import { ConfirmationModalState } from '@/components/ConfirmationModal';
import { UserStatus } from '@/graphql/codegen/graphql';
import { useActivateUser } from '@/hooks/useActivateUser';
import { User } from '@/utils/interfaces';
import { Button } from '@skand/ui';
import { useCallback } from 'react';

export const ReactivateButton = ({
  user,
  handleConfirmationModal,
}: {
  user: User;
  handleConfirmationModal: (modalDetails: ConfirmationModalState) => void;
}) => {
  const userStatus = user?.status as UserStatus;
  const { response: activateUserMutationResponse } = useActivateUser(user?.id ?? '');

  const handleSubmitReactivateUser = useCallback(() => {
    activateUserMutationResponse.mutate();
  }, [activateUserMutationResponse]);

  return (
    <Button
      className="cursor-pointer"
      onClick={e => {
        e.preventDefault();
        handleConfirmationModal({
          isOpen: true,
          title: 'Reactivate user',
          description: `Are you sure you want to reactivate user ${user?.firstName} ${user?.lastName}`,
          actionButton: 'Reactivate',
          actionFunction: handleSubmitReactivateUser,
        });
      }}
      primary={userStatus === 'DEACTIVATED'}
      size="s"
    >
      Reactivate
    </Button>
  );
};
