import { USER_BY_USER_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (userId?: null | undefined | string) => ['USER_BY_USER_ID', userId];

export const useUser = (userId: null | string) => {
  const response = useQuery({
    enabled: !!userId,
    queryFn: () => request(USER_BY_USER_ID, { userId: userId as string }),
    queryKey: getQueryKey(userId as string),
  });

  const user = useMemo(() => {
    const user = response.data?.user;

    if (!hasId(user)) return null;
    return user;
  }, [response.data?.user]);

  return { response, user };
};

useUser.getQueryKey = getQueryKey;
