import { queryClient } from '@/graphql/client';
import { UPDATE_USER } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useUser } from '@/hooks/useUser';
import { useUsers } from '@/hooks/useUsers';
import { toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useListUsersByAccountId } from './useListUsersByAccountId';
import { useReadUserByContext } from './useReadUserByContext';
import { useReadUserById } from './useReadUserById';

export const useUpdateUser = () => {
  const mutation = useMutation({
    mutationFn: ({ firstName, lastName }: { firstName: string; lastName: string }) =>
      request(UPDATE_USER, {
        user: {
          firstName,
          lastName,
        },
      }),
    onSuccess: data => {
      queryClient.invalidateQueries(useUser.getQueryKey(data.updateUser?.id));
      queryClient.invalidateQueries(useReadUserById.getQueryKey(data.updateUser?.id));
      queryClient.invalidateQueries(useUsers.queryKey);
      queryClient.invalidateQueries(useListUsersByAccountId.queryKey);
      queryClient.invalidateQueries(useReadUserByContext.queryKey);
      toast({
        type: 'success',
        message: 'User has been updated successfully',
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
    onError: () => {
      toast({
        type: 'warn',
        message: "User couldn't be updated",
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  });

  return { mutation };
};
