import { ConfirmationModalState } from '@/components/ConfirmationModal';
import { UserStatus } from '@/graphql/codegen/graphql';
import { useDeactivateUser } from '@/hooks/useDeactivateUser';
import { User } from '@/utils/interfaces';
import { Button } from '@skand/ui';
import { useCallback } from 'react';

export const DeactivateButton = ({
  user,
  handleConfirmationModal,
}: {
  user: User;
  handleConfirmationModal: (modalDetails: ConfirmationModalState) => void;
}) => {
  const userStatus = user?.status as UserStatus;

  const { response: deactivateUserMutationResponse } = useDeactivateUser(user?.id ?? '');
  const handleSubmitDeactivateUser = useCallback(() => {
    deactivateUserMutationResponse.mutate();
  }, [deactivateUserMutationResponse]);

  return (
    <Button
      className="cursor-pointer"
      onClick={e => {
        e.preventDefault();
        handleConfirmationModal({
          isOpen: true,
          title: 'Deactivate user',
          description: `Are you sure you want to deactivate user ${user?.firstName} ${user?.lastName}`,
          actionButton: 'Deactivate',
          actionFunction: handleSubmitDeactivateUser,
        });
      }}
      primary={userStatus === 'DEACTIVATED'}
      size="s"
    >
      Deactivate
    </Button>
  );
};
