import { AnnotationFieldType } from '@/constants/template';
import { AnnotationTemplateField } from '@/graphql/codegen/graphql';
import { WithMode } from '@/stores/template';
import { createColumnHelper } from '@tanstack/react-table';
import { FieldAction } from './FieldAction';
import { FieldType } from './FieldType';
import { FieldValue } from './FieldValue';
import { Name } from './Name';

const columnHelper = createColumnHelper<WithMode<AnnotationTemplateField>>();

export const columns = [
  columnHelper.accessor('name', {
    header: () => {
      return (
        <div className="min-w-360px flex justify-start">
          <p className="mr-auto">name</p>
        </div>
      );
    },
    cell: props => <Name field={props.row.original} />,
  }),
  columnHelper.accessor('type', {
    header: () => {
      return (
        <div className="min-w-180px flex justify-start">
          <p className="mr-auto">field type</p>
        </div>
      );
    },
    cell: ({ getValue }) => <FieldType type={getValue() as AnnotationFieldType} />,
  }),
  columnHelper.display({
    id: 'values',
    header: () => (
      <div className="w-full flex items-center justify-between">
        <p>values</p>
        <p className="pr-18">colour</p>
      </div>
    ),
    cell: ({ row }) => <FieldValue field={row.original} />,
  }),
  columnHelper.display({
    id: 'actions',
    header: () => {
      return <div className="ml-auto min-w-128px flex" />;
    },
    cell: ({ row }) => <FieldAction field={row.original} />,
  }),
];
