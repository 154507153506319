import { AZURE_APPLICATION_ID } from '@/constants/env';
import logger from '@/utils/logger';
import { useMsal } from '@azure/msal-react';
import { toast } from '@skand/ui';

const handleError = (error: unknown) => {
  logger.error(error);
  let message = error;
  if (error instanceof Error) message = error.message;
  toast({
    type: 'warn',
    message: `${message}`,
    lifespan: 10000,
    clickToDismiss: true,
  });
};

export const useGainIdpToken = () => {
  const { instance } = useMsal();

  const gainIdpToken = async (email: string) => {
    const account = instance.getAccountByUsername(email);

    if (account) {
      const loginRequest = {
        scopes: [`api://${AZURE_APPLICATION_ID}/access_as_user`],
        account,
      };
      try {
        const { accessToken } = await instance.acquireTokenSilent(loginRequest);
        return accessToken;
      } catch (acquireTokenSilentError) {
        try {
          const { accessToken } = await instance.acquireTokenPopup(loginRequest);
          return accessToken;
        } catch (acquireTokenPopupError) {
          handleError(acquireTokenSilentError);
          return;
        }
      }
    }

    const loginRequest = {
      scopes: [`api://${AZURE_APPLICATION_ID}/access_as_user`],
      prompt: 'select_account',
    };
    try {
      const { accessToken } = await instance.loginPopup(loginRequest);
      return accessToken;
    } catch (error) {
      handleError(error);
      return;
    }
  };

  return gainIdpToken;
};
