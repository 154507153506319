import { CHECK_SUPER_ADMIN } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = () => ['CHECK_SUPER_ADMIN'];

export const useIsSuperAdmin = () => {
  const response = useQuery({
    queryFn: () =>
      request(CHECK_SUPER_ADMIN),
    queryKey: getQueryKey(),
  });

  const isSuperAdmin = useMemo(() => {
    return response.data?.checkSuperAdmin
  }, [response.data]);

  return isSuperAdmin
};

useIsSuperAdmin.getQueryKey = getQueryKey;
