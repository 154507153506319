import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import { AddTargetToSource } from '@/components/SourceTargetPage/AddTargetToSource';
import { Table } from '@/components/Table';
import * as PATHS from '@/constants/paths';
import { OBJECT_ID } from '@/constants/policy';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { GROUP_DETAILS_PAGE_MODE } from '@/constants/role';
import { queryClient } from '@/graphql/client';
import { PolicySubjectTypeInput, Role, User, UserGroup } from '@/graphql/codegen/graphql';
import { useAccount } from '@/hooks/useAccount';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { useGroupingPolicyMutation } from '@/hooks/useGroupingPolicyMutation';
import { useGroups } from '@/hooks/useGroups';
import { useGroupsMutation } from '@/hooks/useGroupsMutation';
import { usePermissionPolicyMutation } from '@/hooks/usePermissionPolicyMutation';
import {
  clearGroupState,
  removeFromGroupUsers,
  setGroupUsers,
  useGroupStore,
} from '@/stores/group';
import { cn } from '@/utils/classname';
import { sourceIdAndTargetIdToGroupingPolicyInput } from '@/utils/groupingPolicy';
import {
  getCreateSubjectsPagesSidePanelLinks,
  getSubjectsPagesSidePanelLinks,
} from '@/utils/links';
import { parseAllRawValue } from '@/utils/misc';
import { filterHighestActionTypePerObjectType } from '@/utils/policy';
import { displayName } from '@/utils/user';
import { Button, Input, toast } from '@skand/ui';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularLoadingIndicator } from '../CircularLoadingIndicator';
import { AccessGate, FeatureNotIncluded } from '../AccessGate';
import { FindIcon } from '../IconButton';
import { NameCell } from './NameCell';
import { getEntitlementName } from './utils';
import { ConfirmationModal, ConfirmationModalState } from '../ConfirmationModal';
import useSourceTypeMappings from '@/hooks/useSourceTypeMappings';

const columnHelper = createColumnHelper<SolidId<User | UserGroup | Role>>();

interface SourceTargetPageProps {
  id: string;
  source: SolidId<User | UserGroup | Role> | null | string;
  sourceType: PolicySubjectTypeInput;
  targetType: PolicySubjectTypeInput;
  targetList: SolidId<User | UserGroup | Role>[];
  sourceTargetIds: string[];
  sourceTargetList: SolidId<User | UserGroup | Role>[];
  onCreateOrDeleteSuccess?: () => void;
  mode?: string;
}

export const SourceTargetPage = ({
  id,
  source,
  sourceType,
  targetList,
  targetType,
  sourceTargetIds,
  sourceTargetList,
  onCreateOrDeleteSuccess,
  mode,
}: SourceTargetPageProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [confirmationModalState, setConfirmationModalState] = useState<ConfirmationModalState>({
    isOpen: false,
    title: '',
    description: '',
    actionButton: '',
    actionFunction: () => null,
  });

  const accountId = useAccount().account?.id;
  const history = useHistory();

  const { groupUserIds, groupName, groupDescription, groupPermissionPolicies } = useGroupStore(
    state => state,
  );

  const { sourceTypeMap, sourceTypeBackLinkMap, targetTypeMap } = useSourceTypeMappings(
    source,
    sourceType,
  );

  const handleCreateOrDeleteSuccess = useCallback(() => {
    onCreateOrDeleteSuccess?.();
  }, [onCreateOrDeleteSuccess]);

  const { createGroupingPolicy, deleteGroupingPolicy } = useGroupingPolicyMutation({
    onDeleteGroupingPoliciesSuccess: handleCreateOrDeleteSuccess,
    onCreateGroupingPoliciesSuccess: handleCreateOrDeleteSuccess,
  });

  const { upsertPermission } = usePermissionPolicyMutation();
  const { createGroup } = useGroupsMutation({
    onCreateGroupSuccess: async (groupId?: string) => {
      const upsertPermissionPolicyPayload = groupPermissionPolicies.map(policy => ({
        accountId,
        actionType: policy?.actionType,
        objectId: OBJECT_ID.ALL,
        objectType: parseAllRawValue(policy?.objectType as string),
        subjectId: groupId,
        subjectType: policy?.subjectType,
      }));

      const permissionPolicyResult = filterHighestActionTypePerObjectType(
        upsertPermissionPolicyPayload,
      );

      const usersPolicy = groupUserIds.map(id =>
        sourceIdAndTargetIdToGroupingPolicyInput({
          accountId: accountId as string,
          sourceId: groupId as string,
          sourceType,
          targetId: id,
          targetType,
        }),
      );

      createGroupingPolicy.mutate(usersPolicy);

      try {
        await upsertPermission.mutateAsync(permissionPolicyResult);
        queryClient.invalidateQueries(useGroups.queryKey);

        toast({
          type: 'success',
          message: 'Group has been created successfully',
          lifespan: 10000,
        });

        history.push(PATHS.MANAGE_GROUPS);
        clearGroupState();
      } catch (error) {
        toast({
          type: 'warn',
          message: 'Group creation failed!',
          lifespan: 10000,
        });
      }
    },
  });

  const removeFromTarget = useCallback(
    (targetId: string) => {
      const policies = [
        sourceIdAndTargetIdToGroupingPolicyInput({
          accountId: accountId as string,
          sourceId: source && typeof source === 'object' && 'id' in source ? source.id : '',
          sourceType,
          targetId,
          targetType,
        }),
      ];
      deleteGroupingPolicy.mutate(policies);
    },
    [accountId, deleteGroupingPolicy, source, sourceType, targetType],
  );

  const addTargetToSource = useCallback(
    (selectedUserGroupIds: string[]) => {
      const policies = selectedUserGroupIds.map(targetId =>
        sourceIdAndTargetIdToGroupingPolicyInput({
          accountId: accountId as string,
          sourceId: source && typeof source === 'object' && 'id' in source ? source.id : '',
          sourceType,
          targetId,
          targetType,
        }),
      );
      mode === GROUP_DETAILS_PAGE_MODE.CREATE
        ? setGroupUsers(policies)
        : createGroupingPolicy.mutate(policies);
      setGroupUsers(policies);
    },
    [accountId, createGroupingPolicy, mode, source, sourceType, targetType],
  );

  const handleCreateGroup = useCallback(() => {
    createGroup.mutate({
      name: groupName,
      description: groupDescription,
    });
  }, [createGroup, groupDescription, groupName]);

  const isCreatedBySystem =
    source && typeof source === 'object' && 'createdBy' in source
      ? source.createdBy === 'SYSTEM'
      : false;

  const sideTabPanelLinks =
    mode === 'CREATE'
      ? getCreateSubjectsPagesSidePanelLinks({ subjectType: sourceType })
      : getSubjectsPagesSidePanelLinks({ id, subjectType: sourceType });

  const columns = useMemo(() => {
    const handleConfirmationModal = (modalFunction: { actionFunction: () => void }) => {
      setConfirmationModalState({
        isOpen: true,
        title: `Remove ${targetType.toLowerCase()} from ${sourceType.toLowerCase()}`,
        description: `This action cannot be undone. Are you sure you want to remove this ${targetType.toLowerCase()} from ${sourceTypeMap[sourceType]
          }?`,
        actionButton: 'Remove',
        ...modalFunction,
      });
    };

    return [
      columnHelper.accessor(
        target => {
          if (targetType === PolicySubjectTypeInput.User) return displayName(target as User);
          if (targetType === PolicySubjectTypeInput.Group) return (target as UserGroup).name;
          if (targetType === PolicySubjectTypeInput.Role) return (target as Role).name;
          return '';
        },
        {
          enableColumnFilter: true,
          header: 'name',
          cell: ({ getValue, row }) => (
            <NameCell id={row.original.id} targetType={targetType} value={getValue() ?? ''} />
          ),
        },
      ),

      columnHelper.accessor(
        target => {
          if (targetType === PolicySubjectTypeInput.User) return (target as User).email;
          if ([PolicySubjectTypeInput.Group, PolicySubjectTypeInput.Role].includes(targetType))
            return (target as UserGroup).description;
          return '';
        },
        {
          enableColumnFilter: true,
          id: 'email or description',
          header: () => (
            <span>
              {targetType === PolicySubjectTypeInput.User && 'email'}
              {[PolicySubjectTypeInput.Group, PolicySubjectTypeInput.Role].includes(targetType) &&
                'description'}
            </span>
          ),
          cell: ({ getValue }) => (
            <span className="color-neutral-800 typo-text-s">{getValue()}</span>
          ),
        },
      ),

      columnHelper.display({
        enableColumnFilter: false,
        id: 'actions',
        header: undefined,
        cell: ({ row }) => {
          const entitlementName = getEntitlementName(sourceType, targetType);
          if (entitlementName === null) return null;

          if (
            sourceType === PolicySubjectTypeInput.Role &&
            targetType === PolicySubjectTypeInput.User
          ) {
            if (isCreatedBySystem) {
              return (
                <div className="flex items-center justify-end">
                  <Button
                    className="cursor-pointer"
                    onClick={() => removeFromTarget(row.original.id)}
                    size="xs"
                  >
                    revoke access
                  </Button>
                </div>
              );
            }
          }

          const readMoreUrl =
            sourceType === PolicySubjectTypeInput.Role
              ? READ_MORE_LINKS.ROLE
              : sourceType === PolicySubjectTypeInput.Group
                ? READ_MORE_LINKS.GROUP
                : sourceType === PolicySubjectTypeInput.User &&
                  targetType === PolicySubjectTypeInput.Group
                  ? READ_MORE_LINKS.USER_GROUP
                  : sourceType === PolicySubjectTypeInput.User &&
                    targetType === PolicySubjectTypeInput.Role
                    ? READ_MORE_LINKS.ROLE
                    : READ_MORE_LINKS.PRICING;

          if (targetType === PolicySubjectTypeInput.User) {
            return (
              <div className="flex items-center justify-end">
                <AccessGate
                  disabled={() => (
                    <FeatureNotIncluded
                      button={<div className="i-skand-close text-3 color-neutral-400" />}
                      readMoreUrl={readMoreUrl}
                    />
                  )}
                  enabled={() => (
                    <div
                      className="i-skand-close cursor-pointer text-3 color-neutral-400 hover:color-primary-400"
                      onClick={e => {
                        e.preventDefault();
                        handleConfirmationModal({
                          actionFunction: () => {
                            mode == GROUP_DETAILS_PAGE_MODE.CREATE
                              ? removeFromGroupUsers(row.original.id)
                              : removeFromTarget(row.original.id);
                          },
                        });
                      }}
                    />
                  )}
                  entitlementCheck={{ featureName: entitlementName }}
                  loading={() => <div className="i-skand-close text-3 color-neutral-400" />}
                />
              </div>
            );
          }

          if ([PolicySubjectTypeInput.Group, PolicySubjectTypeInput.Role].includes(targetType)) {
            const getButtonText = () => {
              if (targetType === PolicySubjectTypeInput.Group) return 'remove from group';
              if (targetType === PolicySubjectTypeInput.Role) return 'revoke access';
              return '';
            };

            return (
              <div className="flex items-center justify-end">
                <AccessGate
                  disabled={() => (
                    <FeatureNotIncluded
                      button={
                        <Button disabled size="xs">
                          {getButtonText()}
                        </Button>
                      }
                      readMoreUrl={readMoreUrl}
                    />
                  )}
                  enabled={() => (
                    <Button
                      className="cursor-pointer"
                      onClick={e => {
                        e.preventDefault();
                        handleConfirmationModal({
                          actionFunction: () => removeFromTarget(row.original.id),
                        });
                      }}
                      size="xs"
                    >
                      {getButtonText()}
                    </Button>
                  )}
                  entitlementCheck={{ featureName: entitlementName }}
                  loading={() => (
                    <Button disabled size="xs">
                      {getButtonText()}
                    </Button>
                  )}
                />
              </div>
            );
          }

          return null;
        },
      }),
    ];
  }, [isCreatedBySystem, mode, removeFromTarget, sourceType, sourceTypeMap, targetType]);

  const table = useReactTable({
    columns,
    data: sourceTargetList,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    state: { globalFilter, sorting },
    onSortingChange: setSorting,
  });

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      {confirmationModalState.isOpen && (
        <ConfirmationModal
          confirmationModalState={confirmationModalState}
          setConfirmationModalState={setConfirmationModalState}
        />
      )}
      <SideBar />
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className="ml-4 color-neutral-800 typo-heading-3">
            {!source ? <CircularLoadingIndicator /> : <>{sourceTypeMap[sourceType]}</>}
          </h1>
          <div className="ml-auto flex gap-2">
            {sourceTypeBackLinkMap[sourceType]}
            <AddTargetToSource
              isCreatedBySystem={isCreatedBySystem}
              onAddSources={selectedUserGroupIds => addTargetToSource(selectedUserGroupIds)}
              sourceTargetIds={sourceTargetIds}
              sourceType={sourceType}
              targetList={targetList}
              targetType={targetType}
            />
            {sourceType === PolicySubjectTypeInput.Group && mode == 'CREATE' && (
              <AccessGate
                disabled={() => (
                  <FeatureNotIncluded
                    button={
                      <Button className="min-w-[134px] px-8" disabled filled primary size="s">
                        Create Group
                      </Button>
                    }
                    readMoreUrl={READ_MORE_LINKS.GROUP}
                  />
                )}
                enabled={() => (
                  <Button
                    className="min-w-[134px] px-8"
                    disabled={groupName === ''}
                    filled
                    onClick={handleCreateGroup}
                    primary
                    size="s"
                  >
                    Create Group
                  </Button>
                )}
                entitlementCheck={{ featureName: ENTITLEMENT_NAME.USER_GROUP }}
                loading={() => (
                  <Button className="min-w-[134px] px-8" disabled filled primary size="s">
                    Create Group
                  </Button>
                )}
              />
            )}
          </div>
        </div>

        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">{targetTypeMap[targetType]}</p>

          <div className="w-360px">
            <Input
              onChange={setGlobalFilter}
              placeholder={`Search for ${targetType.toString().toLocaleLowerCase()}`}
              tail={<FindIcon />}
              value={globalFilter}
            />
          </div>
        </div>

        <div className="flex">
          <Table className="h-fit" table={table} />

          <SideTabPanel links={sideTabPanelLinks} />
        </div>
      </div>
    </div>
  );
};
