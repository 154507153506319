import { Thumbnail } from '@/components/Thumbnail';
import { useAccountV2 } from '@/hooks/useAccountV2';
import { useEntitlements } from '@/hooks/useEntitlements';
import { Progress } from '@skand/ui';
import { useSimpleUploader } from '@skand/uploader';
import {
  ChangeEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ChangeButton } from './EntitlementButtons';
import { useUpdateAccountBranding } from './useUpdateAccountBranding';

export const ChangeReportLogo = ({ children }: { children: ReactNode }) => {
  const { account } = useAccountV2();
  const inputRef = useRef<HTMLInputElement>(null);
  const { addFile, upload, off, on } = useSimpleUploader();
  const { mutateAsync: updateAccountBranding } = useUpdateAccountBranding();
  const [isUploading, setIsUploading] = useState(false);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0);

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    e => {
      const file = e.target.files?.[0];
      if (!file) return;

      setIsUploading(true);
      setFileName(file.name);
      addFile(file);
      upload();
    },
    [addFile, upload],
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    inputRef.current?.click();
  }, []);

  useEffect(() => {
    const onProgress = ({ progress }: { progress: number }) => {
      setIsUploading(true);
      setProgress(progress);
    };

    const onCreateSuccess = async ({ fileId }: { fileId: string }) => {
      await updateAccountBranding({ reportBannerFileId: fileId });
      setIsUploading(false);
      setFileName('');
      setProgress(0);
    };

    on('createSuccess', onCreateSuccess);
    on('progress', onProgress);
    return () => {
      off('createSuccess', onCreateSuccess);
      off('progress', onProgress);
    };
  }, [off, on, updateAccountBranding]);

  const { enabled: isEnabled } = useEntitlements().feature('branding') ?? {};
  return (
    <>
      <label className="flex items-center">
        <input
          accept="image/*"
          className="hidden"
          disabled={!isEnabled}
          onChange={handleChange}
          ref={inputRef}
          type="file"
        />

        {account?.branding?.reportBannerFileId ? (
          <Thumbnail fileId={account?.branding?.reportBannerFileId} height={90} width={160} />
        ) : isUploading ? (
          <div className="h-[90px] w-[160px] flex flex-col items-center justify-center gap-2 border border-1 border-neutral-400 rounded-md border-solid px-3">
            <p className="color-neutral-600 typo-text-small-em">{fileName}</p>
            <Progress className="w-84px" progress={progress / 100} />
          </div>
        ) : (
          <div className="h-[90px] w-[160px] flex items-center justify-between border border-1 border-neutral-400 rounded-md border-solid px-2">
            <p className="color-neutral-600 underline typo-text-small-em">Choose Logo</p>
            <div className="i-skand-add h-[12px] color-neutral-400" />
          </div>
        )}
      </label>

      <div className="mt-3 w-160px flex gap-3">
        <ChangeButton onClick={handleClick} />
        {children}
      </div>
    </>
  );
};
