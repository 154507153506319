import { ConfirmationModalState } from '@/components/ConfirmationModal';
import { SetStateAction, useCallback } from 'react';
import { useResetPassword } from './useResetPassword';
import { useUpdateUserById } from './useUpdateUserById';

export const useUserDetailsPageActions = (
  userId: string,
  firstName: string,
  lastName: string,
  email: string,
  setConfirmationModalState: {
    (value: SetStateAction<ConfirmationModalState>): void;
    (arg0: ConfirmationModalState): void;
  },
) => {
  const { response: resetPasswordResponse } = useResetPassword();
  const { mutation: updateUserMutationResponse } = useUpdateUserById();

  const handleSubmitEditUserDetails = useCallback(() => {
    updateUserMutationResponse.mutate({
      userId,
      firstName,
      lastName,
    });
  }, [userId, firstName, lastName, updateUserMutationResponse]);

  const handleSubmitResetPassword = useCallback(() => {
    resetPasswordResponse.mutateAsync({
      email,
      hostDomain: `${window.location.protocol}//${window.location.host}`,
    });
  }, [email, resetPasswordResponse]);

  const handleConfirmationModal = (modalDetails: ConfirmationModalState) => {
    setConfirmationModalState(modalDetails);
  };

  return {
    handleSubmitEditUserDetails,
    handleSubmitResetPassword,
    handleConfirmationModal,
  };
};
