import { queryClient } from '@/graphql/client';
import { UPDATE_ACCOUNT_BRANDING } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccountV2 } from '@/hooks/useAccountV2';
import { useMutation } from '@tanstack/react-query';

export const useUpdateAccountBranding = () => {
  const { account } = useAccountV2();
  const updateAccountBranding = useMutation({
    mutationFn: (variables: {
      color?: string;
      hasSkandWatermark?: boolean;
      reportBannerFileId?: string | null;
      webappBannerFileId?: string | null;
      webappLogoFileId?: string | null;
    }) => {
      const response = request(UPDATE_ACCOUNT_BRANDING, {
        accountId: account?.id as string,
        ...account?.branding,
        ...variables,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(useAccountV2.queryKey);
    },
  });

  return updateAccountBranding;
};
