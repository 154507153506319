import { ConfirmationModalState } from '@/components/ConfirmationModal';
import { UserStatus } from '@/graphql/codegen/graphql';
import { useResendInvitationToUser } from '@/hooks/useResendInvitationToUser';
import { User } from '@/utils/interfaces';
import { Button } from '@skand/ui';
import { useEffect, useState } from 'react';

export const ResendInviteButton = ({
  user,
  handleConfirmationModal,
}: {
  user: User;
  handleConfirmationModal: (modalDetails: ConfirmationModalState) => void;
}) => {
  const [countdown, setCountdown] = useState<null | number>(null);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const { response: resendInvitation } = useResendInvitationToUser(user?.id ?? '');

  const userStatus = user?.status as UserStatus;

  useEffect(() => {
    const updateCountdown = () => {
      const invitationDate = new Date((user?.invitationCreatedAt as string) ?? '');
      const countdownDuration = 60000;
      const now = new Date();
      const difference = now.getTime() - invitationDate.getTime();
      const timeLeft = countdownDuration - difference;

      if (timeLeft > 0) {
        setCountdown(Math.ceil(timeLeft / 1000));
      } else {
        setIsResendButtonDisabled(false);
        setCountdown(null);
        clearInterval(intervalId);
      }
    };

    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [user?.invitationCreatedAt]);

  if (!user.invitationCreatedAt) {
    return null;
  }

  return (
    <Button
      className="cursor-pointer"
      disabled={isResendButtonDisabled}
      onClick={e => {
        e.preventDefault();
        handleConfirmationModal({
          isOpen: true,
          title: 'Are you sure you want to resend invite?',
          description: `Send invite to ${user?.firstName} ${user?.lastName} again?`,
          actionButton: 'Resend Invite',
          actionFunction: () => {
            setIsResendButtonDisabled(true);
            resendInvitation.mutate();
          },
        });
      }}
      primary={userStatus === 'DEACTIVATED'}
      size="s"
    >
      Resend Invite
      {countdown && <span className="ml-1">again in {countdown} seconds</span>}
    </Button>
  );
};
