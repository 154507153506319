import { READ_USER_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { createUser } from '@/utils/createUser';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (userId: null | undefined | string) => ['READ_USER_BY_ID', userId];

export const useReadUserById = (userId: string) => {
  const response = useQuery({
    enabled: !!userId,
    queryFn: () => request(READ_USER_BY_ID, { userId: userId as string }),
    queryKey: getQueryKey(userId as string),
  });

  return useMemo(() => {
    if (response?.data?.readUserById) {
      return createUser(response.data.readUserById);
    }
  }, [response.data?.readUserById]);
};

useReadUserById.getQueryKey = getQueryKey;
