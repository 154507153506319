import { request } from '@/graphql/request';
import { LOGOUT_MUTATION } from '@/graphql/mutations';
import { useMutation } from '@tanstack/react-query';

export const useLogOut = () => {
  const { mutate: logOut } = useMutation({
    mutationFn: () => request(LOGOUT_MUTATION),
    onSuccess: () => {
      window.location.reload();
    },
    onError: () => {
      window.location.reload();
    },
  });

  return { logOut };
};
