import { AnnotationFieldType, COLOR_FROM_FIELD_CUSTOM } from '@/constants/template';
import {
  AnnotationTemplate,
  AnnotationTemplateField,
  AnnotationTemplateSelectField,
  AnnotationTemplateSelectOptionInput,
} from '@/graphql/codegen/graphql';
import { create } from 'zustand';

export type WithMode<T> = T & { mode: 'update' | 'view' };

export interface TemplateState {
  mode: null | 'create' | 'update' | 'view';
  template: null | AnnotationTemplate;
  fields: WithMode<AnnotationTemplateField>[];

  /** next order */
  next: number;
}

export const useTemplate = create<TemplateState>()(() => {
  return {
    fields: [],
    mode: null,
    template: null,
    next: 0,
  };
});

export const createTemplate = () => {
  useTemplate.setState({
    fields: [],
    mode: 'create',
    next: 0,
    template: {
      name: '',
      description: '',
    },
  });
};

export const clearTemplate = () => {
  useTemplate.setState({
    fields: [],
    mode: null,
    template: null,
    next: 0,
  });
};

export const updateTemplate = (template: SolidId<AnnotationTemplate>) => {
  const fields = template.fields ?? [];

  useTemplate.setState({
    fields: fields.map(f => ({ ...f, mode: 'view' })),
    mode: 'update',
    next: fields.length,
    template,
  });
};

export const viewTemplate = (template: SolidId<AnnotationTemplate>) => {
  const fields = template.fields ?? [];

  useTemplate.setState({
    fields: fields.map(f => ({ ...f, mode: 'view' })),
    mode: 'view',
    next: fields.length,
    template,
  });
};

export const changeTemplateName = (name: string) => {
  useTemplate.setState(state => {
    return { template: { ...state.template, name } };
  });
};

export const changeTemplateDesc = (desc: string) => {
  useTemplate.setState(state => {
    return { template: { ...state.template, description: desc } };
  });
};

export const changeTemplateColor = (field: string) => {
  useTemplate.setState(state => {
    return {
      template: {
        ...state.template,
        colorFromField: field === COLOR_FROM_FIELD_CUSTOM ? null : field,
      },
    };
  });
};

export const addField = (type: AnnotationFieldType) => {
  useTemplate.setState(state => {
    const newField: WithMode<AnnotationTemplateField> = {
      mode: 'update',
      order: state.next,
      type,
    };

    return { fields: [...state.fields, newField], next: state.next + 1 };
  });
};

export const renameField = (field: WithMode<AnnotationTemplateField>, name: string) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) newFields.push(f);
      else newFields.push({ ...f, name });
    }

    // if colorFromField equals to old name, change it to new name
    // otherwise leave it untouched
    const oldColorFromField = state.template?.colorFromField;
    const colorFromField =
      oldColorFromField !== undefined && oldColorFromField === field.name
        ? name
        : state.template?.colorFromField;
    return { fields: newFields, template: { ...state.template, colorFromField } };
  });
};

export const removeField = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];
    let isRemoved = false;

    for (const f of state.fields) {
      if (f === field) {
        isRemoved = true;
        continue;
      }

      if (!isRemoved) {
        newFields.push(f);
        continue;
      }

      newFields.push({ ...f, order: (f.order as number) - 1 });
    }

    return { fields: newFields, next: state.next - 1 };
  });
};

export const editField = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) newFields.push(f);
      else newFields.push({ ...f, mode: 'update' });
    }

    return { fields: newFields };
  });
};

export const saveField = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) newFields.push(f);
      else newFields.push({ ...f, mode: 'view' });
    }

    return { fields: newFields };
  });
};

export const addOption = (field: WithMode<AnnotationTemplateField>) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];
      const newOptions = [...options, { color: '#FFFFFF', value: '' }];

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: newOptions,
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const removeOption = (field: WithMode<AnnotationTemplateField>, index: number) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: options.filter((_, i) => i !== index),
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const changeOption = (
  field: WithMode<AnnotationTemplateField>,
  index: number,
  newOption: Partial<AnnotationTemplateSelectOptionInput>,
) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];
      const newOptions: AnnotationTemplateSelectOptionInput[] = [];

      for (const [i, option] of options.entries()) {
        if (i !== index && option) newOptions.push(option);
        else newOptions.push({ ...option, ...newOption });
      }

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: newOptions,
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const moveOption = (
  field: WithMode<AnnotationTemplateField>,
  fromIndex: number,
  toIndex: number,
) => {
  useTemplate.setState(state => {
    const newFields: WithMode<AnnotationTemplateField>[] = [];

    for (const f of state.fields) {
      if (f !== field) {
        newFields.push(f);
        continue;
      }

      const options = (f as AnnotationTemplateSelectField).options ?? [];
      const option = options[fromIndex];
      const tmpOptions = options.filter((_, i) => i !== fromIndex);
      const newOptions = [];

      for (let i = 0; i < options.length; i++) {
        if (i < toIndex) newOptions.push(tmpOptions[i]);
        if (i === toIndex) newOptions.push(option);
        if (i > toIndex) newOptions.push(tmpOptions[i - 1]);
      }

      const newField: WithMode<AnnotationTemplateSelectField> = {
        ...(f as AnnotationTemplateSelectField),
        mode: 'update',
        options: newOptions,
      };

      newFields.push(newField);
    }

    return { fields: newFields };
  });
};

export const moveField = (fromIndex: number, toIndex: number) => {
  useTemplate.setState(state => {
    const fields = state.fields.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    const fromField = fields[fromIndex];

    const tmpFields = fields.filter((_, i) => i !== fromIndex);
    const newFields = [];

    for (let i = 0; i < fields.length; i++) {
      if (i < toIndex)
        newFields.push({
          ...tmpFields[i],
          order: i,
        });

      if (i === toIndex) {
        newFields.push({
          ...fromField,
          order: i,
        });
      }

      if (i > toIndex) {
        newFields.push({
          ...tmpFields[i - 1],
          order: i,
        });
      }
    }

    return { fields: newFields };
  });
};
