import { cn } from '@skand/ui';
import React from 'react';
import { EmptyResourceMessage } from './EmptyResourceMessage';

export enum EmptyListSeachTopic {
  USERS = 'users',
  GROUPS = 'user groups',
  ROLES = 'roles',
  TEMPLATES = 'templates',
}

const emptyDescMap = {
  [EmptyListSeachTopic.USERS]: 'Invite users to your account to collaborate on projects.',
  [EmptyListSeachTopic.GROUPS]: 'Create a user group to provide permissions across multiple users.',
  [EmptyListSeachTopic.ROLES]: 'Create a role to customise permissions for users or user groups.',
  [EmptyListSeachTopic.TEMPLATES]: 'Create custom fields and templates for use in Annotations.',
};

interface EmptyListComponentProps {
  searchTopic: EmptyListSeachTopic;
  searchTerm: string | undefined;
  children: React.ReactNode;
}

export const EmptyListComponent = ({
  searchTopic,
  searchTerm,
  children,
}: EmptyListComponentProps) => {
  return (
    <div className={cn('b-neutral-200 rounded-2 b-solid h-full my-3')}>
      <EmptyResourceMessage
        action={children}
        desc={
          <div className="max-w-384px">
            {searchTerm ? (
              <>
                <p className="font-semibold">
                  Your search did not match any names. Here&apos;s what you can try:
                </p>
                <div className="mb-4">
                  <ul className="ml-3 mt-2 list-disc">
                    <li>Double-check the spelling</li>
                    <li>Check for typographical errors</li>
                    <li>
                      If you&apos;re unsure of the exact name, use a partial term or fewer
                      characters
                    </li>
                  </ul>
                </div>
              </>
            ) : (
              <p className="text-center">{emptyDescMap[searchTopic]}</p>
            )}
          </div>
        }
        title={
          searchTerm ? (
            <div className="w-384px overflow-hidden text-center">
              <p className="text-lg font-semibold text-gray-600">
                No results found for {searchTopic} with the term
                <p
                  className="w-full overflow-hidden truncate text-lg font-bold text-primary-400"
                  title={searchTerm}
                >
                  &#34;{searchTerm}&#34;
                </p>
              </p>
            </div>
          ) : (
            <p>No {searchTopic} yet</p>
          )
        }
        toastMessage="Check with your admin for your permissions if content is expected here."
      />
    </div>
  );
};
