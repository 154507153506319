import { AccessGate, FeatureNotIncluded } from '@/components/AccessGate';
import { MoreMenu } from '@/components/MoreMenu';
import * as PATHS from '@/constants/paths';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ANNOTATION_TEMPLATE_CREATED_BY } from '@/constants/template';
import { AnnotationTemplate } from '@/graphql/codegen/graphql';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { Button, Menu, MenuItem } from '@skand/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';

const columnHelper = createColumnHelper<SolidId<AnnotationTemplate>>();
interface CreateColumnProps {
  handleDeleteAnnotationTemplate: (id: string) => void;
}

export const createColumns = ({ handleDeleteAnnotationTemplate }: CreateColumnProps) => [
  columnHelper.accessor('name', {
    enableGlobalFilter: true,
    header: 'name',
    cell: ({ getValue, row }) => {
      const isSystemDefault = row.original.createdBy !== ANNOTATION_TEMPLATE_CREATED_BY.USER;

      return (
        <div className="max-w-800px truncate" title={getValue() ?? ''}>
          <Link
            className="color-neutral-800 underline typo-link-s"
            to={
              isSystemDefault
                ? `${PATHS.MANAGE_TEMPLATES}/${row.original.id}/details`
                : `${PATHS.MANAGE_TEMPLATES}/${row.original.id}/edit`
            }
          >
            {getValue()}
          </Link>

          <span className="color-neutral-400 typo-text-s">
            {isSystemDefault ? ' - System default' : ' - User created'}
          </span>
        </div>
      );
    },
  }),

  columnHelper.display({
    enableGlobalFilter: false,
    id: 'actions',
    header: '',
    cell: ({ row }) => {
      const isSystemDefault = row.original.createdBy !== ANNOTATION_TEMPLATE_CREATED_BY.USER;
      const disabledButton = (
        <Button disabled size="s">
          Edit
        </Button>
      );

      return (
        <div className="flex items-center justify-end gap-2">
          {isSystemDefault ? (
            <Link to={`${PATHS.MANAGE_TEMPLATES}/${row.original.id}/details`}>
              <Button size="s">View</Button>
            </Link>
          ) : (
            <AccessGate
              disabled={() => (
                <FeatureNotIncluded
                  button={disabledButton}
                  readMoreUrl={READ_MORE_LINKS.TEMPLATE}
                />
              )}
              enabled={() => (
                <Link to={`${PATHS.MANAGE_TEMPLATES}/${row.original.id}/edit`}>
                  <Button size="s">Edit</Button>
                </Link>
              )}
              entitlementCheck={{ featureName: ENTITLEMENT_NAME.ANNOTATION_TEMPLATE }}
              loading={() => disabledButton}
            />
          )}

          <MoreMenu>
            <Menu>
              <MenuItem
                disabled={isSystemDefault}
                onClick={() => !isSystemDefault && handleDeleteAnnotationTemplate(row.original.id)}
              >
                Delete
              </MenuItem>
            </Menu>
          </MoreMenu>
        </div>
      );
    },
  }),
];
