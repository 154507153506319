import { AnnotationTemplateSelectField } from '@/graphql/codegen/graphql';
import { WithMode } from '@/stores/template';
import { cn } from '@/utils/classname';

export interface OptionViewProps {
  field: WithMode<AnnotationTemplateSelectField>;
}

export const OptionView = ({ field }: OptionViewProps) => {
  const options = field.options ?? [];
  return (
    <ul className="min-w-400px list-none">
      {options?.map((option, index) => {
        return (
          <li className="mt-2 flex justify-between first:mt-0" key={index}>
            <span className={cn(' color-neutral-800 typo-text-s')}>{option?.value}</span>
            <div className="mr-4 min-w-150px flex flex-none items-center justify-center">
              <div
                className={cn(
                  'w-3',
                  'h-3',
                  'rounded-full',
                  option?.color === '#FFFFFF' && 'ring-1 ring-inset ring-neutral-300',
                )}
                style={{ background: option?.color ?? undefined }}
              />
            </div>
          </li>
        );
      })}
    </ul>
  );
};
