import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { CreateSubjectPermissionsPage } from '@/components/SubjectPermissionsPage/CreateSubjectPermissionsPage';

export const CreateGroupsPermissionsPage = () => {
  return (
    <CreateSubjectPermissionsPage
      subjectType={PolicySubjectTypeInput.Group}
    />
  );
};
