import { useCallback, useEffect } from 'react';

import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';

import { useAccount } from '@/hooks/useAccount';

import { cn } from '@/utils/classname';
import { parseAllRawValue } from '@/utils/misc';
import { CheckBox } from '@skand/ui';

import { Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ACTION_TYPE_MAP, OBJECT_TYPE_TO_ACTION_TYPES_MAP } from '../../constants/permissions';
import { Resource } from './columns';
import { setAllAdminChecked, usePermissionStore } from '@/stores/permission';
import { removeGroupPermissionPolicy, upsertGroupPermissionPolicy } from '@/stores/group';
import { removeRolePermissionPolicy, upsertRolePermissionPolicy } from '@/stores/role';

export const CreatePermissionCell = ({ row }: { row: Row<Resource> }) => {
  const { actionTypes, subjectType, objectType } = row.original;
  const { isAllAdminChecked } = usePermissionStore(state => state);
  const { id } = useParams<{ id: string }>();
  const accountId = useAccount().account?.id;
  const currObjectTypeActionTypes =
    OBJECT_TYPE_TO_ACTION_TYPES_MAP[parseAllRawValue(objectType) as PolicyObjectTypeInput];

  useEffect(() => {
    if (isAllAdminChecked) {
      if (subjectType == 'ROLE') {
        for (let i = 0; i < currObjectTypeActionTypes.length; i++) {
          upsertRolePermissionPolicy({
            accountId: accountId as string,
            actionType: currObjectTypeActionTypes[i],
            subjectId: id ?? '',
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          });
        }
      }
      for (let i = 0; i < currObjectTypeActionTypes.length; i++) {
        upsertGroupPermissionPolicy({
          actionType: currObjectTypeActionTypes[i],
          subjectType: subjectType,
          objectId: row.original.objectId,
          objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
        });
      }
    }
  }, [
    accountId,
    currObjectTypeActionTypes,
    id,
    isAllAdminChecked,
    row.original.objectId,
    row.original.objectType,
    subjectType,
  ]);

  const handleGroupChange = useCallback(
    (checked: boolean, actionType: PolicyActionTypeInput) => {
      const actionIndex = currObjectTypeActionTypes.indexOf(actionType);
      if (checked) {
        for (let i = actionIndex; i < currObjectTypeActionTypes.length; i++) {
          upsertGroupPermissionPolicy({
            actionType: currObjectTypeActionTypes[i],
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          });
        }
      } else {
        setAllAdminChecked(false);
        removeGroupPermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType: subjectType,
          objectId: '*',
          objectType: 'ALL',
        });

        for (let i = 0; i <= actionIndex; i++) {
          removeGroupPermissionPolicy({
            actionType: currObjectTypeActionTypes[i],
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          });
        }
      }
    },
    [currObjectTypeActionTypes, row.original.objectId, row.original.objectType, subjectType],
  );

  const handleRoleChange = useCallback(
    (checked: boolean, actionType: PolicyActionTypeInput) => {
      const actionIndex = currObjectTypeActionTypes.indexOf(actionType);

      if (checked) {
        for (let i = actionIndex; i < currObjectTypeActionTypes.length; i++) {
          upsertRolePermissionPolicy({
            accountId: accountId as string,
            actionType: currObjectTypeActionTypes[i],
            subjectId: id ?? '',
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          });
        }
      } else {
        setAllAdminChecked(false);
        removeRolePermissionPolicy({
          accountId: accountId as string,
          actionType: PolicyActionTypeInput.Admin,
          subjectId: id ?? '',
          subjectType: subjectType,
          objectId: '*',
          objectType: 'ALL',
        });
        for (let i = 0; i <= actionIndex; i++) {
          removeRolePermissionPolicy({
            accountId: accountId as string,
            actionType: currObjectTypeActionTypes[i],
            subjectId: id ?? '',
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          });
        }
      }
    },
    [
      accountId,
      currObjectTypeActionTypes,
      id,
      row.original.objectId,
      row.original.objectType,
      subjectType,
    ],
  );

  const handleAdminChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setAllAdminChecked(true);
        upsertRolePermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType,
          objectId: '*',
          objectType: '*',
        });

        upsertGroupPermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType,
          objectId: '*',
          objectType: '*',
        });
      } else {
        setAllAdminChecked(false);
        removeRolePermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType,
          objectId: '*',
          objectType: '*',
        });

        removeGroupPermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType,
          objectId: '*',
          objectType: '*',
        });
      }
    },
    [subjectType],
  );

  const handleChangePermissionPolicy = useCallback(
    (checked: boolean, actionType: PolicyActionTypeInput) => {
      if (actionType !== PolicyActionTypeInput.Admin && !checked) {
        setAllAdminChecked(false);
        removeRolePermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType,
          objectId: '*',
          objectType: '*',
        });
        removeGroupPermissionPolicy({
          actionType: PolicyActionTypeInput.Admin,
          subjectType,
          objectId: '*',
          objectType: '*',
        });
      }

      if (row.original.key === 'ALL-*') {
        handleAdminChange(checked);
      }

      if (subjectType == 'GROUP') {
        handleGroupChange(checked, actionType);
      }

      if (subjectType == 'ROLE') {
        handleRoleChange(checked, actionType);
      }
    },
    [handleAdminChange, handleGroupChange, handleRoleChange, row.original.key, subjectType],
  );

  const types = useMemo(() => {
    const types: {
      name: string;
      checked: boolean;
      disabled: boolean;
      actionType: PolicyActionTypeInput;
    }[] = [];

    for (const actionType of currObjectTypeActionTypes) {
      const included = actionTypes.includes(actionType);
      types.push({
        checked: included,
        disabled: false,
        actionType,
        name:
          ACTION_TYPE_MAP[actionType as PolicyActionTypeInput].name === 'List'
            ? 'Restricted'
            : ACTION_TYPE_MAP[actionType as PolicyActionTypeInput].name,
      });
    }

    return types;
  }, [actionTypes, currObjectTypeActionTypes]);

  return (
    <div>
      {types.map(({ actionType, name, disabled, checked }) => {
        return (
          <label
            className={cn(
              'hover:cursor-pointer',
              'mt-3 flex items-center gap-3 color-neutral-800 typo-text-s first:mt-0',
              disabled && 'opacity-50',
            )}
            key={actionType}
          >
            <CheckBox
              checked={checked}
              className={cn({ 'hover:cursor-pointer': !disabled })}
              disabled={disabled}
              onChange={e => handleChangePermissionPolicy(e.target.checked, actionType)}
            />
            {name}
          </label>
        );
      })}
    </div>
  );
};
