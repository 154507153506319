import brand from '@/assets/brand.svg';
import { LOG_IN } from '@/constants/paths';
import { RESET_PASSWORD_REQUEST } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Spinner } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { z } from 'zod';

const schema = z.object({
  email: z.string().email(),
});

export type ForgotPasswordInputs = z.infer<typeof schema>;

export const ForgotPasswordPage = () => {
  const history = useHistory();

  const { control, handleSubmit, formState, getValues } = useForm<ForgotPasswordInputs>({
    resolver: zodResolver(schema),
  });

  const [hasSentEmail, setHasSentEmail] = useState(false);

  const requestToResetPassword = useMutation({
    mutationFn: ({ email }: ForgotPasswordInputs) =>
      request(RESET_PASSWORD_REQUEST, { hostDomain: window.location.origin, email }),
    onSuccess: () => setHasSentEmail(true),
  });

  const onSubmit: SubmitHandler<ForgotPasswordInputs> = (data, e) => {
    e?.preventDefault();
    requestToResetPassword.mutate(data);
  };

  return (
    <div className="h-100dvh w-100dvw overflow-auto bg-neutral-100 py-10 lg:py-20">
      <div className="m-auto max-w-98 flex flex-col px-4">
        <div className="self-center">
          <img className="h-auto w-50 sm:w-auto" src={brand} />
        </div>

        <p className="mt-14 color-neutral-800 typo-heading-4 lg:mt-28">Forgot your password?</p>

        {hasSentEmail ? (
          <>
            <p className="mt-6 color-neutral-700 typo-text-m">
              If we found a user with the email address{' '}
              <span className="color-neutral-800 typo-text-m-em">{getValues('email')}</span> you
              will receive an email from us shortly.
            </p>

            <p className="mt-3 color-neutral-700 typo-text-m">
              For more support, please contact us at{' '}
              <a
                className="cursor-pointer color-primary-400 no-underline typo-link-m"
                href="mailto:support@skand.io"
              >
                support@skand.io
              </a>
              .
            </p>

            <Button
              className="mt-3 cursor-pointer"
              onClick={() => history.push(LOG_IN)}
              primary
              size="s"
            >
              Back
            </Button>
          </>
        ) : (
          <form className="contents" onSubmit={handleSubmit(onSubmit)}>
            <p className="mt-6 color-neutral-700 typo-text-m">
              Enter your email address and we will send you a link to reset your password.
            </p>

            <div className="mt-6">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <Input
                    disabled={field.disabled}
                    hint={formState.errors.email?.message}
                    label="Email address"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    value={field.value}
                    warn={!!formState.errors.email}
                  />
                )}
              />
            </div>

            <Button
              className="mt-6 flex cursor-pointer items-center justify-center gap-1"
              disabled={requestToResetPassword.isLoading}
              filled
              primary
              size="s"
              type="submit"
            >
              {requestToResetPassword.isLoading && <Spinner className="animate-spin" />}
              Reset password
            </Button>

            <Button
              className="mt-3 cursor-pointer"
              onClick={() => history.push(LOG_IN)}
              primary
              size="s"
            >
              Cancel
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};
