import { SourceTargetPage } from '@/components/SourceTargetPage';
import { queryClient } from '@/graphql/client';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useGroup } from '@/hooks/useGroup';
import { useUsers } from '@/hooks/useUsers';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { GROUP_DETAILS_PAGE_MODE } from '@/constants/role';
import { useGroupStore } from '@/stores/group';

export const GroupsUsersPage = ({ mode }: { mode: keyof typeof GROUP_DETAILS_PAGE_MODE }) => {
  const { id } = useParams<{ id: string }>();
  const { group, groupUserIds } = useGroup(id);
  const { users } = useUsers();
  const groupUsersToBeAdded = useGroupStore(state => state.groupUsersToBeAdded);

  const { groupName } = useGroupStore(state => state);

  const groupUsers = useMemo(() => {
    return users.filter(user => groupUserIds.includes(user.id));
  }, [groupUserIds, users]);

  const newGroupUsers = useMemo(() => {
    return users.filter(user => groupUsersToBeAdded.map(i => i.sourceId).includes(user.id));
  }, [groupUsersToBeAdded, users]);

  const handleCreateOrDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(useGroup.getQueryKey(id));
    queryClient.invalidateQueries(useUsers.queryKey);
  }, [id]);

  return (
    <SourceTargetPage
      id={id}
      mode={mode}
      onCreateOrDeleteSuccess={handleCreateOrDeleteSuccess}
      source={mode === GROUP_DETAILS_PAGE_MODE.CREATE ? groupName : group}
      sourceTargetIds={groupUserIds}
      sourceTargetList={mode === GROUP_DETAILS_PAGE_MODE.CREATE ? newGroupUsers : groupUsers}
      sourceType={PolicySubjectTypeInput.Group}
      targetList={users}
      targetType={PolicySubjectTypeInput.User}
    />
  );
};
