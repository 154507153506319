import { FILE_IMAGE_ORIGINAL_URL_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { cn } from '@/utils/classname';
import { useQuery } from '@tanstack/react-query';
import { HTMLAttributes, useMemo } from 'react';

export interface ThumbnailProps extends HTMLAttributes<HTMLImageElement> {
  fileId: string;
  height: number;
  width: number;
}

export const Thumbnail = ({ fileId, height, width, ...props }: ThumbnailProps) => {
  const response = useQuery({
    queryFn: () => request(FILE_IMAGE_ORIGINAL_URL_BY_ID, { fileId }),
    queryKey: ['FILE_IMAGE_THUMBNAIL_URL_BY_ID', fileId],
  });

  const imageUrl = useMemo(() => {
    const file = response.data?.file;
    if (file?.__typename === 'ImageFile') return file.originalUrl;
    return null;
  }, [response.data?.file]);

  const heightClass = `h-[${height}px]`;
  const widthClass = `w-[${width}px]`;

  return (
    imageUrl && (
      <div
        className={cn(
          'border border-1 border-neutral-400 rounded-md border-solid',
          'flex justify-center',
          'hover:cursor-pointer',
          'overflow-hidden',
          heightClass,
          widthClass,
        )}
      >
        <img src={imageUrl} {...props} height={height} width="auto" />
      </div>
    )
  );
};
