export const isSolid = <T, K extends keyof T>(object: T, ...keys: K[]): object is Solid<T, K> => {
  for (const key of keys) {
    if (object[key] === null) return false;
    if (object[key] === undefined) return false;
  }

  return true;
};

export const isDefiend = <T>(object: T): object is NonNullable<T> => {
  return object !== null && object !== undefined;
};

export const hasId = <T extends null | undefined | { id?: unknown }>(
  object: T,
): object is SolidId<T> => {
  if (!isDefiend(object)) return false;
  return isSolid(object, 'id');
};

export const isEmpty = (value: unknown): value is null | undefined => {
  return value === undefined || value === null;
};

export const convertToTitleCase = (inputString: string): string => {
  const words = inputString.split('_');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  return words.join(' ');
};

export const parseAllRawValue = (value: string) => {
  return value === '*' ? 'ALL' : value;
};

export const handleSearchInputField = (e: string, setGlobalFilter: (value: string) => void) => {
  if (!e.trim()) {
    setGlobalFilter('');
    return;
  }
  setGlobalFilter(e);
};
