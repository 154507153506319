import { LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const queryKey = ['LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT'];

export const ANNOTATION_FIELD_TYPE = {
  TEXT: { label: 'Text', value: 'TEXT' },
  FILE: { label: 'File', value: 'FILE' },
  DATE: { label: 'Date', value: 'DATE' },
  URL: { label: 'Link', value: 'URL' },
  SELECT: { label: 'Select', value: 'SELECT' },
};

export const useAnnotationTemplates = () => {
  const response = useQuery({
    queryFn: () => request(LIST_ANNOTATION_TEMPLATES_BY_ACCOUNT_CONTEXT),
    queryKey: queryKey,
    refetchOnMount: true,
  });

  const templates = useMemo(() => {
    return response.data?.listAnnotationTemplatesByAccountContext?.filter(hasId) ?? [];
  }, [response.data?.listAnnotationTemplatesByAccountContext]);

  return { response, templates };
};

useAnnotationTemplates.queryKey = queryKey;
