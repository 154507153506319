import {
  CreateGroupingPoliciesMutation,
  DeleteGroupingPoliciesMutation,
  GroupingPolicy,
  GroupingPolicyInput,
  InputMaybe,
} from '@/graphql/codegen/graphql';
import { CREATE_GROUPING_POLICIES, DELETE_GROUPING_POLICIES } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { UseMutationResult, useMutation } from '@tanstack/react-query';

export interface UseGroupingPolicyMutation {
  createGroupingPolicy: UseMutationResult<
    CreateGroupingPoliciesMutation,
    unknown,
    GroupingPolicy[],
    unknown
  >;
  deleteGroupingPolicy: UseMutationResult<
    DeleteGroupingPoliciesMutation,
    unknown,
    GroupingPolicy[],
    unknown
  >;
}

export interface UsePermissionPolicyMutationProps {
  onCreateGroupingPoliciesSuccess?: () => void;
  onDeleteGroupingPoliciesSuccess?: () => void;
}

export const useGroupingPolicyMutation = (
  props?: UsePermissionPolicyMutationProps,
): UseGroupingPolicyMutation => {
  const createGroupingPolicy = useMutation<
    CreateGroupingPoliciesMutation,
    unknown,
    GroupingPolicy[],
    unknown
  >({
    mutationFn: groupingPolicies =>
      request(CREATE_GROUPING_POLICIES, {
        groupingPolicies: groupingPolicies as InputMaybe<GroupingPolicyInput>[],
      }),
    onSuccess: () => {
      props?.onCreateGroupingPoliciesSuccess?.();
    },
  });

  const deleteGroupingPolicy = useMutation<
    DeleteGroupingPoliciesMutation,
    unknown,
    GroupingPolicy[],
    unknown
  >({
    mutationFn: groupingPolicies =>
      request(DELETE_GROUPING_POLICIES, {
        groupingPolicies: groupingPolicies as InputMaybe<GroupingPolicyInput>[],
      }),
    onSuccess: () => {
      props?.onDeleteGroupingPoliciesSuccess?.();
    },
  });

  return { createGroupingPolicy, deleteGroupingPolicy };
};
