import { cn } from '@/utils/classname';
import { Link, LinkProps } from 'react-router-dom';

export type BackProps = LinkProps;

export const Back = ({ className, children, ...props }: BackProps) => {
  return (
    <Link
      className={cn(
        'flex items-center',
        'ring-1 ring-neutral-500 ring-inset',
        'outline-0 outline-solid outline-offset-0 outline-neutral-300',
        'px-2 py-1 color-neutral-800 decoration-none typo-text-s rounded-md',
        'hover:bg-neutral-300',
        'active:bg-neutral-400',
        'focus:outline-2',
        'min-w-[134px]',
        className,
      )}
      role="button"
      {...props}
    >
      <div className="i-skand-dropdown mr-2 rotate-90" />
      {children}
    </Link>
  );
};
