import { OBJECT_TYPE } from '@/constants/policy';
import { cn } from '@/utils/classname';
import { convertToTitleCase } from '@/utils/misc';
import * as Select from '@radix-ui/react-select';

const options = Object.keys(OBJECT_TYPE).filter(
  key => !['ALL', OBJECT_TYPE.ANNOTATION_GROUP, OBJECT_TYPE.PROJECT_NODE].includes(key),
);
interface ResourceTypeSelectProps {
  onValueChange: (value: string) => void;
}

export const ResourceTypeSelect = ({ onValueChange }: ResourceTypeSelectProps) => {
  return (
    <Select.Root onValueChange={onValueChange}>
      <Select.Trigger asChild>
        <div
          className={cn(
            'px-12px py-8px w-45',
            'typo-text-s b-1 b-solid b-rd-1 b-neutral-400',
            'flex justify-between items-center',
            'color-neutral-600 hover:cursor-pointer',
          )}
        >
          <Select.Value placeholder="Select a resource type" />
          <Select.Icon className="i-skand-dropdown color-neutral-400" />
        </div>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={cn(
            'z-1 bg-neutral-100 p-1 w-45',
            'typo-text-s b-1 b-solid b-rd-1 b-neutral-400',
          )}
          position="popper"
          side="bottom"
          sideOffset={5}
        >
          <Select.Viewport>
            {options.map(resource => {
              let content;

              if (resource == 'SCENE_ENTITY') {
                content = 'Layers';
              } else if (resource == 'SYSTEM_NODE') {
                content = 'Folders';
              } else {
                content = convertToTitleCase(resource.replace(/_/g, ' '));
              }
              return (
                <Select.Item
                  className={cn(
                    'hover:cursor-pointer hover:bg-neutral-200',
                    'color-neutral-800 p-2',
                  )}
                  key={resource}
                  value={resource}
                >
                  <Select.ItemText>{content}</Select.ItemText>
                </Select.Item>
              );
            })}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
