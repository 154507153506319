import { Button, Modal } from '@skand/ui';

export interface ConfirmationModalState {
  isOpen: boolean;
  title: string;
  description: string | JSX.Element;
  actionButton: string;
  actionFunction: () => void;
}

export type setConfirmationModalState = React.Dispatch<
  React.SetStateAction<ConfirmationModalState>
>;

interface ConfirmationModalProps {
  confirmationModalState: ConfirmationModalState;
  setConfirmationModalState: setConfirmationModalState;
}

export const ConfirmationModal = ({
  confirmationModalState: { title, description, actionButton, actionFunction },
  setConfirmationModalState,
}: ConfirmationModalProps) => {
  const handleCloseConfirmationModal = () => {
    setConfirmationModalState(state => {
      return { ...state, isOpen: false };
    });
  };

  return (
    <Modal>
      <div className="z-20 mt-1 flex flex-col gap-2">
        <p className="color-neutral-800 typo-text-m">{title}</p>
        <p className="color-neutral-800 typo-text-s">{description}</p>
      </div>
      <div className="w-full flex gap-2">
        <Button className="w-full cursor-pointer" onClick={handleCloseConfirmationModal} size="s">
          Cancel
        </Button>
        <Button
          className="w-full cursor-pointer"
          filled
          onClick={() => {
            actionFunction();
            setConfirmationModalState({
              isOpen: false,
              title: '',
              description: '',
              actionButton: '',
              actionFunction: () => null,
            });
            handleCloseConfirmationModal();
          }}
          primary
          size="s"
        >
          {actionButton}
        </Button>
      </div>
    </Modal>
  );
};
