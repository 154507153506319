import * as PATHS from '@/constants/paths';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { Link } from 'react-router-dom';

export const NameCell = ({
  id,
  value,
  targetType,
}: {
  id: string;
  value: string;
  targetType: PolicySubjectTypeInput;
}) => {
  const targetTypeToPath = {
    [PolicySubjectTypeInput.User]: `${PATHS.MANAGE_USERS}/${id}/details`,
    [PolicySubjectTypeInput.Group]: `${PATHS.MANAGE_GROUPS}/${id}/details`,
    [PolicySubjectTypeInput.Role]: `${PATHS.MANAGE_ROLES}/${id}/details`,
    [PolicySubjectTypeInput.ShareLink]: PATHS.MANAGE_ROLES, // Unsupported
  };

  const path: string = targetTypeToPath[targetType];

  return (
    <Link className="color-neutral-800 underline typo-link-s" to={path}>
      {value}
    </Link>
  );
};
