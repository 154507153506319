import { create } from 'zustand';

interface PermissionPolicyStore {
  isAllAdminChecked: boolean;
}

export const usePermissionStore = create<PermissionPolicyStore>()(() => ({
  isAllAdminChecked: false,
}));

export const useSubjectPermissionPage = create()(() => ({
  subjectType: '',
}));

export const setAllAdminChecked = (isAllAdminChecked: boolean) => {
  usePermissionStore.setState({ isAllAdminChecked });
};

export const setSubjectPermissionPage = (subjectType: string) => {
  useSubjectPermissionPage.setState({ subjectType });
};
