import { SourceTargetPage } from '@/components/SourceTargetPage';
import { queryClient } from '@/graphql/client';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useRoleQuery } from '@/hooks/useRoleQuery';
import { useUsers } from '@/hooks/useUsers';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const RolesUsersPage = () => {
  const { id } = useParams<{ id: string }>();
  const { role, roleUserIds } = useRoleQuery(id);
  const { users } = useUsers();

  const roleUsers = useMemo(() => {
    return users.filter(user => roleUserIds.includes(user.id));
  }, [roleUserIds, users]);

  const handleCreateOrDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(useRoleQuery.getQueryKey(id));
    queryClient.invalidateQueries(useUsers.queryKey);
  }, [id]);

  return (
    <SourceTargetPage
      id={id}
      onCreateOrDeleteSuccess={handleCreateOrDeleteSuccess}
      source={role}
      sourceTargetIds={roleUserIds}
      sourceTargetList={roleUsers}
      sourceType={PolicySubjectTypeInput.Role}
      targetList={users}
      targetType={PolicySubjectTypeInput.User}
    />
  );
};
