import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { SubjectPermissions } from '@/components/SubjectPermissionsPage';
import { useParams } from 'react-router-dom';
import { useGroup } from '@/hooks/useGroup';

export const GroupsPermissionsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { group } = useGroup(id);

  return <SubjectPermissions id={id} subject={group} subjectType={PolicySubjectTypeInput.Group} />;
};
