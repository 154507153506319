import { PolicySubjectTypeInput, UserGroup } from '@/graphql/codegen/graphql';
import { useGroupingPolicies } from '@/hooks/useGroupingPolicies';
import { Row } from '@tanstack/react-table';

export interface GroupCellProps {
  row: Row<SolidId<UserGroup>>;
}

export const UserCell = ({ row }: GroupCellProps) => {
  const { policies } = useGroupingPolicies({
    sourceId: null,
    sourceType: PolicySubjectTypeInput.User,
    targetId: row.original.id,
    targetType: PolicySubjectTypeInput.Group,
  });

  return <span className="typo-text-s">{policies.length}</span>;
};
