import { LIST_ROLES_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['LIST_ROLES_BY_ACCOUNT_CONTEXT'];

export const useUserRolesByContext = () => {
  const response = useQuery({
    queryFn: () => request(LIST_ROLES_BY_ACCOUNT_CONTEXT),
    queryKey,
  });

  const roles = useMemo(() => response.data?.listRolesByAccountContext ?? [], [response.data]);

  return { roles };
};

useUserRolesByContext.queryKey = queryKey;
