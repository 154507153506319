import { LIST_PROJECTS_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export const queryKey = ['LIST_PROJECTS_BY_ACCOUNT_CONTEXT'];

export const useProjects = () => {
  const response = useQuery({
    queryFn: () => request(LIST_PROJECTS_BY_ACCOUNT_CONTEXT),
    queryKey,
  });

  const projects = useMemo(() => {
    return response.data?.listProjectsByAccountContext?.filter(hasId) ?? [];
  }, [response.data?.listProjectsByAccountContext]);

  return { response, projects };
};

useProjects.queryKey = queryKey;
