import { LIST_USERS_BY_ACCOUNT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['LIST_USERS_BY_ACCOUNT_ID'];

export const useListUsersByAccountId = () => {
  const response = useQuery({
    queryFn: () => request(LIST_USERS_BY_ACCOUNT_ID),
    queryKey,
    refetchOnMount: true,
  });

  const users = useMemo(() => {
    return response.data?.listUsersByAccountId?.filter(hasId) ?? [];
  }, [response.data?.listUsersByAccountId]);

  return { response, users };
};

useListUsersByAccountId.queryKey = queryKey;
