import { FileNode, FolderNode } from '@/graphql/codegen/graphql';

export const NODE_KIND = {
  FILE_NODE: 'FileNode',
  FOLDER_NODE: 'FolderNode',
};

export type NodeKind = keyof typeof NODE_KIND;

export type SystemNode = FileNode | FolderNode;
