import { AccessGate, FeatureNotIncluded } from '@/components/AccessGate';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { Button } from '@skand/ui';
import { MouseEventHandler } from 'react';

export const ChangeButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  const disabledButton = (
    <Button className="flex-1" disabled primary size="s">
      Change
    </Button>
  );

  return (
    <AccessGate
      disabled={() => (
        <FeatureNotIncluded button={disabledButton} readMoreUrl={READ_MORE_LINKS.BRANDING} />
      )}
      enabled={() => (
        <Button className="flex-1 cursor-pointer" onClick={onClick} primary size="s">
          Change
        </Button>
      )}
      entitlementCheck={{ featureName: ENTITLEMENT_NAME.BRANDING }}
      loading={() => disabledButton}
    />
  );
};

export const DeleteButton = ({ onClick }: { onClick: MouseEventHandler<HTMLButtonElement> }) => {
  const disabledButton = (
    <Button className="flex-1" disabled size="s">
      Delete
    </Button>
  );

  return (
    <AccessGate
      disabled={() => (
        <FeatureNotIncluded button={disabledButton} readMoreUrl={READ_MORE_LINKS.BRANDING} />
      )}
      enabled={() => (
        <Button className="flex-1 cursor-pointer" onClick={onClick} size="s">
          Delete
        </Button>
      )}
      entitlementCheck={{ featureName: ENTITLEMENT_NAME.BRANDING }}
      loading={() => disabledButton}
    />
  );
};
