import { SubjectPermissions } from '@/components/SubjectPermissionsPage';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useUser } from '@/hooks/useUser';
import { useParams } from 'react-router-dom';

export const UsersPermissionsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useUser(id);

  return <SubjectPermissions id={id} subject={user} subjectType={PolicySubjectTypeInput.User} />;
};
