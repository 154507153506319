import { UPDATE_USER } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';

export const useChangeUserPassword = () => {
  const mutation = useMutation({
    mutationFn: ({
      currentPassword,
      newPassword,
      passwordConfirm,
    }: {
      currentPassword: string;
      newPassword: string;
      passwordConfirm: string;
    }) =>
      request(UPDATE_USER, {
        user: {
          currentPassword,
          newPassword,
          passwordConfirm,
        },
      }),
    onSuccess: () => {
      toast({
        type: 'success',
        message: 'Password has been updated successfully',
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
    onError: () => {
      toast({
        type: 'warn',
        message: "Password couldn't be updated",
        lifespan: 10000,
        clickToDismiss: true,
      });
    },
  });

  return { mutation };
};
