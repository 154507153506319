import { GROUP_DETAILS_PAGE_MODE } from '@/constants/role';
import { PATHS } from '@/index';
import { FormEventHandler, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from './Content';
import { useGroupStore, setGroupName, setGroupDescription } from '@/stores/group';

export const CreateMode = () => {
  const history = useHistory();
  const groupName = useGroupStore(state => state.groupName);
  const groupDescription = useGroupStore(state => state.groupDescription);

  const handleSubmitEditRoleDetails: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      history.push(PATHS.MANAGE_GROUPS_CREATE_PERMISSIONS);
    },
    [history],
  );

  return (
    <Content
      groupDescription={groupDescription}
      groupName={groupName}
      mode={GROUP_DETAILS_PAGE_MODE.CREATE}
      onSubmitEditRoleDetails={handleSubmitEditRoleDetails}
      setGroupDescription={setGroupDescription}
      setGroupName={setGroupName}
    />
  );
};
