import { SUBJECT_TYPE } from '@/constants/policy';
import { READ_ROLE_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId, isEmpty } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (roleId: string) => ['READ_ROLE_BY_ID', roleId];

export const useRoleQuery = (roleId: null | string) => {
  const response = useQuery({
    enabled: !!roleId,
    queryFn: () => request(READ_ROLE_BY_ID, { roleId: roleId as string }),
    queryKey: getQueryKey(roleId as string),
    refetchOnMount: true,
  });

  const role = useMemo(() => {
    const role = response.data?.readRoleById;

    if (!hasId(role)) return null;
    return role;
  }, [response.data?.readRoleById]);

  const roleUserIds = useMemo(
    () =>
      (role?.userToRolePolicies ?? []).reduce((acc, policy) => {
        if (policy?.sourceType === SUBJECT_TYPE.USER && !isEmpty(policy.sourceId))
          acc.push(policy.sourceId);
        return acc;
      }, [] as string[]),
    [role?.userToRolePolicies],
  );

  const roleGroupIds = useMemo(
    () =>
      (role?.userGroupToRolePolicies ?? []).reduce((acc, policy) => {
        if (policy?.sourceType === SUBJECT_TYPE.GROUP && !isEmpty(policy.sourceId))
          acc.push(policy.sourceId);
        return acc;
      }, [] as string[]),
    [role?.userGroupToRolePolicies],
  );

  return {
    response,
    role,
    roleUserIds,
    roleGroupIds,
  };
};

useRoleQuery.getQueryKey = getQueryKey;
