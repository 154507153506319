import {
  createAnnotation,
  createAnnotationGroup,
  createLayer,
  createLayerGroup,
  createPanorama,
  createPhoto2D,
  createPhotoGroup,
} from '@/components/SceneEntityTree/transformers';
import { LayerFormatType } from '@/constants/layer';
import { RENDERER_TYPE } from '@/constants/renderer';
import { ImageProjectionRenderObject, PanoramicRenderObject, SceneEntity } from '@/graphql/codegen/graphql';
import { useListAnnotationsByProjectId } from '@/hooks/useListAnnotationsByProjectId';
import { useListLayerByProjectId } from '@/hooks/useListLayerByProjectId';
import { useListSceneEntitiesByProjectIdAndRendererTypesQuery } from '@/hooks/useListSceneEntitiesByProjectIdAndRendererTypesQuery';
import { isEmpty } from '@/utils/misc';
import { Graph } from '@skand/math';
import { useCallback, useMemo } from 'react';

export interface LayerGroup {
  type: 'layerGroup';
  id: string;
  name: string;
  sceneEntityId: string;
  parent?: Layer | LayerGroup;
}

export interface Layer {
  type: 'layer';
  id: string;
  name: string;
  captureDate: Date;
  sceneEntityId: string;
  supportingSceneEntityIds: string[];
  formatType: LayerFormatType;
  parent?: Layer | LayerGroup;
}

export interface Annotation {
  type: 'annotation';
  id: string;
  versionId: string;
  name: string;
  group: AnnotationGroup;
  hasPhoto?: boolean;
  hasSketch3D?: boolean;
  hasSketch2D?: boolean;
  // sketch3D?: ModelNode;
}

export interface AnnotationGroup {
  type: 'annotationGroup';
  id: string;
  name: string;
  sceneEntityId: string;
  parent?: Layer | LayerGroup;
  annotations: Annotation[];
}

export interface BasePhoto {
  id: string;
  name: string;
  group: PhotoGroup;
}

export interface Photo2D extends BasePhoto {
  type: 'photo2D';
}

export interface Panorama extends BasePhoto {
  type: 'panorama';
}

export type Photo = Photo2D | Panorama;

export interface PhotoGroup {
  type: 'photoGroup';
  id: string;
  name: string;
  sceneEntityId: string;
  parent?: Layer | LayerGroup;
  photos: Photo[];
}

export interface SceneEntityNode {
  entity: LayerGroup | Layer | Annotation | AnnotationGroup | Photo | PhotoGroup;
  children: SceneEntityNode[];
}

export const useSceneEntityData = ({
  projectId,
  filterNodeTypes,
}: {
  projectId?: string;
  filterNodeTypes: string[];
}) => {
  const {
    sceneEntities,
    response: { isFetching: isFetchingSceneEntities },
  } = useListSceneEntitiesByProjectIdAndRendererTypesQuery({
    projectId: projectId,
  });
  const {
    layers: layersQueryData,
    response: { isFetching: isFetchingLayers },
  } = useListLayerByProjectId({
    projectId: projectId,
  });
  const {
    annotations,
    response: { isFetching: isFetchingAnnotations },
  } = useListAnnotationsByProjectId({
    projectId: projectId,
  });

  const isLoadingTreeData = isFetchingSceneEntities || isFetchingLayers || isFetchingAnnotations;

  const applyNodeTypeFilters = useCallback(
    (list: SceneEntityNode[]) => {
      return list
        .map(item => {
          return { ...item };
        })
        .filter(item => {
          if ('children' in item) {
            item.children = applyNodeTypeFilters(item.children);
          }
          let filteredItem;
          if (filterNodeTypes.includes(item.entity.type)) {
            filteredItem = item;
          }
          // Adding annotation entity type if annotationGroup type is available in the filter.
          if (filterNodeTypes.includes('layerGroup') && item.entity.type === 'layerGroup') {
            filteredItem = item;
          }
          if (filterNodeTypes.includes('annotationGroup') && item.entity.type === 'annotation') {
            filteredItem = item;
          }
          if (item.entity.type == 'layer' && filterNodeTypes.includes(item.entity.formatType)) {
            filteredItem = item;
          }
          if (
            !filterNodeTypes.includes('sketch3D') &&
            item.entity.type == 'annotationGroup' &&
            item.entity.annotations
          ) {
            if (item.entity.annotations.filter(annotation => annotation.hasSketch3D).length > 0)
              return;
          }
          if (
            !filterNodeTypes.includes('sketch2D') &&
            item.entity.type == 'annotationGroup' &&
            item.entity.annotations
          ) {
            if (item.entity.annotations.filter(annotation => annotation.hasSketch2D).length > 0)
              return;
          }
          return filteredItem;
        });
    },
    [filterNodeTypes],
  );

  const treeData: SceneEntityNode[] = useMemo(() => {
    const layerGroups: LayerGroup[] = [];
    const layers: Layer[] = [];
    const annotationGroups: AnnotationGroup[] = [];
    const panoramaGroups: PhotoGroup[] = [];
    const photo2DGroups: PhotoGroup[] = [];

    // Link nodes together
    const graph = new Graph<string>();
    const sceneEntityMap = new Map<string, SceneEntity>();
    const objectMap = new Map<string, Layer | LayerGroup | AnnotationGroup | PhotoGroup>();

    // Pre-process scene entity graph
    for (const entity of sceneEntities) {
      if (!isEmpty(entity) && !isEmpty(entity.id)) {
        graph.addNode(entity.id);
        sceneEntityMap.set(entity.id, entity);

        // Define dependency
        if (!isEmpty(entity.parentSceneEntityId)) {
          graph.addEdge(entity.parentSceneEntityId, entity.id);
        }

        // Handle case of supporting scene entity ids (DEPRECATE)
        const layer = layersQueryData.find(
          layer => layer?.mainSceneEntityId === entity.id,
        );
        if (layer) {
          for (const childId of layer.supportingSceneEntityIds ?? []) {
            if (childId) {
              graph.addEdge(entity.id, childId);
            }
          }
        }
      }
    }

    // Process each node in order
    const sorted = graph.topologicalSort() ?? [];
    for (const nodeId of sorted) {
      const parentId = graph.getParents(nodeId)[0];
      const node = sceneEntityMap.get(nodeId);
      const parent = objectMap.get(parentId) as Layer | undefined;
      if (!node) {
        throw new Error(`useFetchSceneEntities: Scene entity ${nodeId} not found`);
      }

      // Create the nodes by renderer type
      if (node.rendererType === null) {
        const group = createLayerGroup(node, parent);
        if (group) {
          layerGroups.push(group);
          objectMap.set(nodeId, group);
        }
      } else if (node.rendererType === RENDERER_TYPE.ANNOTATION) {
        const group = createAnnotationGroup(node, parent);
        if (group) {
          annotationGroups.push(group);
          objectMap.set(nodeId, group);
        }
      } else if (node.rendererType === RENDERER_TYPE.IMAGE_PROJECTION) {
        const group = createPhotoGroup(node, parent);
        if (group) {
          photo2DGroups.push(group);
          objectMap.set(nodeId, group);

          const renderObject = node.renderObject as ImageProjectionRenderObject;
          for (const photoQuery of renderObject.images ?? []) {
            if (photoQuery) {
              const photo = createPhoto2D(photoQuery, group);
              if (photo) {
                group.photos.push(photo);
              }
            }
          }

        }
      } else if (node.rendererType === RENDERER_TYPE.PANORAMIC_IMAGE) {
        const group = createPhotoGroup(node, parent);
        if (group) {
          panoramaGroups.push(group);
          objectMap.set(nodeId, group);

          const renderObject = node.renderObject as PanoramicRenderObject;
          for (const photoQuery of renderObject.panoramicImages ?? []) {
            if (photoQuery) {
              const photo = createPanorama(photoQuery, group);
              if (photo) {
                group.photos.push(photo);
              }
            }
          }
        }
      } else {
        const layerQuery = layersQueryData.find(
          layer => layer?.mainSceneEntityId === nodeId,
        );
        if (layerQuery) {
          const layer = createLayer(node, layerQuery, parent);
          if (layer) {
            layers.push(layer);
            objectMap.set(nodeId, layer);
          }
        }
      }
    }

    // Populate groups with annotations
    for (const annotationQuery of annotations) {
      if (
        !isEmpty(annotationQuery) &&
        !isEmpty(annotationQuery.id) &&
        !isEmpty(annotationQuery.groupId)
      ) {
        const group = annotationGroups.find(group => group.id === annotationQuery.groupId);
        if (group) {
          const annotation = createAnnotation(
            annotationQuery,
            group,
            photo2DGroups,
          );
          if (annotation) {
            group.annotations.push(annotation);
          }
        }
      }
    }

    const entities = [
      ...layerGroups,
      ...layers,
      ...annotationGroups,
      ...photo2DGroups,
      ...panoramaGroups,
    ];
    const nodeMap = new Map<(typeof entities)[number], SceneEntityNode>();
    for (const entity of entities) {
      const node: SceneEntityNode = { entity, children: [] };
      if (entity.type === 'annotationGroup') {
        node.children = entity.annotations.map(child => ({
          entity: child,
          children: [],
        }));
      } else if (entity.type === 'photoGroup') {
        node.children = entity.photos.map(child => ({ entity: child, children: [] }));
      }
      nodeMap.set(entity, node);
    }

    const nodes: SceneEntityNode[] = [];
    for (const entity of entities) {
      const node = nodeMap.get(entity);
      if (node) {
        if (entity.parent) {
          const parent = nodeMap.get(entity.parent);
          if (parent) {
            parent.children.push(node);
          }
        } else {
          nodes.push(node);
        }
      }
    }

    const filteredNodes = applyNodeTypeFilters(nodes);
    return filteredNodes;
  }, [sceneEntities, layersQueryData, annotations, applyNodeTypeFilters]);

  return { treeData, isLoadingTreeData };
};
