export enum ROLE_DETAILS_PAGE_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum ROLE_CREATED_BY {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}

export enum GROUP_DETAILS_PAGE_MODE {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
}

export enum GROUP_CREATED_BY {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
}
