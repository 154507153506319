import { SourceTargetPage } from '@/components/SourceTargetPage';
import { queryClient } from '@/graphql/client';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useGroupingPolicies } from '@/hooks/useGroupingPolicies';
import { useRolesQuery } from '@/hooks/useRolesQuery';
import { useUser } from '@/hooks/useUser';
import { useUserRoles } from '@/hooks/useUserRoles';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

export const UsersRolesPage = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useUser(id);
  const { userRoles, userRoleIds } = useUserRoles(id);
  const { roles } = useRolesQuery();

  const handleCreateOrDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      useGroupingPolicies.getQueryKey({
        sourceId: id,
        sourceType: PolicySubjectTypeInput.User,
        targetId: null,
        targetType: PolicySubjectTypeInput.Role,
      }),
    );
  }, [id]);

  return (
    <SourceTargetPage
      id={id}
      onCreateOrDeleteSuccess={handleCreateOrDeleteSuccess}
      source={user}
      sourceTargetIds={userRoleIds}
      sourceTargetList={userRoles}
      sourceType={PolicySubjectTypeInput.User}
      targetList={roles}
      targetType={PolicySubjectTypeInput.Role}
    />
  );
};
