import { UserGroup } from '@/graphql/codegen/graphql';
import { LIST_USER_GROUPS_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['LIST_USER_GROUPS_BY_ACCOUNT_CONTEXT'];

export const useUserGroups = () => {
  const response = useQuery({
    queryFn: () => request(LIST_USER_GROUPS_BY_ACCOUNT_CONTEXT),
    queryKey,
  });

  const groups: SolidId<UserGroup>[] = useMemo(() => {
    return response.data?.listUserGroupsByAccountContext?.filter(hasId) ?? [];
  }, [response.data?.listUserGroupsByAccountContext]);

  return { response, groups };
};

useUserGroups.queryKey = queryKey;
