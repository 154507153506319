import { queryClient } from '@/graphql/client';
import { INVITE_USER } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useEntitlements } from './useEntitlements';
import { useListUsersByAccountId } from './useListUsersByAccountId';
import { useUsers } from './useUsers';

export const useInviteUser = (
  newUserEmail: string,
  firstName: string,
  lastName: string,
  selectedRoleId: string,
  selectedGroupId: string,
) => {
  const response = useMutation({
    mutationFn: () =>
      request(INVITE_USER, {
        newUserEmail,
        firstName,
        lastName,
        roleId: selectedRoleId,
        userGroupId: selectedGroupId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useUsers.queryKey);
      queryClient.invalidateQueries(useListUsersByAccountId.queryKey);
      queryClient.invalidateQueries(useEntitlements.queryKey);

      toast({
        type: 'success',
        message: "User's invite has been sent successfully",
        lifespan: 10000,
      });
    },
    onError: () => {
      toast({
        type: 'warn',
        message: "User invite couldn't be sent",
        lifespan: 10000,
      });
    },
  });

  return { response };
};
