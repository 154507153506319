import { SourceTargetPage } from '@/components/SourceTargetPage';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useGroups } from '@/hooks/useGroups';
import { useUser } from '@/hooks/useUser';
import { useUserGroupsByUserId } from '@/hooks/useUserGroupsByUserId';
import { useParams } from 'react-router-dom';
import { queryClient } from '@/graphql/client';
import { useCallback } from 'react';
import { useGroupingPolicies } from '@/hooks/useGroupingPolicies';

export const UsersGroupsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useUser(id);
  const { userGroups, userGroupIds } = useUserGroupsByUserId(id);
  const { groups } = useGroups();

  const handleCreateOrDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      useGroupingPolicies.getQueryKey({
        sourceId: id,
        sourceType: PolicySubjectTypeInput.User,
        targetId: null,
        targetType: PolicySubjectTypeInput.Group,
      }),
    );
  }, [id]);

  return (
    <SourceTargetPage
      id={id}
      onCreateOrDeleteSuccess={handleCreateOrDeleteSuccess}
      source={user}
      sourceTargetIds={userGroupIds}
      sourceTargetList={userGroups}
      sourceType={PolicySubjectTypeInput.User}
      targetList={groups}
      targetType={PolicySubjectTypeInput.Group}
    />
  );
};
