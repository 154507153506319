export const SUBJECT_TYPE = {
  USER: 'USER',
  GROUP: 'GROUP',
  ROLE: 'ROLE',
};
export type SubjectType = keyof typeof SUBJECT_TYPE;

export const OBJECT_TYPE = {
  ALL: '*',
  SYSTEM_NODE: 'SYSTEM_NODE',
  PROJECT: 'PROJECT',
  PROJECT_NODE: 'PROJECT_NODE',
  PROJECT_GROUP: 'PROJECT_GROUP',
  SCENE_ENTITY: 'SCENE_ENTITY',
  ANNOTATION_GROUP: 'ANNOTATION_GROUP',
};
export type ObjectType = keyof typeof OBJECT_TYPE;

export const ACTION_TYPE = {
  ADMIN: 'ADMIN',
  EDIT: 'EDIT',
  EDIT_REQUESTER: 'EDIT_REQUESTER',
  READ: 'READ',
  LIST: 'LIST',
};
export type ActionType = keyof typeof ACTION_TYPE;

export const OBJECT_ID = {
  ALL: '*',
};
