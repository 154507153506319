import {
  BIMIcon,
  DXFIcon,
  IFCIcon,
  ImageIcon,
  Mesh3DIcon,
  OBJIcon,
  PointCloudIcon,
  TerrainIcon,
} from '@/components/IconButton';
import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';
import { Layer } from '../../hooks/useSceneEntityTreeData';

export interface LayerNodeProps {
  layer: Layer;
}

export const LayerNode = ({ layer }: LayerNodeProps) => {
  // Layer format icon
  const FormatTypeIcon = useMemo(() => {
    switch (layer.formatType) {
      case LAYER_FORMAT_TYPE.IFC:
        return <IFCIcon />;
      case LAYER_FORMAT_TYPE.OBJ:
        return <OBJIcon />;
      case LAYER_FORMAT_TYPE.POINT_CLOUD:
        return <PointCloudIcon />;
      case LAYER_FORMAT_TYPE.MESH_3D:
        return <Mesh3DIcon />;
      case LAYER_FORMAT_TYPE.ORTHO_2D:
      case LAYER_FORMAT_TYPE.TERRAIN:
        return <TerrainIcon />;
      case LAYER_FORMAT_TYPE.IMAGES:
        return <ImageIcon />;
      case LAYER_FORMAT_TYPE.DXF:
        return <DXFIcon />;
      case LAYER_FORMAT_TYPE.BIM_CAD_MODEL:
        return <BIMIcon />;
    }
  }, [layer.formatType]);

  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800 whitespace-nowrap')}>{layer.name}</p>
        {FormatTypeIcon}
      </div>
    </div>
  );
};
