import {
  AnnotationTemplateSelectField,
  AnnotationTemplateSelectOption,
} from '@/graphql/codegen/graphql';
import { WithMode, changeOption } from '@/stores/template';
import { cn } from '@/utils/classname';
import { ColorPicker } from '@skand/ui';
import { useCallback, useState } from 'react';
import { ColorResult } from 'react-color';

export interface ColorProps {
  field: WithMode<AnnotationTemplateSelectField>;
  index: number;
  option: null | AnnotationTemplateSelectOption;
}

export const Color = ({ field, index, option }: ColorProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = useCallback(
    (color: ColorResult) => {
      changeOption(field, index, { color: color.hex.toUpperCase() });
    },
    [field, index],
  );

  return (
    <>
      <div
        className={cn(
          'w-128px',
          'b-1 b-solid hover:b-neutral-800',
          'flex-nowrap flex-row flex',
          'items-center justify-between',
          'p-x-3 p-y-2',
          'rounded-1',
          'typo-text-s',
          'color-neutral-600',
          'cursor-pointer',
          isOpen ? 'b-neutral-800' : 'b-neutral-400',
        )}
        onClick={() => setIsOpen(true)}
      >
        {option?.color ?? 'Select colour'}
        <div
          className={cn('w-3 h-3', 'ml-4', 'rounded-full', 'ring-1 ring-inset ring-neutral-300')}
          style={{ backgroundColor: option?.color ?? '#FFFFFF' }}
        />
      </div>
      {isOpen && (
        <ColorPicker
          closeMenu={() => setIsOpen(false)}
          color={option?.color ?? '#FFFFFF'}
          colorPickerHeader="Custom colour"
          openingCoordinates={{ x: -90, y: 170 }}
          setColor={handleChange}
        />
      )}
    </>
  );
};
