import { AccessGate, FeatureNotIncluded } from '@/components/AccessGate';
import { FindIcon } from '@/components/IconButton';
import { SideBar } from '@/components/SideBar';
import { Table } from '@/components/Table';
import { MANAGE_GROUPS_CREATE_DETAILS } from '@/constants/paths';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { useGroups } from '@/hooks/useGroups';
import { cn } from '@/utils/classname';
import { Button, Input } from '@skand/ui';
import {
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { columns } from './columns';
import { SearchResultsSummary } from '@/components/SearchResultsSummary';
import { EmptyListComponent, EmptyListSeachTopic } from '@/components/EmptyListComponent';
import { handleSearchInputField } from '@/utils/misc';

export const GroupsPage = () => {
  const {
    groups,
    response: { isFetching },
  } = useGroups();

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const table = useReactTable({
    columns,
    data: groups,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    state: { globalFilter, sorting },
    onSortingChange: setSorting,
  });

  const createGroup = (
    <AccessGate
      disabled={() => (
        <FeatureNotIncluded
          button={
            <Button className="min-w-[134px]" disabled filled primary size="s">
              Create User Group
            </Button>
          }
          readMoreUrl={READ_MORE_LINKS.GROUP}
        />
      )}
      enabled={() => (
        <NavLink to={MANAGE_GROUPS_CREATE_DETAILS}>
          <Button className="min-w-[134px] cursor-pointer" filled primary size="s">
            Create User Group
          </Button>
        </NavLink>
      )}
      entitlementCheck={{ featureName: ENTITLEMENT_NAME.USER_GROUP }}
      loading={() => (
        <Button className="min-w-[134px]" disabled filled primary size="s">
          Create User Group
        </Button>
      )}
    />
  );

  const displayedGroups = table.getRowModel().rows.length;

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('max-h-fit', 'w-full', 'px-6', 'overflow-auto', 'flex flex-col')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'justify-between',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800')}>User Groups</h1>

          {createGroup}
        </div>

        <div className="mt-3 flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">
            Create groups to provide permissions across multiple users.
          </p>

          <div className="flex items-center justify-center gap-2">
            {globalFilter && displayedGroups > 0 && (
              <div className="max-w-360px">
                <SearchResultsSummary dataLength={displayedGroups} searchKey={globalFilter} />
              </div>
            )}
            <div className={cn('w-360px', !groups.length && 'opacity-50')}>
              <Input
                disabled={!groups.length}
                onChange={e => handleSearchInputField(e, setGlobalFilter)}
                placeholder="Search for groups"
                tail={<FindIcon />}
                value={globalFilter}
              />
            </div>
          </div>
        </div>

        <Table
          className="mb-8 mt-6"
          empty={
            <EmptyListComponent searchTerm={globalFilter} searchTopic={EmptyListSeachTopic.GROUPS}>
              {createGroup}
            </EmptyListComponent>
          }
          isLoading={isFetching}
          table={table}
        />
      </div>
    </div>
  );
};
