import { SideBar } from '@/components/SideBar';
import { useAnnotationTemplates } from '@/hooks/useAnnotationTemplates';
import { updateTemplate } from '@/stores/template';
import { cn } from '@/utils/classname';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TemplatePanel } from './TemplatePanel';

export const TemplatesEditPage = () => {
  const { id } = useParams<{ id: string }>();

  const { templates } = useAnnotationTemplates();

  useEffect(() => {
    const template = templates.find(template => template.id === id);
    if (template) updateTemplate(template);
  }, [id, templates]);

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <TemplatePanel mode="update" />
    </div>
  );
};
