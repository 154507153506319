// import { User } from '@/utils/interfaces';
import { UserStatus as UserStatuses } from '@/graphql/codegen/graphql';

interface UserStatusProps {
  status?: null | UserStatuses;
}

export const UserStatus = ({ status }: UserStatusProps) => {
  return (
    <div className="w-100px">
      <div className="w-fit">
        {status === UserStatuses.Active && (
          <div className="h-full flex items-center justify-start gap-2 border-success-400 rounded border-solid bg-success-100 px-2 py-1">
            <div className="i-skand-done color-success-400" />
            <p className="color-neutral-800 typo-text-xs">Accepted</p>
          </div>
        )}
        {status === UserStatuses.Invited && (
          <div className="h-full flex items-center justify-start gap-2 border-info-400 rounded border-solid bg-info-100 px-2 py-1">
            <div className="i-skand-info color-info-400" />
            <p className="color-neutral-800 typo-text-xs">Pending</p>
          </div>
        )}
        {status === UserStatuses.Deactivated && (
          <div className="h-full flex items-center justify-start gap-2 border-neutral-400 rounded border-solid bg-neutral-100 px-2 py-1">
            <div className="i-skand-info color-neutral-400" />
            <p className="color-neutral-800 typo-text-xs">Deactivated</p>
          </div>
        )}
      </div>
    </div>
  );
};
