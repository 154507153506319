import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';

export const getEntitlementName = (
  sourceType: PolicySubjectTypeInput,
  targetType: PolicySubjectTypeInput,
) => {
  if (sourceType === PolicySubjectTypeInput.User) {
    if (targetType === PolicySubjectTypeInput.Group) return ENTITLEMENT_NAME.USER_GROUP;
    if (targetType === PolicySubjectTypeInput.Role) return ENTITLEMENT_NAME.ROLE;
  }

  if (sourceType === PolicySubjectTypeInput.Group) {
    if (targetType === PolicySubjectTypeInput.User) return ENTITLEMENT_NAME.USER_GROUP;
    if (targetType === PolicySubjectTypeInput.Role) return ENTITLEMENT_NAME.ROLE;
  }

  if (sourceType === PolicySubjectTypeInput.Role) {
    if (targetType === PolicySubjectTypeInput.User) return ENTITLEMENT_NAME.ROLE;
    if (targetType === PolicySubjectTypeInput.Group) return ENTITLEMENT_NAME.USER_GROUP;
  }

  return null;
};
