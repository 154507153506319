import { cn } from '@/utils/classname';
import { NavLink, NavLinkProps } from 'react-router-dom';

const TabLink = ({ children, ...props }: NavLinkProps) => {
  const className = (isActive: boolean) =>
    cn(
      isActive
        ? ' color-neutral-800 border-l-neutral-800 no-underline'
        : ' decoration-none color-neutral-400 ',
      'border-l border-l-solid border-l-1 border-l-neutral-400',
      'pl-6 ml-12 py-1',
      'typo-text-small	',
    );

  return (
    <NavLink className={className} {...props}>
      {children}
    </NavLink>
  );
};

interface SideTabPanelProps {
  links: { path: string; title: string }[];
}

export const SideTabPanel = ({ links }: SideTabPanelProps) => {
  if (links.length === 0) return null;

  return (
    <div className="ml-auto mr-12 w-fit flex flex-col whitespace-nowrap pl-4">
      {links.map(link => (
        <TabLink key={link.path} to={link.path}>
          {link.title}
        </TabLink>
      ))}
    </div>
  );
};
