import { ACCESS_TOKEN_NAME, GRAPHQL_URL } from '@/constants/env';
import * as logger from '@/utils/logger';
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { toast } from '@skand/ui';
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';

const httpLink = createHttpLink({
  uri: GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  const authorization = Cookies.get(ACCESS_TOKEN_NAME);
  return { headers: { ...headers, authorization } };
});

// HACK: Please see https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-676886539
const omitTypeName = (key: string, value: unknown) => (key === '__typename' ? undefined : value);
const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypeName);
  }

  return forward(operation).map(data => {
    return data;
  });
});

export const client = new ApolloClient({
  link: ApolloLink.from([cleanTypeName, authLink, httpLink]),
  cache: new InMemoryCache(),
});

const handleError = (error: unknown) => {
  logger.error(error);
  let message = error;
  if (error instanceof Error) message = error.message;
  toast({
    type: 'warn',
    message: `${message}`,
    lifespan: 10000,
    clickToDismiss: true,
  });
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },

  queryCache: new QueryCache({
    onError: handleError,
  }),

  mutationCache: new MutationCache({
    onError: handleError,
  }),
});
