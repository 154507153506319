import { useEntitlements } from '@/hooks/useEntitlements';
import { Progress } from '@skand/ui';
import { useMemo } from 'react';

export const UsageMeter = () => {
  const {
    feature,
    query: { isFetching },
  } = useEntitlements();
  const entitlement = feature('user');
  const { total, infinity, used } = entitlement ?? {};

  const content = useMemo(() => {
    if (!entitlement || isFetching)
      return (
        <div className="h-20px flex items-center">
          <Progress className="w-20" progress={0} />
        </div>
      );
    if (infinity)
      return (
        <>
          <Progress className="w-20" progress={0} />
          <p className="color-neutral-600 typo-text-s">
            <span className="typo-text-s-em">{used}</span> users allocated
          </p>
        </>
      );

    if (used !== undefined && used !== null && total !== undefined && total !== null) {
      return (
        <>
          <Progress className="w-20" progress={used / total} />
          <p className="color-neutral-600 typo-text-s">
            <span className="typo-text-s-em">{used}</span> of {total} users allocated
          </p>
        </>
      );
    }

    throw new Error('invalid feature entitlement'); // should not reach here
  }, [entitlement, infinity, isFetching, total, used]);

  return <div className="flex items-center gap-2">{content}</div>;
};
