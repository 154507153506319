import { AZURE_APPLICATION_ID } from '@/constants/env';
import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: AZURE_APPLICATION_ID ?? '',
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${window.location.origin}/login`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: [`api://${AZURE_APPLICATION_ID}/access_as_user`],
};
