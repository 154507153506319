import { RendererType } from '@/constants/renderer';
import { LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (projectId: string, rendererTypes: RendererType[]) => [
  'LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES',
  projectId,
  ...rendererTypes,
];

export const useListSceneEntitiesByProjectIdAndRendererTypesQuery = ({
  projectId,
  rendererTypes = [],
}: {
  projectId?: string;
  rendererTypes?: RendererType[];
}) => {
  const response = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_SCENE_ENTITIES_BY_PROJECT_ID_AND_RENDERER_TYPES, {
        projectId: projectId as string,
        rendererTypes,
      }),
    queryKey: getQueryKey(projectId as string, rendererTypes),
    keepPreviousData: true,
  });

  const sceneEntities = useMemo(() => {
    return response.data?.listSceneEntitiesByProjectIdAndRendererTypes ?? [];
  }, [response.data?.listSceneEntitiesByProjectIdAndRendererTypes]);

  return {
    response,
    sceneEntities,
  };
};

useListSceneEntitiesByProjectIdAndRendererTypesQuery.getQueryKey = getQueryKey;
