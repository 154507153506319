import { useChangeUserPassword } from '@/hooks/useChangeUserPassword';
import { useLogOut } from '@/hooks/useLogOut';
import { useReadUserByContext } from '@/hooks/useReadUserByContext';
import { useUpdateUser } from '@/hooks/useUpdateUser';
import { cn } from '@/utils/classname';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Spinner } from '@skand/ui';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const nameSchema = z.object({
  firstName: z.string().refine(value => value !== '', { message: 'Required' }),
  lastName: z.string().refine(value => value !== '', { message: 'Required' }),
});
export type NameInputs = z.infer<typeof nameSchema>;

const passwordSchema = z
  .object({
    currentPassword: z.string(),
    newPassword: z.string().min(8),
    passwordConfirm: z.string().min(8),
  })
  .refine(data => data.newPassword === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ['passwordConfirm'],
  });
export type PasswordInputs = z.infer<typeof passwordSchema>;

export const AccountPage = () => {
  const { user } = useReadUserByContext();
  const { logOut } = useLogOut();

  const defaultValues = {
    firstName: user?.name?.first ?? '',
    lastName: user?.name?.last ?? '',
  };

  const nameForm = useForm<NameInputs>({
    resolver: zodResolver(nameSchema),
    values: defaultValues,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const passwordForm = useForm<PasswordInputs>({
    resolver: zodResolver(passwordSchema),
  });

  const [passwordVisibilityState, setPasswordVisibilityState] = useState({
    currentPassword: false,
    newPassword: false,
    passwordConfirm: false,
  });

  const { mutation: updateUserDetails } = useUpdateUser();
  const { mutation: updateUserPassword } = useChangeUserPassword();

  const handleSubmitUserDetails: SubmitHandler<NameInputs> = (data, e) => {
    e?.preventDefault();
    updateUserDetails.mutate(data);
  };

  const handleSubmitUserPassword: SubmitHandler<PasswordInputs> = (data, e) => {
    e?.preventDefault();
    updateUserPassword.mutate(data);
  };

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1 b-b-solid b-b-neutral-500',
            'flex items-center justify-between',
            'p-b-3 p-t-30px',
          )}
        >
          <h1 className="color-neutral-800 typo-heading-3">Account Details</h1>
          <div className="ml-auto flex gap-2">
            <Button className="w-134px" filled onClick={() => logOut()} primary size="s">
              Log out
            </Button>
          </div>
        </div>
        <div className="mt-3 flex">
          <div className="flex flex-col gap-6">
            <form
              className="w-360px flex flex-col gap-6"
              onSubmit={nameForm.handleSubmit(handleSubmitUserDetails)}
            >
              <p className="color-neutral-800 typo-heading-4">Signed in as</p>
              <div className="flex flex-col gap-3">
                <Controller
                  control={nameForm.control}
                  name="firstName"
                  render={({ field }) => (
                    <Input
                      disabled={field.disabled}
                      hint={nameForm.formState.errors.firstName?.message}
                      label="First name"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      warn={!!nameForm.formState.errors.firstName}
                    />
                  )}
                />

                <Controller
                  control={nameForm.control}
                  name="lastName"
                  render={({ field }) => (
                    <Input
                      disabled={field.disabled}
                      hint={nameForm.formState.errors.lastName?.message}
                      label="Last name"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      value={field.value}
                      warn={!!nameForm.formState.errors.lastName}
                    />
                  )}
                />

                <Input disabled label="Email" value={user?.email || ''} />

                <Button
                  className="flex cursor-pointer items-center justify-center gap-1"
                  disabled={updateUserDetails.isLoading}
                  primary
                  size="s"
                  type="submit"
                >
                  {updateUserDetails.isLoading && <Spinner className="animate-spin" />}
                  Update details
                </Button>
              </div>
            </form>
            <form
              className="w-360px flex flex-col gap-6"
              onSubmit={passwordForm.handleSubmit(handleSubmitUserPassword)}
            >
              <p className="color-neutral-800 typo-heading-4">Manage password</p>
              <div className="flex flex-col gap-3">
                <Controller
                  control={passwordForm.control}
                  name="currentPassword"
                  render={({ field }) => (
                    <Input
                      disabled={field.disabled}
                      hint={passwordForm.formState.errors.currentPassword?.message}
                      label="Current password"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      tail={
                        <div
                          className={cn(
                            'cursor-pointer color-neutral-400',
                            passwordVisibilityState.currentPassword
                              ? 'i-skand-hide'
                              : 'i-skand-show',
                          )}
                          onClick={() => {
                            setPasswordVisibilityState({
                              ...passwordVisibilityState,
                              currentPassword: !passwordVisibilityState.currentPassword,
                            });
                          }}
                        />
                      }
                      type={passwordVisibilityState.currentPassword ? 'text' : 'password'}
                      value={field.value}
                      warn={!!passwordForm.formState.errors.currentPassword}
                    />
                  )}
                />

                <Controller
                  control={passwordForm.control}
                  name="newPassword"
                  render={({ field }) => (
                    <Input
                      disabled={field.disabled}
                      hint={passwordForm.formState.errors.newPassword?.message}
                      label="New password"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      tail={
                        <div
                          className={cn(
                            'cursor-pointer color-neutral-400',
                            passwordVisibilityState.newPassword ? 'i-skand-hide' : 'i-skand-show',
                          )}
                          onClick={() => {
                            setPasswordVisibilityState({
                              ...passwordVisibilityState,
                              newPassword: !passwordVisibilityState.newPassword,
                            });
                          }}
                        />
                      }
                      type={passwordVisibilityState.newPassword ? 'text' : 'password'}
                      value={field.value}
                      warn={!!passwordForm.formState.errors.newPassword}
                    />
                  )}
                />

                <Controller
                  control={passwordForm.control}
                  name="passwordConfirm"
                  render={({ field }) => (
                    <Input
                      disabled={field.disabled}
                      hint={passwordForm.formState.errors.passwordConfirm?.message}
                      label="Confirm new password"
                      name={field.name}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      tail={
                        <div
                          className={cn(
                            'cursor-pointer color-neutral-400',
                            passwordVisibilityState.passwordConfirm
                              ? 'i-skand-hide'
                              : 'i-skand-show',
                          )}
                          onClick={() => {
                            setPasswordVisibilityState({
                              ...passwordVisibilityState,
                              passwordConfirm: !passwordVisibilityState.passwordConfirm,
                            });
                          }}
                        />
                      }
                      type={passwordVisibilityState.passwordConfirm ? 'text' : 'password'}
                      value={field.value}
                      warn={!!passwordForm.formState.errors.passwordConfirm}
                    />
                  )}
                />

                <Button
                  className="flex cursor-pointer items-center justify-center gap-1"
                  disabled={updateUserPassword.isLoading}
                  primary
                  size="s"
                  type="submit"
                >
                  {updateUserPassword.isLoading && <Spinner className="animate-spin" />}
                  Update password
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
