import { LayerFormatType } from '@/constants/layer';
import {
  Annotation as AnnotationQuery,
  ImageField,
  Layer as LayerQuery,
  PanoramicImageField,
  SceneEntity,
} from '@/graphql/codegen/graphql';
import {
  Annotation,
  AnnotationGroup,
  Layer,
  LayerGroup,
  Panorama,
  Photo,
  Photo2D,
  PhotoGroup,
} from '@/hooks/useSceneEntityTreeData';
import { isEmpty } from '@/utils/misc';

export const createLayerGroup = (sceneEntity: SceneEntity, parent?: Layer | LayerGroup): LayerGroup | undefined => {
  if (!isEmpty(sceneEntity.id) && !isEmpty(sceneEntity.name)) {
    return {
      type: 'layerGroup',
      id: sceneEntity.id,
      name: sceneEntity.name,
      sceneEntityId: sceneEntity.id,
      parent,
    }
  }
}

export const createLayer = (sceneEntity: SceneEntity, layer: LayerQuery, parent?: Layer | LayerGroup): Layer | undefined => {
  if (
    !isEmpty(sceneEntity.renderObject) && 
    !isEmpty(layer) &&
    !isEmpty(layer.id) &&
    !isEmpty(layer.captureDate) &&
    !isEmpty(layer.name) &&
    !isEmpty(layer.mainSceneEntityId) &&
    !isEmpty(layer.supportingSceneEntityIds) &&
    !isEmpty(layer.worldPosition) &&
    !isEmpty(layer.worldRotation) &&
    !isEmpty(layer.formatType)
  ) {
    return {
      type: 'layer',
      id: layer.id,
      captureDate: new Date(layer.captureDate),
      name: layer.name,
      sceneEntityId: layer.mainSceneEntityId,
      supportingSceneEntityIds: layer.supportingSceneEntityIds as string[],
      formatType: layer.formatType as LayerFormatType,
      parent,
    };
  }
};

export const createPhotoGroup = (
  sceneEntity: SceneEntity,
  parent?: Layer | LayerGroup,
): PhotoGroup | undefined => {
  if (
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.renderObject) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation)
  ) {
    switch (sceneEntity.renderObject.__typename) {
      case 'ImageProjectionRenderObject':
        return {
          type: 'photoGroup',
          id: sceneEntity.id,
          name: `2D Images (${sceneEntity.id})`,
          sceneEntityId: sceneEntity.id,
          parent,
          photos: [],
        };
      case 'PanoramicRenderObject':
        return {
          type: 'photoGroup',
          id: sceneEntity.id,
          name: `Panoramas (${sceneEntity.id})`,
          sceneEntityId: sceneEntity.id,
          parent,
          photos: [],
        };
      default:
        return;
    }
  }
};

export const createPhoto2D = (image: ImageField, group: PhotoGroup): Photo2D | undefined => {
  if (
    !isEmpty(image) &&
    !isEmpty(image.fileId) &&
    !isEmpty(image.file) &&
    image.file.__typename === 'ImageFile' &&
    !isEmpty(image.cameraModelId) &&
    !isEmpty(image.file.fileName) &&
    !isEmpty(image.cameraRelativePosition) &&
    !isEmpty(image.cameraRelativeRotation) &&
    !isEmpty(image.extrinsicPosition) &&
    !isEmpty(image.extrinsicRotation) &&
    !isEmpty(image.extrinsicScale)
  ) {
    return {
      type: 'photo2D',
      id: image.fileId,
      name: image.file.fileName,
      group,
    };
  }
};

export const createPanorama = (
  panorama: PanoramicImageField,
  group: PhotoGroup,
): Panorama | undefined => {
  if (
    !isEmpty(panorama) &&
    !isEmpty(panorama.fileId) &&
    !isEmpty(panorama.file) &&
    panorama.file.__typename === 'ImageFile' &&
    !isEmpty(panorama.file.fileName) &&
    !isEmpty(panorama.file.signedGetObjectUrl) &&
    !isEmpty(panorama.file.thumbnailUrl) &&
    !isEmpty(panorama.relativePosition) &&
    !isEmpty(panorama.relativeRotation)
  ) {
    return {
      type: 'panorama',
      id: panorama.fileId,
      name: panorama.file.fileName,
      group,
    };
  }
};

export const createAnnotationGroup = (
  sceneEntity: SceneEntity,
  parent: Layer | LayerGroup | undefined,
): AnnotationGroup | undefined => {
  if (
    !isEmpty(sceneEntity.renderObject) &&
    sceneEntity.renderObject.__typename === 'AnnotationRenderObject' &&
    !isEmpty(sceneEntity.renderObject.id) &&
    !isEmpty(sceneEntity.renderObject.createdAt) &&
    !isEmpty(sceneEntity.id) &&
    !isEmpty(sceneEntity.name) &&
    !isEmpty(sceneEntity.position) &&
    !isEmpty(sceneEntity.rotation)
  ) {
    return {
      type: 'annotationGroup',
      id: sceneEntity.renderObject.id,
      name: sceneEntity.name,
      sceneEntityId: sceneEntity.id,
      parent,
      annotations: [],
    };
  }
};

export const createAnnotation = (
  annotation: AnnotationQuery,
  group: AnnotationGroup,
  photoGroups: PhotoGroup[],
): Annotation | undefined => {
  if (
    !isEmpty(annotation) &&
    !isEmpty(annotation.annotationId) &&
    !isEmpty(annotation.id) &&
    !isEmpty(annotation.name) &&
    !isEmpty(annotation.color)
  ) {
    let photo: Photo | undefined;
    for (const group of photoGroups) {
      photo = group.photos.find(photo => photo.id === annotation.annotation2d?.imageFileId);
    }
    const result: Annotation = {
      type: 'annotation',
      id: annotation.annotationId,
      versionId: annotation.id,
      name: annotation.name,
      hasPhoto: !!photo,
      group,
    };

    if (
      !isEmpty(annotation.annotation2d) &&
      !isEmpty(annotation.annotation2d.shapeType) &&
      !isEmpty(annotation.annotation2d.points)
    ) {
      result.hasSketch2D = true;
    }

    if (
      !isEmpty(annotation.annotation3d) &&
      !isEmpty(annotation.annotation3d.shapeType) &&
      !isEmpty(annotation.annotation3d.positions) &&
      !isEmpty(annotation.annotation3d.rotations)
    ) {
      result.hasSketch3D = true;
    }
    return result;
  }
};
