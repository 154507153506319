import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { isEmpty } from '@/utils/misc';
import { useMemo } from 'react';
import { useGroupingPolicies } from './useGroupingPolicies';
import { useGroups } from './useGroups';

/** get userGroups belong to a user */
export const useUserGroupsByUserId = (userId: null | string) => {
  const { groups } = useGroups();

  const { policies } = useGroupingPolicies(
    {
      sourceId: userId as string,
      sourceType: PolicySubjectTypeInput.User,
      targetId: null,
      targetType: PolicySubjectTypeInput.Group,
    },
    { enabled: !!userId },
  );

  const userGroupIds = useMemo(() => {
    const ids = [];

    for (const policy of policies) {
      if (!isEmpty(policy.targetId)) ids.push(policy.targetId);
    }

    return ids;
  }, [policies]);

  const userGroups = useMemo(
    () => groups.filter(group => userGroupIds.includes(group.id)),
    [groups, userGroupIds],
  );

  return { userGroups, userGroupIds };
};
