import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { CreateSubjectPermissionsPage } from '@/components/SubjectPermissionsPage/CreateSubjectPermissionsPage';

export const CreateRolesPermissionsPage = () => {
  return (
    <CreateSubjectPermissionsPage
      subjectType={PolicySubjectTypeInput.Role}
    />
  );
};
