import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { isEmpty } from '@/utils/misc';
import { useMemo } from 'react';
import { useGroupingPolicies } from './useGroupingPolicies';
import { useRolesQuery } from './useRolesQuery';

export const useUserRoles = (userId: null | string) => {
  const { roles } = useRolesQuery();
  const { policies } = useGroupingPolicies(
    {
      sourceId: userId as string,
      sourceType: PolicySubjectTypeInput.User,
      targetId: null,
      targetType: PolicySubjectTypeInput.Role,
    },
    { enabled: !!userId },
  );

  const userRoleIds = useMemo(() => {
    const ids = [];

    for (const policy of policies) {
      if (!isEmpty(policy.targetId)) ids.push(policy.targetId);
    }

    return ids;
  }, [policies]);

  const userRoles = useMemo(
    () => roles.filter(role => userRoleIds.includes(role.id)),
    [roles, userRoleIds],
  );

  return { userRoles, userRoleIds };
};
