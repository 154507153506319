import { TableDense } from '@/components/TableDense';
import { dialog, island } from '@/constants/classes';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { PolicySubjectTypeInput, Role, User, UserGroup } from '@/graphql/codegen/graphql';
import { setGroupUserIds } from '@/stores/group';
import { cn } from '@/utils/classname';
import { displayName } from '@/utils/user';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, CheckBox, Input } from '@skand/ui';
import {
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { AccessGate, FeatureNotIncluded } from '../AccessGate';
import { FindIcon } from '../IconButton';
import { getEntitlementName } from './utils';

interface AddTargetToSourceProps {
  sourceTargetIds: string[];
  sourceType: PolicySubjectTypeInput;
  targetType: PolicySubjectTypeInput;
  targetList: SolidId<User | UserGroup | Role>[];
  onAddSources: (selectedSourceIds: string[]) => void;
  isCreatedBySystem: boolean;
}

const columnHelper = createColumnHelper<SolidId<User | UserGroup | Role>>();

export const AddTargetToSource = ({
  sourceType,
  sourceTargetIds,
  targetType,
  targetList,
  onAddSources,
  isCreatedBySystem,
}: AddTargetToSourceProps) => {
  const [open, setOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const unAssignedSourceList = useMemo(() => {
    return targetList.filter(source => !sourceTargetIds.includes(source.id));
  }, [sourceTargetIds, targetList]);

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        source => {
          if (targetType === PolicySubjectTypeInput.User) return displayName(source as User);
          if (targetType === PolicySubjectTypeInput.Group) return (source as UserGroup).name;
          if (targetType === PolicySubjectTypeInput.Role) return (source as Role).name;
          return '';
        },
        {
          enableGlobalFilter: true,
          id: 'name',
          header: ({ table }) => (
            <label className="flex items-center gap-3">
              <CheckBox
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
              {[PolicySubjectTypeInput.User, PolicySubjectTypeInput.Role].includes(targetType) &&
                'name'}
              {targetType === PolicySubjectTypeInput.Group && 'group name'}
            </label>
          ),
          cell: ({ row, getValue }) => (
            <label className="flex items-center gap-3">
              <CheckBox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
              />
              {getValue()}
            </label>
          ),
        },
      ),

      columnHelper.accessor(
        source => {
          if (targetType === PolicySubjectTypeInput.User) return (source as User).email;
          if ([PolicySubjectTypeInput.Group, PolicySubjectTypeInput.Role].includes(targetType))
            return (source as UserGroup).description;
          return '';
        },
        {
          enableGlobalFilter: true,
          id: 'email or description',
          header: () => (
            <span>
              {targetType === PolicySubjectTypeInput.User && 'email'}
              {[PolicySubjectTypeInput.Group, PolicySubjectTypeInput.Role].includes(targetType) &&
                'description'}
            </span>
          ),
          cell: ({ getValue }) => getValue(),
        },
      ),
    ],
    [targetType],
  );

  const table = useReactTable({
    columns,
    data: unAssignedSourceList,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: row => row.id,
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    state: { globalFilter, rowSelection, sorting },
  });

  const handleAddSources = () => {
    onAddSources(Object.keys(rowSelection));
    setGroupUserIds(Object.keys(rowSelection));
    setOpen(false);
  };

  useEffect(() => {
    if (!open) setRowSelection({});
  }, [open]);

  const entitlementName = useMemo(
    () => getEntitlementName(sourceType, targetType),
    [sourceType, targetType],
  );

  const readMoreUrl = useMemo(() => {
    if (sourceType === PolicySubjectTypeInput.User) {
      if (targetType === PolicySubjectTypeInput.Group) return READ_MORE_LINKS.USER_GROUP;
      if (targetType === PolicySubjectTypeInput.Role) return READ_MORE_LINKS.ROLE;
    }

    if (sourceType === PolicySubjectTypeInput.Group) {
      if (targetType === PolicySubjectTypeInput.User) return READ_MORE_LINKS.GROUP;
      if (targetType === PolicySubjectTypeInput.Role) return READ_MORE_LINKS.ROLE;
    }

    if (sourceType === PolicySubjectTypeInput.Role) {
      if (targetType === PolicySubjectTypeInput.User) return READ_MORE_LINKS.GROUP;
      if (targetType === PolicySubjectTypeInput.Group) return READ_MORE_LINKS.ROLE;
    }

    return READ_MORE_LINKS.PRICING;
  }, [sourceType, targetType]);

  const buttonText = useMemo(() => {
    if (targetType === PolicySubjectTypeInput.User) return 'Assign users';
    if (targetType === PolicySubjectTypeInput.Group) return 'Add to group';
    if (targetType === PolicySubjectTypeInput.Role) return 'Assign to role';
    return '';
  }, [targetType]);

  const trigger = useMemo(() => {
    if (entitlementName === null) return null;

    const enabled = (
      <Dialog.Trigger asChild>
        <Button
          active={open}
          className="min-w-[134px] hover:cursor-pointer"
          filled
          primary
          size="s"
        >
          {buttonText}
        </Button>
      </Dialog.Trigger>
    );

    if (
      sourceType === PolicySubjectTypeInput.Role &&
      targetType === PolicySubjectTypeInput.User &&
      isCreatedBySystem
    ) {
      return enabled;
    }

    const disabled = (
      <Button className="min-w-[134px]" disabled filled primary size="s">
        {buttonText}
      </Button>
    );

    return (
      <AccessGate
        disabled={() => <FeatureNotIncluded button={disabled} readMoreUrl={readMoreUrl} />}
        enabled={() => enabled}
        entitlementCheck={{ featureName: entitlementName }}
        loading={() => disabled}
      />
    );
  }, [buttonText, entitlementName, open, readMoreUrl, sourceType, targetType]);

  return (
    <Dialog.Root onOpenChange={setOpen} open={open}>
      {trigger}
      <Dialog.Portal>
        <Dialog.Content className={cn(dialog, 'mt-auto mb-auto h-90vh')}>
          <Dialog.Title className="color-neutral-800 typo-text-l">
            {targetType === PolicySubjectTypeInput.User && 'Assign users'}
            {targetType === PolicySubjectTypeInput.Group && 'Add to group'}
            {targetType === PolicySubjectTypeInput.Role && 'Assign to roles'}
          </Dialog.Title>

          <div className={cn(island, 'mt-6 flex-1 overflow-auto')}>
            <Input
              onChange={setGlobalFilter}
              placeholder={`Search for ${targetType.toString().toLocaleLowerCase()}`}
              tail={<FindIcon />}
              value={globalFilter}
            />

            <TableDense className="mt-3" table={table} />
          </div>

          <div className="mt-6 flex gap-3">
            <Dialog.Close asChild>
              <Button
                className="flex-1 hover:cursor-pointer"
                onClick={() => setOpen(false)}
                size="s"
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              className="flex-1 hover:cursor-pointer"
              filled
              onClick={() => handleAddSources()}
              primary
              size="s"
            >
              {targetType === PolicySubjectTypeInput.User && 'Add users'}
              {targetType === PolicySubjectTypeInput.Group && 'Add groups'}
              {targetType === PolicySubjectTypeInput.Role && 'Assign roles'}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
