import { SubjectPermissions } from '@/components/SubjectPermissionsPage';
import { ROLE_CREATED_BY } from '@/constants/role';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useRoleQuery } from '@/hooks/useRoleQuery';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const RolesPermissionsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { role } = useRoleQuery(id);

  const isSystemDefault = useMemo(
    () => role?.createdBy === ROLE_CREATED_BY.SYSTEM,
    [role?.createdBy],
  );

  return (
    <SubjectPermissions
      disablePermissionActions={isSystemDefault}
      id={id}
      subject={role}
      subjectType={PolicySubjectTypeInput.Role}
    />
  );
};
