import { READ_USER_GROUP_BY_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId, isEmpty } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (id: string) => ['READ_USER_GROUP_BY_ID', id];

export const useGroup = (id: null | string) => {
  const response = useQuery({
    enabled: !!id,
    queryFn: () => request(READ_USER_GROUP_BY_ID, { userGroupId: id as string }),
    queryKey: getQueryKey(id as string),
    refetchOnMount: true,
  });

  const group = useMemo(() => {
    const group = response.data?.readUserGroupById;

    if (!hasId(group)) return null;
    return group;
  }, [response.data?.readUserGroupById]);

  const groupUserIds = useMemo(() => {
    const ids = [];

    for (const policy of group?.userToUserGroupPolicies ?? []) {
      if (policy?.sourceType === 'USER' && !isEmpty(policy.sourceId)) ids.push(policy.sourceId);
    }

    return ids;
  }, [group?.userToUserGroupPolicies]);

  return { response, group, groupUserIds };
};

useGroup.getQueryKey = getQueryKey;
