import { queryClient } from '@/graphql/client';
import {
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables,
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables,
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_USER_GROUP, DELETE_USER_GROUP, UPDATE_USER_GROUP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { toast } from '@skand/ui';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useGroup } from './useGroup';

export interface UseGroupMutation {
  createGroup: UseMutationResult<
    CreateUserGroupMutation,
    unknown,
    CreateUserGroupMutationVariables,
    unknown
  >;
  updateGroup: UseMutationResult<
    UpdateUserGroupMutation,
    unknown,
    UpdateUserGroupMutationVariables,
    unknown
  >;
  deleteGroup: UseMutationResult<
    DeleteUserGroupMutation,
    unknown,
    DeleteUserGroupMutationVariables,
    unknown
  >;
}

export interface UseGroupMutationProps {
  onCreateGroupSuccess?: (createGroupId?: string) => void;
  onUpdateGroupSuccess?: (updateGroupId?: string) => void;
  onDeleteGroupSuccess?: () => void;
}

export const useGroupsMutation = (props?: UseGroupMutationProps): UseGroupMutation => {
  const createGroup = useMutation<
    CreateUserGroupMutation,
    unknown,
    CreateUserGroupMutationVariables,
    unknown
  >({
    mutationFn: ({ name, description }) =>
      request(CREATE_USER_GROUP, {
        name: name,
        description: description || '',
      }),
    onSuccess: ({ createUserGroup }) => {
      props?.onCreateGroupSuccess?.(createUserGroup as string);
    },
  });

  const updateGroup = useMutation<
    UpdateUserGroupMutation,
    unknown,
    UpdateUserGroupMutationVariables,
    unknown
  >({
    mutationFn: updateGroupInput =>
      request(UPDATE_USER_GROUP, {
        group: {
          userGroupId: updateGroupInput?.group?.userGroupId as string,
          name: updateGroupInput?.group?.name as string,
          description: updateGroupInput?.group?.description || '',
        },
      }),
    onSuccess: ({ updateUserGroup }) => {
      queryClient.invalidateQueries(useGroup.getQueryKey(updateUserGroup as string));
      toast({
        type: 'success',
        message: 'Group has been updated successfully',
        lifespan: 10000,
      });
      props?.onUpdateGroupSuccess?.(updateUserGroup as string);
    },
    onError: () => {
      toast({
        type: 'warn',
        message: 'Group update failed',
        lifespan: 10000,
      });
    },
  });

  const deleteGroup = useMutation<
    DeleteUserGroupMutation,
    unknown,
    DeleteUserGroupMutationVariables,
    unknown
  >({
    mutationFn: ({ userGroupId }) =>
      request(DELETE_USER_GROUP, {
        userGroupId,
      }),
    onSuccess: () => {
      toast({
        type: 'success',
        message: 'Group has been deleted successfully',
        lifespan: 10000,
      });

      props?.onDeleteGroupSuccess?.();
    },
    onError: () => {
      toast({
        type: 'warn',
        message: 'Group deletion failed',
        lifespan: 10000,
      });
    },
  });

  return {
    createGroup,
    updateGroup,
    deleteGroup,
  };
};
