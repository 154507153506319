import { PermissionPolicy } from '@/graphql/codegen/graphql';
import { create } from 'zustand';

export interface RoleState {
  roleName: string;
  roleDescription: string;
  rolePermissionPolicies: PermissionPolicy[];
}

export const useRoleStore = create<RoleState>(() => {
  return {
    roleName: '',
    roleDescription: '',
    rolePermissionPolicies: [],
  };
});

export const clearRoleState = () => {
  useRoleStore.setState({
    roleName: '',
    roleDescription: '',
    rolePermissionPolicies: [],
  });
};

export const setRoleName = (roleName: string) => {
  useRoleStore.setState({
    roleName,
  });
};

export const setRoleDescription = (roleDescription: string) => {
  useRoleStore.setState({
    roleDescription,
  });
};

export const setRolePermissionPolicies = (rolePermissionPolicies: PermissionPolicy[]) => {
  useRoleStore.setState({
    rolePermissionPolicies,
  });
};

export const upsertRolePermissionPolicy = (permissionPolicy: PermissionPolicy) => {
  useRoleStore.setState(state => {
    const rolePermissionPolicies = [...state.rolePermissionPolicies];

    const updateIndex = rolePermissionPolicies.findIndex(
      policy =>
        policy.objectId === permissionPolicy.objectId &&
        policy.objectType === permissionPolicy.objectType &&
        policy.subjectType === permissionPolicy.subjectType &&
        policy.actionType === permissionPolicy.actionType,
    );

    if (updateIndex != -1) {
      rolePermissionPolicies[updateIndex] = permissionPolicy;
    } else {
      rolePermissionPolicies.push(permissionPolicy);
    }

    return {
      rolePermissionPolicies,
    };
  });
};

export const removeRolePermissionPolicy = (permissionPolicy: PermissionPolicy) => {
  useRoleStore.setState(state => {
    const rolePermissionPolicies = [...state.rolePermissionPolicies];

    const removeIndex = rolePermissionPolicies.findIndex(
      policy =>
        policy.objectId === permissionPolicy.objectId &&
        policy.objectType === permissionPolicy.objectType &&
        policy.subjectType === permissionPolicy.subjectType &&
        policy.actionType === permissionPolicy.actionType,
    );

    if (removeIndex != -1) {
      rolePermissionPolicies.splice(removeIndex, 1);
    }

    return {
      rolePermissionPolicies,
    };
  });
};
