import { Role } from '@/graphql/codegen/graphql';
import { LIST_ROLES_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['LIST_ROLES_BY_ACCOUNT_CONTEXT'];

export const useRolesQuery = () => {
  const response = useQuery({
    queryFn: () => request(LIST_ROLES_BY_ACCOUNT_CONTEXT),
    queryKey,
    refetchOnMount: true,
  });

  const roles: SolidId<Role>[] = useMemo(() => {
    return response.data?.listRolesByAccountContext?.filter(hasId) ?? [];
  }, [response.data?.listRolesByAccountContext]);

  return { response, roles };
};

useRolesQuery.queryKey = queryKey;
