import { ACCESS_TOKEN_NAME, USER_EMAIL_NAME } from '@/constants/env';
import * as PATHS from '@/constants/paths';
import { LOG_IN } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';

export type LogInCallback = (args: {
  accountId?: null | string;
  bearerToken?: null | string;
  userEmail?: null | string;
}) => void;

export interface LogInProps {
  onLogIn?: LogInCallback;
}

export const useLogIn = ({ onLogIn }: LogInProps = {}) => {
  const history = useHistory();

  const logIn = useMutation({
    mutationFn: ({ email, password }: { email: string; password: string }) =>
      request(LOG_IN, { email, password }),
    onSuccess: data => {
      const bearerToken = data?.login?.bearerToken;
      const userEmail = data?.login?.email;
      const accountId = data?.login?.accountId;

      if (bearerToken) {
        Cookies.set(ACCESS_TOKEN_NAME, bearerToken);
        if (userEmail) Cookies.set(USER_EMAIL_NAME, userEmail);

        if (onLogIn) onLogIn({ accountId, bearerToken, userEmail });
        else history.push(PATHS.MANAGE_ROOT);
      }
    },
  });

  return logIn;
};
