import {
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
} from '@/graphql/codegen/graphql';
import { LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isDefiend } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export interface PermissionPolicyQueryTuple {
  actionType: null | PolicyActionTypeInput;
  subjectId: null | string;
  subjectType: null | PolicySubjectTypeInput;
  objectId: null | string;
  objectType: null | PolicyObjectTypeInput;
}

export interface UsePermissionPoliciesOptions {
  enabled?: boolean;
}

const getQueryKey = ({
  actionType,
  objectId,
  objectType,
  subjectId,
  subjectType,
}: PermissionPolicyQueryTuple) => [
  'LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT',
  actionType,
  objectId,
  objectType,
  subjectId,
  subjectType,
];

export const usePermissionPolicies = (
  query: PermissionPolicyQueryTuple,
  options: UsePermissionPoliciesOptions = {},
) => {
  const { enabled = true } = options;

  const response = useQuery({
    enabled,
    queryFn: () => request(LIST_PERMISSION_POLICIES_WITH_ACCOUNT_CONTEXT, query),
    queryKey: getQueryKey(query),
  });

  const policies = useMemo(() => {
    return response.data?.listPermissionPoliciesWithAccountContext?.filter(isDefiend) ?? [];
  }, [response.data?.listPermissionPoliciesWithAccountContext]);

  return { response, policies };
};

usePermissionPolicies.getQueryKey = getQueryKey;
