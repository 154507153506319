import { AccessGate, FeatureNotIncluded, QuotaExceeded } from '@/components/AccessGate';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { useInviteUser } from '@/hooks/useInviteUser';
import { useUserRolesByContext } from '@/hooks/useRolesByContext';
import { useUserGroups } from '@/hooks/useUserGroups';
import { cn } from '@/utils/classname';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, Dropdown, DropdownItem, Input } from '@skand/ui';
import { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';

interface InviteUserProps {
  isInviteUserModalOpen: boolean;
  setIsInviteUserModalOpen: (isOpen: boolean) => void;
}

export const InviteUser = ({
  isInviteUserModalOpen,
  setIsInviteUserModalOpen,
}: InviteUserProps) => {
  // const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const { roles } = useUserRolesByContext();
  const { groups } = useUserGroups();

  const [newUserEmail, setNewUserEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [selectedRoleName, setSelectedRoleName] = useState<string | null>(null);
  const [selectedGroupName, setSelectedGroupName] = useState<string | null>(null);

  const [selectedRoleId, setSelectedRoleId] = useState<string | null>(null);
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);

  const roleDropdownRef = useRef<HTMLLabelElement>(null);
  const groupDropdownRef = useRef<HTMLLabelElement>(null);

  const { response: inviteUser } = useInviteUser(
    newUserEmail,
    firstName,
    lastName,
    selectedRoleId ?? '',
    selectedGroupId ?? '',
  );

  useEffect(() => {
    if (isInviteUserModalOpen) {
      setNewUserEmail('');
      setFirstName('');
      setLastName('');
      setSelectedRoleId(null);
      setSelectedGroupId(null);
      setSelectedRoleName(null);
      setSelectedGroupName(null);
    }
  }, [isInviteUserModalOpen]);

  const handleSubmitDetails: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      inviteUser.mutate();
      setIsInviteUserModalOpen(false);
    },
    [inviteUser, setIsInviteUserModalOpen],
  );

  const handleRoleClick = (roleId: string | null, roleName: string) => {
    setSelectedRoleId(roleId);
    setSelectedRoleName(roleName);
    closeDropDown(roleDropdownRef);
  };

  const handleGroupClick = (groupId: string | null, groupName: string) => {
    setSelectedGroupId(groupId);
    setSelectedGroupName(groupName);
    closeDropDown(groupDropdownRef);
  };

  const closeDropDown = (ref: React.RefObject<HTMLLabelElement>) => {
    ref.current?.focus();
    ref.current?.blur();
  };

  const renderDisabledButton = () => (
    <Button className="min-w-[134px]" disabled filled primary size="s">
      Invite User
    </Button>
  );

  return (
    <Dialog.Root onOpenChange={setIsInviteUserModalOpen} open={isInviteUserModalOpen}>
      <AccessGate
        disabled={() => (
          <FeatureNotIncluded
            button={renderDisabledButton()}
            readMoreUrl={READ_MORE_LINKS.PRICING}
          />
        )}
        enabled={() => (
          <Dialog.Trigger asChild>
            <Button
              active={isInviteUserModalOpen}
              className="min-w-[134px] cursor-pointer"
              filled
              primary
              size="s"
            >
              Invite User
            </Button>
          </Dialog.Trigger>
        )}
        entitlementCheck={{ featureName: ENTITLEMENT_NAME.USER }}
        full={() => <QuotaExceeded button={renderDisabledButton()} />}
        loading={renderDisabledButton}
      />

      <Dialog.Portal>
        <Dialog.Content
          className={cn(
            'b-1 b-neutral-400 b-solid',
            'bg-neutral-100',
            'fixed',
            'inset-x-0',
            'top-1/2',
            'mx-auto',
            'translate-x-30',
            '-translate-y-1/2',
            'p-6',
            'rounded-2',
            'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
            'z-10',
            'w-408px',
            'max-w-lg',
            'min-h-fit',
            'max-h-[90vh]',
          )}
        >
          <div className="flex justify-between">
            <Dialog.Title className="mb-6 color-neutral-800 typo-text-l">Invite user</Dialog.Title>

            <Dialog.Close asChild>
              <div
                className="i-skand-close cursor-pointer color-neutral-400"
                onClick={() => setIsInviteUserModalOpen(false)}
              />
            </Dialog.Close>
          </div>
          <div>
            <form className="flex flex-col gap-3" onSubmit={handleSubmitDetails}>
              <p className="color-neutral-600 typo-text-medium">Enter details</p>
              <Input label="First name" onChange={e => setFirstName(e)} value={firstName} />
              <Input label="Last name" onChange={e => setLastName(e)} value={lastName} />
              <Input
                label="Email"
                onChange={e => setNewUserEmail(e)}
                type="email"
                value={newUserEmail}
              />
              <p className="color-neutral-600 typo-text-medium">Select Role</p>
              <Dropdown
                disabled={!roles?.length}
                label={selectedRoleName ?? 'Select Role'}
                ref={roleDropdownRef}
                width="full"
              >
                <DropdownItem onClick={() => handleRoleClick(null, 'Select Role')}>
                  Select Role
                </DropdownItem>
                <div className="max-h-100px overflow-y-auto">
                  {roles &&
                    roles?.length >= 1 &&
                    roles?.map((role, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() =>
                          handleRoleClick(role?.id ?? null, role?.name ?? 'Select Role')
                        }
                      >
                        {role?.name}
                      </DropdownItem>
                    ))}
                </div>
              </Dropdown>
              <p className="color-neutral-600 typo-text-medium">Select Group</p>
              <Dropdown
                disabled={!groups?.length}
                label={selectedGroupName ?? 'Select Group'}
                ref={groupDropdownRef}
                width="full"
              >
                <DropdownItem onClick={() => handleGroupClick(null, 'Select Group')}>
                  Select Group
                </DropdownItem>
                <div className="max-h-100px overflow-y-auto">
                  {groups &&
                    groups.length >= 1 &&
                    groups?.map((group, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() =>
                          handleGroupClick(group?.id ?? null, group?.name ?? 'Select Group')
                        }
                      >
                        {group.name}
                      </DropdownItem>
                    ))}
                </div>
              </Dropdown>
              <div className="mt-6">
                <Button
                  className="w-full cursor-pointer"
                  disabled={!newUserEmail || !firstName || !lastName}
                  filled
                  primary
                  size="s"
                  type="submit"
                >
                  Send Invite
                </Button>
              </div>
            </form>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
