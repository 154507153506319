import { SceneEntityTree } from '@/components/SceneEntityTree';
import { TableDense } from '@/components/TableDense';
import { NODE_KIND, SystemNode } from '@/constants/node';
import { OBJECT_TYPE_MAP } from '@/constants/permissions';
import { ObjectType } from '@/constants/policy';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import {
  NODES_TABLE_DEFAULT_PAGE_INDEX,
  NODES_TABLE_DEFAULT_PAGE_SIZE,
} from '@/constants/resources';
import { queryClient } from '@/graphql/client';
import {
  PermissionPolicy,
  PolicyActionTypeInput,
  PolicyObjectTypeInput,
  PolicySubjectTypeInput,
  Project,
  ProjectGroup,
  SystemNodeKindInput,
} from '@/graphql/codegen/graphql';
import { useAccount } from '@/hooks/useAccount';
import { useListSystemNodesByParentNodeIdQuery } from '@/hooks/useListSystemNodesByParentNodeIdQuery';
import { usePermissionPolicies } from '@/hooks/usePermissionPolicies';
import { usePermissionPolicyMutation } from '@/hooks/usePermissionPolicyMutation';
import { useProjectGroups } from '@/hooks/useProjectGroups';
import { useProjects } from '@/hooks/useProjects';
import { cn } from '@/utils/classname';
import * as Dialog from '@radix-ui/react-dialog';
import { Button, CheckBox, Input } from '@skand/ui';
import {
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccessGate, FeatureNotIncluded } from '../AccessGate';
import { FindIcon } from '../IconButton';
import { Breadcrumbs } from './Breadcrumb';
import { Pagination } from './Pagination';
import { ProjectsSelect } from './ProjectsSelect';
import { ResourceTypeSelect } from './ResourceTypeSelect';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';

interface AddResourcesProps {
  subjectType: PolicySubjectTypeInput;
}

interface Resource {
  id: string;
  project?: SolidId<Project>;
  projectGroup?: SolidId<ProjectGroup>;
  systemNode?: SolidId<SystemNode>;
  type: PolicyObjectTypeInput;
}

const columnHelper = createColumnHelper<Resource>();

export const AddResources = ({ subjectType }: AddResourcesProps) => {
  const { id } = useParams<{ id: string }>();

  const [resourceType, setResourceType] = useState<ObjectType | null>(null);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const accountId = useAccount().account?.id;
  const { projects } = useProjects();
  const { projectGroups } = useProjectGroups();
  const { policies } = usePermissionPolicies(
    {
      actionType: null,
      subjectId: id,
      subjectType,
      objectId: null,
      objectType: null,
    },
    { enabled: !!id },
  );

  const [parentNodeId, setParentNodeId] = useState<string | undefined>();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
    pageSize: NODES_TABLE_DEFAULT_PAGE_SIZE,
  });
  const { systemNodes, totalNumberOfPages: systemNodeTotalNumberOfPages } =
    useListSystemNodesByParentNodeIdQuery(
      {
        parentNodeId: parentNodeId ?? null,
        pageSize,
        pageIndex,
        nodeKinds: [SystemNodeKindInput.FolderNode],
        searchTerm: globalFilter,
      },
      { keepPreviousData: true },
    );

  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>();
  const [selectedSceneEntityIds, setSelectedSceneEntityIds] = useState<string[]>([]);

  const { upsertPermission } = usePermissionPolicyMutation({
    onUpsertPermissionPoliciesSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType,
          objectId: null,
          objectType: null,
        }),
      );
      handleOpenChange(false);
    },
  });

  const systemNodesPagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const resources = useMemo(() => {
    const projectRes = projects.map(item => ({
      id: item.id,
      type: PolicyObjectTypeInput.Project,
      project: item,
    }));

    const projectGroupRes = projectGroups.map(item => ({
      id: item.id,
      type: PolicyObjectTypeInput.ProjectGroup,
      projectGroup: item,
    }));

    const systemNodesRes = systemNodes.map(item => ({
      id: item?.id as string,
      type: PolicyObjectTypeInput.SystemNode,
      systemNode: item,
    }));

    return [...projectRes, ...projectGroupRes, ...systemNodesRes];
  }, [projectGroups, projects, systemNodes]);

  const excludedResources = useMemo(() => {
    if (!resourceType) return [];

    const policiesGranted = policies.map(policy => ({
      type: policy.objectType,
      id: policy.objectId,
    }));

    const excluded = resources.filter(
      res =>
        !policiesGranted.some(policy => policy.type === res.type && policy.id === res.id) &&
        res.type === resourceType,
    );

    return excluded;
  }, [policies, resourceType, resources]);

  const [open, setOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    if (!open) return;

    const sceneEntityIds = policies.reduce((acc, currPolicy) => {
      if (currPolicy.objectType !== PolicyObjectTypeInput.SceneEntity) return acc;
      return [...acc, currPolicy.objectId as string];
    }, [] as string[]);

    setSelectedSceneEntityIds(sceneEntityIds);
  }, [open, policies]);

  const handleNavigateToFolderNode = useCallback((folderNodeId?: string) => {
    setParentNodeId(folderNodeId);
    setPagination(prev => ({ ...prev, pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX }));
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        row => {
          if (row.type === PolicyObjectTypeInput.Project) return row.project?.name ?? '';
          if (row.type === PolicyObjectTypeInput.ProjectGroup) return row.projectGroup?.name ?? '';
          if (row.type === PolicyObjectTypeInput.SystemNode) return row.systemNode?.name ?? '';
          return '';
        },
        {
          enableGlobalFilter: true,
          id: 'name',
          header: ({ table }) => (
            <label className="flex items-center gap-3">
              <CheckBox
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
              name
            </label>
          ),
          cell: ({ row, getValue }) => {
            const isSystemFolderNode =
              row.original.type === PolicyObjectTypeInput.SystemNode &&
              row.original?.systemNode?.kind === NODE_KIND.FOLDER_NODE;

            return (
              <div className="flex items-center gap-3">
                <CheckBox
                  checked={row.getIsSelected()}
                  className="hover:cursor-pointer"
                  disabled={!row.getCanSelect()}
                  onChange={row.getToggleSelectedHandler()}
                />
                <label
                  className={cn('flex items-center gap-3', {
                    'underline hover:cursor-pointer': isSystemFolderNode,
                  })}
                  onClick={() => {
                    if (isSystemFolderNode) {
                      handleNavigateToFolderNode(row.original.systemNode?.id);
                    }
                  }}
                >
                  {getValue()}
                </label>
              </div>
            );
          },
        },
      ),
      columnHelper.accessor('type', {
        enableGlobalFilter: false,
        header: 'type',
        cell: ({ getValue }) => {
          return OBJECT_TYPE_MAP[getValue()];
        },
      }),
    ],
    [handleNavigateToFolderNode],
  );

  const table = useReactTable({
    columns,
    data: excludedResources,
    enableRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: row => row.id,
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    pageCount: systemNodeTotalNumberOfPages,
    onPaginationChange: setPagination,
    manualPagination: true,
    state: { globalFilter, rowSelection, sorting, pagination: systemNodesPagination },
  });

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open);

    if (!open) {
      setParentNodeId(undefined);
      setPagination({
        pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
        pageSize: NODES_TABLE_DEFAULT_PAGE_SIZE,
      });
      setResourceType(null);
      setSelectedSceneEntityIds([]);
    }
  }, []);

  const handleResourceTypeChange = useCallback((value: string) => {
    setResourceType(value as ObjectType);
    setSelectedProjectId(undefined);
    setRowSelection({});
  }, []);

  const handleAddResources = useCallback(() => {
    let policies: PermissionPolicy[] = [];

    if (resourceType !== PolicyObjectTypeInput.SceneEntity) {
      policies = Object.keys(rowSelection).flatMap(resourceId => {
        const resource = resources.find(resource => resource.id === resourceId);
        if (!resource) return [];

        const actionType =
          resourceType === PolicyObjectTypeInput.SystemNode
            ? PolicyActionTypeInput.Read
            : PolicyActionTypeInput.List;

        return [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType,
            objectId: resource.id,
            objectType: resource.type,
          },
        ];
      });
    } else {
      policies = selectedSceneEntityIds.map(sceneEntityId => ({
        accountId: accountId as string,
        actionType: PolicyActionTypeInput.Read,
        subjectId: id,
        subjectType,
        objectId: sceneEntityId,
        objectType: PolicyObjectTypeInput.SceneEntity,
      }));
    }

    if (policies.length > 0) {
      upsertPermission.mutate(policies);
    }
  }, [
    accountId,
    id,
    resourceType,
    resources,
    rowSelection,
    selectedSceneEntityIds,
    upsertPermission,
    subjectType,
  ]);

  const handleSearchWithDebounce = useRef(
    debounce(async keyword => {
      setGlobalFilter(keyword);
      if (keyword.length === 0 || pageIndex !== NODES_TABLE_DEFAULT_PAGE_INDEX) {
        setPagination({
          pageIndex: NODES_TABLE_DEFAULT_PAGE_INDEX,
          pageSize: NODES_TABLE_DEFAULT_PAGE_SIZE,
        });
      }
    }, 300),
  ).current;

  const generateSearchPlaceholderText = (resourceType: ObjectType | null): string => {
    if (resourceType === null) {
      return 'Search for resources';
    }

    const typePlaceholders: { [key in ObjectType]?: string } = {
      SYSTEM_NODE: 'folders',
      PROJECT: 'projects',
      PROJECT_NODE: 'project files',
      PROJECT_GROUP: 'project groups',
    };

    return `Search for ${typePlaceholders[resourceType]}`;
  };

  return (
    <Dialog.Root onOpenChange={handleOpenChange} open={open}>
      <AccessGate
        disabled={() => (
          <FeatureNotIncluded
            button={
              <Button className="min-w-[134px]" disabled filled primary size="s">
                Add resources
              </Button>
            }
            readMoreUrl={READ_MORE_LINKS.USER_PERM}
          />
        )}
        enabled={() => (
          <Dialog.Trigger asChild>
            <Button
              active={open}
              className="min-w-[134px] hover:cursor-pointer"
              filled
              primary
              size="s"
            >
              Add resources
            </Button>
          </Dialog.Trigger>
        )}
        entitlementCheck={
          { featureName: ENTITLEMENT_NAME.PERMISSION }
        }
        loading={() => (
          <Button className="min-w-[134px]" disabled filled primary size="s">
            Add resources
          </Button>
        )}
      />

      <Dialog.Portal>
        <Dialog.Content
          className={cn(
            'b-1 b-neutral-600 b-solid',
            'bg-neutral-100',
            'fixed',
            'flex-col',
            'flex',
            'inset-y-12',
            'p-6',
            'rounded-2',
            'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
            'left-50% transform-translate-x--50%',
            'w-640px',
            'mt-auto mb-auto h-90vh',
            'z-1',
            'overflow-hidden',
          )}
        >
          <div className="flex items-center justify-between">
            <Dialog.Title className="color-neutral-800 typo-text-l">Add resources</Dialog.Title>
            <ResourceTypeSelect onValueChange={handleResourceTypeChange} />
          </div>

          <Dialog.Description className="mt-3 color-neutral-800 typo-text-m">
            Give access to individual resources
          </Dialog.Description>

          <div className="mt-2">
            {resourceType === PolicyObjectTypeInput.SystemNode && (
              <Breadcrumbs onNavigate={handleNavigateToFolderNode} parentNodeId={parentNodeId} />
            )}
            {resourceType === PolicyObjectTypeInput.SceneEntity && (
              <ProjectsSelect onValueChange={id => setSelectedProjectId(id)} options={projects} />
            )}
          </div>

          <div className={cn('b-1 b-neutral-400 rounded-1 b-solid p-x-3 p-t-3', 'mt-3 flex-1')}>
            <div>
              {resourceType !== null && resourceType !== PolicyObjectTypeInput.SceneEntity && (
                <>
                  <Input
                    onChange={e => handleSearchWithDebounce(e)}
                    placeholder={generateSearchPlaceholderText(resourceType)}
                    tail={<FindIcon />}
                    value={globalFilter}
                  />
                  <TableDense
                    className="mt-3"
                    contentRowClassName="grid grid-cols-2"
                    headerRowClassName="grid grid-cols-2 p-r-2"
                    table={table}
                    tableBodyClassName={cn(
                      'grid overflow-y-auto auto-rows-max m-y-1',
                      'scrollbar scrollbar-rounded',
                      {
                        'h-[calc(90vh-355px)]': resourceType === PolicyObjectTypeInput.SystemNode,
                        'h-[calc(90vh-300px)]':
                          resourceType === PolicyObjectTypeInput.Project ||
                          resourceType === PolicyObjectTypeInput.ProjectGroup,
                      },
                    )}
                  />
                </>
              )}
              {resourceType === null && (
                <div className="mt-1/2 flex justify-center">
                  <p className="color-neutral-500 typo-text-s">
                    Select resource type to show contents
                  </p>
                </div>
              )}

              {resourceType === PolicyObjectTypeInput.SceneEntity && (
                <div className={cn('mt-2')}>
                  <SceneEntityTree
                    projectId={selectedProjectId}
                    selectedSceneEntityIds={selectedSceneEntityIds}
                    setSelectedSceneEntityIds={setSelectedSceneEntityIds}
                    treeClassName={'h-[calc(90vh-305px)]'}
                  />
                </div>
              )}
            </div>

            {resourceType === PolicyObjectTypeInput.SystemNode && (
              <div className="flex justify-center p-t-3">
                <Pagination
                  currentPage={table.getState().pagination.pageIndex + 1}
                  isNextPageDisabled={!table.getCanNextPage()}
                  isPreviewPageDisabled={!table.getCanPreviousPage()}
                  onPageChange={newPage => table.setPageIndex(newPage - 1)}
                  totalPages={table.getPageCount()}
                />
              </div>
            )}
          </div>

          <div className="mt-6 flex gap-3">
            <Dialog.Close asChild>
              <Button
                className="flex-1 hover:cursor-pointer"
                onClick={() => handleOpenChange(false)}
                size="s"
              >
                Cancel
              </Button>
            </Dialog.Close>
            <Button
              className="flex-1 hover:cursor-pointer"
              disabled={
                Object.keys(rowSelection).length === 0 && selectedSceneEntityIds.length === 0
              }
              filled
              onClick={() => handleAddResources()}
              primary
              size="s"
            >
              Add
            </Button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
