import { useMemo } from 'react';
import * as PATHS from '@/constants/paths';
import { PolicySubjectTypeInput, Role, User, UserGroup } from '@/graphql/codegen/graphql';
import { displayName } from '@/utils/user';
import { Back } from '@/components/Back';
import { clearGroupState } from '@/stores/group';

type Source = SolidId<User | UserGroup | Role> | null | string;

const useSourceTypeMappings = (source: Source, sourceType: PolicySubjectTypeInput) => {
  const sourceTypeMap = useMemo(
    () => ({
      [PolicySubjectTypeInput.User]: source ? displayName(source as User) : '',
      [PolicySubjectTypeInput.Group]: source ? (source as UserGroup).name : '',
      [PolicySubjectTypeInput.Role]: source ? (source as Role).name : '',
      [PolicySubjectTypeInput.ShareLink]: '',
    }),
    [source],
  );

  const sourceTypeBackLinkMap = {
    [PolicySubjectTypeInput.User]: <Back to={PATHS.MANAGE_USERS}>Back to all users</Back>,
    [PolicySubjectTypeInput.Group]: (
      <Back onClick={clearGroupState} to={PATHS.MANAGE_GROUPS}>
        Back to all groups
      </Back>
    ),
    [PolicySubjectTypeInput.Role]: <Back to={PATHS.MANAGE_ROLES}>Back to all roles</Back>,
    [PolicySubjectTypeInput.ShareLink]: '',
  };

  const targetTypeMap = {
    [PolicySubjectTypeInput.User]: `Manage users for this ${
      sourceType.toLowerCase() === 'group' ? 'user group' : sourceType.toLowerCase()
    }.`,
    [PolicySubjectTypeInput.Group]: `Manage group memberships for this ${sourceType.toLowerCase()}.`,
    [PolicySubjectTypeInput.Role]: `Manage role assignments for this ${sourceType.toLowerCase()}.`,
    [PolicySubjectTypeInput.ShareLink]: '',
  };

  return { sourceTypeMap, sourceTypeBackLinkMap, targetTypeMap };
};

export default useSourceTypeMappings;
