import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import { GROUP_DETAILS_PAGE_MODE } from '@/constants/role';
import { PATHS } from '@/index';
import { clearGroupState } from '@/stores/group';
import { cn } from '@/utils/classname';
import { getCreateGroupsPagesSidePanelLinks } from '@/utils/links';
import { Button, Input } from '@skand/ui';
import { FormEventHandler } from 'react';
import { Link, useHistory } from 'react-router-dom';

interface GroupContentProps {
  id?: string;
  groupName: string;
  setGroupName: (value: string) => void;
  groupDescription: string;
  setGroupDescription: (value: string) => void;
  onSubmitEditRoleDetails: FormEventHandler<HTMLFormElement>;
  mode: keyof typeof GROUP_DETAILS_PAGE_MODE;
}

export const Content = ({
  groupName,
  setGroupName,
  groupDescription,
  setGroupDescription,
  onSubmitEditRoleDetails,
}: GroupContentProps) => {
  const history = useHistory();

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />

      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'p-b-3',
            'p-t-30px',
            'justify-between',
          )}
        >
          <h1 className="ml-4 color-neutral-800 typo-heading-3">Create a new group</h1>

          <div className="ml-auto flex gap-2">
            <>
              <Link to={PATHS.MANAGE_GROUPS}>
                <Button
                  className="min-w-[134px] hover:cursor-pointer"
                  onClick={clearGroupState}
                  size="s"
                >
                  Cancel
                </Button>
              </Link>

              <Button
                className={cn('min-w-[134px]', { 'hover:cursor-pointer': !!groupName.trim() })}
                disabled={!groupName.trim()}
                filled
                onClick={() => history.push(PATHS.MANAGE_GROUPS_CREATE_PERMISSIONS)}
                primary
                size="s"
              >
                Next
              </Button>
            </>
          </div>
        </div>
        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">Enter group details.</p>
        </div>
        <div className="flex">
          <div className="ml-4 flex flex-col gap-6">
            <form className="w-360px flex flex-col gap-6" onSubmit={onSubmitEditRoleDetails}>
              <p className="color-neutral-800 typo-heading-4">Enter group details</p>

              <div className="flex flex-col gap-3">
                <Input
                  hint="Required"
                  label="Group name"
                  onChange={setGroupName}
                  value={groupName}
                />
                <Input
                  hint="Optional"
                  label="Description"
                  onChange={setGroupDescription}
                  value={groupDescription}
                />
              </div>
            </form>
          </div>

          <SideTabPanel links={getCreateGroupsPagesSidePanelLinks()} />
        </div>
      </div>
    </div>
  );
};
