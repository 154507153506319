import { SourceTargetPage } from '@/components/SourceTargetPage';
import { queryClient } from '@/graphql/client';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useGroups } from '@/hooks/useGroups';
import { useRoleQuery } from '@/hooks/useRoleQuery';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const RolesGroupsPage = () => {
  const { id } = useParams<{ id: string }>();
  const { role, roleGroupIds } = useRoleQuery(id);
  const { groups } = useGroups();

  const roleGroups = useMemo(() => {
    return groups.filter(group => roleGroupIds.includes(group.id));
  }, [groups, roleGroupIds]);

  const handleCreateOrDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(useRoleQuery.getQueryKey(id));
    queryClient.invalidateQueries(useGroups.queryKey);
  }, [id]);

  return (
    <SourceTargetPage
      id={id}
      onCreateOrDeleteSuccess={handleCreateOrDeleteSuccess}
      source={role}
      sourceTargetIds={roleGroupIds}
      sourceTargetList={roleGroups}
      sourceType={PolicySubjectTypeInput.Role}
      targetList={groups}
      targetType={PolicySubjectTypeInput.Group}
    />
  );
};
