import { User } from '@/graphql/codegen/graphql';
import { USERS_BY_ACCOUNT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['USERS_BY_ACCOUNT_ID'];

export const useUsers = () => {
  const response = useQuery({
    queryFn: () => request(USERS_BY_ACCOUNT_ID),
    queryKey,
  });

  const users: Solid<User, 'id'>[] = useMemo(() => {
    return response.data?.usersByAccountId?.filter(hasId) ?? [];
  }, [response.data?.usersByAccountId]);

  return { response, users };
};

useUsers.queryKey = queryKey;
