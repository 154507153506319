import { ACCOUNT_V2_BY_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['ACCOUNT_V2_BY_CONTEXT'];

export const useAccountV2 = () => {
  const response = useQuery({
    queryFn: () => request(ACCOUNT_V2_BY_CONTEXT),
    queryKey,
  });

  const account = useMemo(() => {
    return response?.data?.readAccountByContext;
  }, [response?.data?.readAccountByContext]);

  return { response, account };
};

useAccountV2.queryKey = queryKey;
