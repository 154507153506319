import { cn } from '@/utils/classname';
import { useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react';
import { Slot } from '@radix-ui/react-slot';
import { MenuProps } from '@skand/ui';
import { useState } from 'react';

export interface MoreMenuProps extends MenuProps {
  children: React.ReactNode;
  className?: string;
}

export const MoreMenu = ({ children, className, ...props }: MoreMenuProps) => {
  const [open, setOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    open: open,
    onOpenChange: setOpen,
    placement: 'bottom-end',
  });
  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss]);

  return (
    <>
      <div
        className={cn(
          'i-skand-more hover:color-primary-400',
          open ? 'color-primary-400' : 'color-neutral-400',
          className,
        )}
        ref={refs.setReference}
        {...getReferenceProps()}
      />
      {open && (
        <Slot ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()} {...props}>
          {children}
        </Slot>
      )}
    </>
  );
};
