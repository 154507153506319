import { ImageIcon } from '@/components/IconButton';
import { cn } from '@/utils/classname';
import { Photo } from '../../hooks/useSceneEntityTreeData';

export interface PhotoNodeProps {
  photo: Photo;
}
export const PhotoNode = ({ photo }: PhotoNodeProps) => {
  return (
    <div className={cn('flex justify-between items-center py-1')}>
      <div className={cn('flex items-center gap-2 text-neutral-600')}>
        <p className={cn('typo-text-small text-neutral-800')}>{photo.name}</p>
        <ImageIcon />
      </div>
    </div>
  );
};
