import { PermissionPolicy } from '@/graphql/codegen/graphql';
import { create } from 'zustand';

interface GroupUsersToBeAdded {
  accountId: string;
  sourceId: string;
  sourceType: string;
  targetId: string;
  targetType: string;
}

export interface GroupState {
  groupName: string;
  groupDescription: string;
  groupUsersToBeAdded: GroupUsersToBeAdded[];
  groupUserIds: string[];
  groupPermissionPolicies: PermissionPolicy[];
}

export const useGroupStore = create<GroupState>(() => {
  return {
    groupName: '',
    groupDescription: '',
    groupUsersToBeAdded: [],
    groupUserIds: [],
    groupPermissionPolicies: [],
  };
});

export const setGroupUserIds = (groupUserIds: string[]) => {
  useGroupStore.setState({
    groupUserIds,
  });
};

export const clearGroupState = () => {
  useGroupStore.setState({
    groupName: '',
    groupDescription: '',
    groupUsersToBeAdded: [],
    groupPermissionPolicies: [],
  });
};

export const setGroupName = (groupName: string) => {
  useGroupStore.setState({
    groupName,
  });
};

export const setGroupDescription = (groupDescription: string) => {
  useGroupStore.setState({
    groupDescription,
  });
};

export const setGroupUsers = (groupUsersToBeAdded: GroupUsersToBeAdded[]) => {
  useGroupStore.setState({
    groupUsersToBeAdded,
  });
};

export const removeFromGroupUsers = (userId: string) => {
  useGroupStore.setState(state => {
    const groupUsersToBeAdded = [...state.groupUsersToBeAdded];

    const removeIndex = groupUsersToBeAdded.findIndex(user => user.sourceId === userId);

    if (removeIndex != -1) {
      groupUsersToBeAdded.splice(removeIndex, 1);
    }

    return {
      groupUsersToBeAdded,
    };
  });
};

export const clearGroupPermissionPolicies = () => {
  useGroupStore.setState({
    groupPermissionPolicies: [],
  });
};

export const setGroupPermissionPolicies = (groupPermissionPolicies: PermissionPolicy[]) => {
  useGroupStore.setState({
    groupPermissionPolicies,
  });
};
export const upsertGroupPermissionPolicy = (permissionPolicy: PermissionPolicy) => {
  useGroupStore.setState(state => {
    const groupPermissionPolicies = [...state.groupPermissionPolicies];

    const updateIndex = groupPermissionPolicies.findIndex(
      policy =>
        policy.objectId === permissionPolicy.objectId &&
        policy.objectType === permissionPolicy.objectType &&
        policy.subjectType === permissionPolicy.subjectType &&
        policy.actionType === permissionPolicy.actionType,
    );

    if (updateIndex != -1) {
      groupPermissionPolicies[updateIndex] = permissionPolicy;
    } else {
      groupPermissionPolicies.push(permissionPolicy);
    }

    return {
      groupPermissionPolicies,
    };
  });
};

export const removeGroupPermissionPolicy = (permissionPolicy: PermissionPolicy) => {
  useGroupStore.setState(state => {
    const groupPermissionPolicies = [...state.groupPermissionPolicies];

    const removeIndex = groupPermissionPolicies.findIndex(
      policy =>
        policy.objectId === permissionPolicy.objectId &&
        policy.objectType === permissionPolicy.objectType &&
        policy.subjectType === permissionPolicy.subjectType &&
        policy.actionType === permissionPolicy.actionType,
    );

    if (removeIndex != -1) {
      groupPermissionPolicies.splice(removeIndex, 1);
    }

    return {
      groupPermissionPolicies,
    };
  });
};
