import { PolicySubjectTypeInput, UserGroup } from '@/graphql/codegen/graphql';
import { usePermissionPolicies } from '@/hooks/usePermissionPolicies';
import { Row } from '@tanstack/react-table';

export interface GroupCellProps {
  row: Row<SolidId<UserGroup>>;
}

export const ResourceCell = ({ row }: GroupCellProps) => {
  const { policies } = usePermissionPolicies({
    actionType: null,
    subjectId: row.original.id,
    subjectType: PolicySubjectTypeInput.Group,
    objectId: null,
    objectType: null,
  });

  return <span className="typo-text-s">{policies.length}</span>;
};
