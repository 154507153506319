import { EmptyDesc, EmptyRoot, EmptyTitle, EmptyToast } from '@skand/ui';

interface EmptyResourceMessageProps {
  action?: React.ReactNode;
  desc?: string | React.ReactNode;
  title?: string | React.ReactNode;
  toastMessage?: string;
}

export const EmptyResourceMessage = ({
  action,
  desc,
  title,
  toastMessage,
}: EmptyResourceMessageProps) => {
  return (
    <EmptyRoot className="h-full">
      <div className="flex flex-col items-center gap-3">
        {toastMessage && <EmptyToast message={toastMessage} />}
        <EmptyTitle>{title}</EmptyTitle>
        <EmptyDesc>{desc}</EmptyDesc>
        {action}
      </div>
    </EmptyRoot>
  );
};
