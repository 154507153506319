import { OBJECT_ID } from '@/constants/policy';
import { PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { useFindSceneEntitiesByIds } from '@/hooks/useFindSceneEntitiesByIds';
import { useFindSystemNodesByIds } from '@/hooks/useFindSystemNodesByIds';
import { useProjectGroups } from '@/hooks/useProjectGroups';
import { useProjects } from '@/hooks/useProjects';
import { parseAllRawValue } from '@/utils/misc';
import { Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import { PREDEFINED_RESOURCE_MAP } from '../../constants/permissions';
import { Resource } from './columns';

export const NameCell = ({ row }: { row: Row<Resource> }) => {
  const resource = row.original;

  const { projects } = useProjects();
  const { projectGroups } = useProjectGroups();
  const { systemNodes } = useFindSystemNodesByIds(resource.systemNodeIds ?? []);
  const { sceneEntities } = useFindSceneEntitiesByIds(resource.sceneEntityIds ?? []);

  const project = useMemo(() => {
    if (resource.objectType !== PolicyObjectTypeInput.Project) return null;
    return projects.find(item => item.id === resource.objectId) ?? null;
  }, [projects, resource.objectId, resource.objectType]);

  const projectGroup = useMemo(() => {
    if (resource.objectType !== PolicyObjectTypeInput.ProjectGroup) return null;
    return projectGroups.find(item => item.id === resource.objectId) ?? null;
  }, [projectGroups, resource.objectId, resource.objectType]);

  const systemNode = useMemo(() => {
    if (resource.objectType !== PolicyObjectTypeInput.SystemNode) return null;
    return systemNodes.find(item => item.id === resource.objectId) ?? null;
  }, [resource.objectId, resource.objectType, systemNodes]);

  const sceneEntity = useMemo(() => {
    if (resource.objectType !== PolicyObjectTypeInput.SceneEntity) return null;
    return sceneEntities.find(item => item.id === resource.objectId) ?? null;
  }, [resource.objectId, resource.objectType, sceneEntities]);

  const name = useMemo(() => {
    if (row.original.objectId === OBJECT_ID.ALL)
      return (
        PREDEFINED_RESOURCE_MAP[
          parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput
        ] ?? ''
      );
    if (resource.objectType === PolicyObjectTypeInput.Project) return project?.name;
    if (resource.objectType === PolicyObjectTypeInput.ProjectGroup) return projectGroup?.name;
    if (resource.objectType === PolicyObjectTypeInput.SystemNode) return systemNode?.name;
    if (resource.objectType === PolicyObjectTypeInput.SceneEntity) return sceneEntity?.name;
  }, [
    project?.name,
    projectGroup?.name,
    resource.objectType,
    row.original.objectId,
    row.original.objectType,
    systemNode?.name,
    sceneEntity?.name,
  ]);

  return <span className="color-neutral-800 typo-text-s">{name}</span>;
};
