import { queryClient } from '@/graphql/client';
import { PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { CREATE_PERMISSION_POLICY, DELETE_PERMISSION_POLICY } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useAccount } from '@/hooks/useAccount';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';
import { usePermissionPolicies } from '@/hooks/usePermissionPolicies';
import { setAllAdminChecked, usePermissionStore } from '@/stores/permission';
import { cn } from '@/utils/classname';
import { parseAllRawValue } from '@/utils/misc';
import { CheckBox } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { ChangeEvent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ACTION_TYPE_MAP, OBJECT_TYPE_TO_ACTION_TYPES_MAP } from '../../constants/permissions';
import { Resource } from './columns';
import { AccessGate } from '../AccessGate';

export const PermissionCell = ({ row }: { row: Row<Resource> }) => {
  const { actionTypes, subjectType } = row.original;
  const isAllAdminChecked = usePermissionStore(state => state.isAllAdminChecked);

  const { id } = useParams<{ id: string }>();
  const accountId = useAccount().account?.id;

  const currObjectTypeActionTypes =
    OBJECT_TYPE_TO_ACTION_TYPES_MAP[
    parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput
    ];

  const addResource = useMutation({
    mutationFn: (actionType: PolicyActionTypeInput) =>
      request(CREATE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  const removeResource = useMutation({
    mutationFn: (actionType: PolicyActionTypeInput) =>
      request(DELETE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType,
            subjectId: id,
            subjectType: subjectType,
            objectId: row.original.objectId,
            objectType: parseAllRawValue(row.original.objectType) as PolicyObjectTypeInput,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  const removeAdminAllResource = useMutation({
    mutationFn: () =>
      request(DELETE_PERMISSION_POLICY, {
        policies: [
          {
            accountId: accountId as string,
            actionType: PolicyActionTypeInput.Admin,
            subjectId: id,
            subjectType: subjectType,
            objectId: '*',
            objectType: PolicyObjectTypeInput.All,
          },
        ],
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(
        usePermissionPolicies.getQueryKey({
          actionType: null,
          subjectId: id,
          subjectType: subjectType,
          objectId: null,
          objectType: null,
        }),
      );
    },
  });

  useEffect(() => {
    if (isAllAdminChecked) {
      addResource.mutate(PolicyActionTypeInput.Admin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllAdminChecked]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>, actionType: PolicyActionTypeInput) => {
    const { checked } = e.target;

    if (!checked) {
      setAllAdminChecked(false);
      removeAdminAllResource.mutate();
    }

    const isLowest = actionType === currObjectTypeActionTypes[currObjectTypeActionTypes.length - 1];

    if (isLowest && !checked) {
      for (const type of currObjectTypeActionTypes) {
        removeResource.mutate(type);
      }
      return;
    }

    if (checked) {
      addResource.mutate(actionType);
      return;
    } else {
      removeResource.mutate(actionType);
    }

    const nextActionType =
      currObjectTypeActionTypes[currObjectTypeActionTypes.indexOf(actionType) + 1];

    const canDowngrade = actionType !== nextActionType;

    if (!canDowngrade) {
      removeResource.mutate(actionType);
      return;
    }

    addResource.mutate(nextActionType);
  };

  const types = useMemo(() => {
    const types: {
      name: string;
      checked: boolean;
      disabled: boolean;
      actionType: PolicyActionTypeInput;
    }[] = [];

    let checked = false;

    for (const actionType of currObjectTypeActionTypes) {
      const included = actionTypes.includes(actionType);
      if (included) checked = true;

      types.push({
        checked,
        disabled: false,
        actionType,
        name:
          ACTION_TYPE_MAP[actionType as PolicyActionTypeInput].name == 'List'
            ? 'Restricted'
            : ACTION_TYPE_MAP[actionType as PolicyActionTypeInput].name,
      });
    }

    return types;
  }, [actionTypes, currObjectTypeActionTypes]);

  return (
    types.map(({ actionType, name, checked }) => {
      return (
        <AccessGate
          disabled={() =>
            <label
              className={cn(
                'mt-3 flex items-center gap-3 color-neutral-800 typo-text-s first:mt-0 opacity-50',
              )}
            >
              <CheckBox
                checked={checked}
                disabled={true}
                onChange={e => handleChange(e, actionType)}
              />
              {name}
            </label>
          }
          enabled={() =>
            <label
              className={cn(
                'mt-3 flex items-center gap-3 color-neutral-800 typo-text-s first:mt-0 hover:cursor-pointer',
              )}
            >
              <CheckBox
                checked={checked}
                className={cn({ 'hover:cursor-pointer': true })}
                onChange={e => handleChange(e, actionType)}
              />
              {name}
            </label>
          }
          entitlementCheck={{ featureName: ENTITLEMENT_NAME.PERMISSION }}
          key={actionType}
          loading={() => null}
        />
      );
    })
  );
};
