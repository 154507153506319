import { ROLE_DETAILS_PAGE_MODE } from '@/constants/role';
import { PATHS } from '@/index';
import { setRoleDescription, setRoleName, useRoleStore } from '@/stores/role';
import { FormEventHandler, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from './Content';

export const CreateMode = () => {
  const history = useHistory();

  const roleName = useRoleStore(state => state.roleName);
  const roleDescription = useRoleStore(state => state.roleDescription);

  const handleSubmitEditRoleDetails: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      history.push(PATHS.MANAGE_ROLES_CREATE_PERMISSIONS);
    },
    [history],
  );

  return (
    <Content
      mode={ROLE_DETAILS_PAGE_MODE.CREATE}
      onSubmitEditRoleDetails={handleSubmitEditRoleDetails}
      roleDescription={roleDescription}
      roleName={roleName}
      setRoleDescription={setRoleDescription}
      setRoleName={setRoleName}
    />
  );
};
