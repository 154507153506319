import { UserV2 } from '@/graphql/codegen/graphql';
import { useUserGroupsByUserId } from '@/hooks/useUserGroupsByUserId';
import { cn } from '@/utils/classname';
import { flip, safePolygon, useFloating, useHover, useInteractions } from '@floating-ui/react';
import { Row } from '@tanstack/react-table';
import { useState } from 'react';

export interface GroupCellProps {
  row: Row<SolidId<UserV2>>;
}

export const GroupCell = ({ row }: GroupCellProps) => {
  const { userGroups } = useUserGroupsByUserId(row.original.id);

  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    middleware: [flip()],
    open: isOpen,
    onOpenChange: setIsOpen,
  });
  const hover = useHover(context, {
    enabled: userGroups.length !== 0,
    handleClose: safePolygon(),
  });
  const { getFloatingProps, getReferenceProps } = useInteractions([hover]);

  return (
    <>
      <span className="underline typo-link-s" ref={refs.setReference} {...getReferenceProps()}>
        {userGroups.length}
      </span>
      {userGroups.length !== 0 && isOpen && (
        <ul
          className={cn(
            'list-none bg-neutral-100 px-2 py-1 rounded-1',
            'b-1 b-solid b-neutral-400',
            'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
          )}
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {userGroups.map(group => (
            <li className="color-neutral-800 typo-text-xs" key={group.id}>
              {group.name}
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
