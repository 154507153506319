import { PolicyActionTypeInput, PolicyObjectTypeInput } from '../graphql/codegen/graphql';

export interface ObjectData {
  accountId: string;
  actionType: keyof typeof PolicyActionTypeInput;
  objectId: string;
  objectType: keyof typeof PolicyObjectTypeInput;
  subjectId: string;
  subjectType: string;
}

export const filterHighestActionTypePerObjectType = (arr: any[]) => {
  const actionOrder = Object.keys(PolicyActionTypeInput);
  const objectTypeMap: Record<string, ObjectData> = {};

  arr.forEach(item => {
    const existingItem = objectTypeMap[item.objectType];
    if (
      !existingItem ||
      actionOrder.indexOf(item.actionType) > actionOrder.indexOf(existingItem.actionType)
    ) {
      objectTypeMap[item.objectType] = item;
    }
  });

  return Object.values(objectTypeMap);
};
