import { RESEND_INVITATION } from '@/graphql/mutations';
import { useMutation } from '@tanstack/react-query';
import { toast } from '@skand/ui';
import { request } from '@/graphql/request';
import { queryClient } from '@/graphql/client';
import { useReadUserById } from './useReadUserById';

export const useResendInvitationToUser = (userId: string) => {
  const response = useMutation({
    mutationFn: () =>
      request(RESEND_INVITATION, {
        userId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useReadUserById.getQueryKey(userId));
      toast({
        type: 'success',
        message: "User's invite has been sent successfully",
        lifespan: 10000,
      });
    },
    onError: () => {
      toast({
        type: 'warn',
        message: "User invite couldn't be sent",
        lifespan: 10000,
      });
    },
  });

  return { response };
};
