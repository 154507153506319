import { Back } from '@/components/Back';
import { DeleteButton } from '@/components/DeleteButton';
import { SideBar } from '@/components/SideBar';
import { SideTabPanel } from '@/components/SideTabPanel';
import * as PATHS from '@/constants/paths';
import { queryClient } from '@/graphql/client';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { DELETE_USER_GROUP, UPDATE_USER_GROUP } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useGroup } from '@/hooks/useGroup';
import { useGroups } from '@/hooks/useGroups';
import { cn } from '@/utils/classname';
import { getGroupsPagesSidePanelLinks } from '@/utils/links';
import { Button, Input } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const EditMode = () => {
  const { id } = useParams<{ id: string }>();
  const { group } = useGroup(id);

  const history = useHistory();

  const [name, setName] = useState<string>(group?.name ?? '');
  const [desc, setDesc] = useState<string>(group?.description ?? '');

  useEffect(() => {
    const { name, description } = group ?? {};
    setName(name ?? '');
    setDesc(description ?? '');
  }, [group]);

  const updateUserGroup = useMutation({
    mutationFn: () =>
      request(UPDATE_USER_GROUP, {
        group: {
          name,
          description: desc,
          userGroupId: id,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(useGroup.getQueryKey(id));
      queryClient.invalidateQueries(useGroups.queryKey);
    },
  });

  const deleteUserGroup = useMutation({
    mutationFn: () => request(DELETE_USER_GROUP, { userGroupId: id }),
    onSuccess: () => {
      history.replace(PATHS.MANAGE_GROUPS);
      queryClient.invalidateQueries(useGroups.queryKey);
    },
  });

  const handleSubmitDetails: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();
      updateUserGroup.mutate();
    },
    [updateUserGroup],
  );
  const groupNameDisplay = group?.name ?? '';

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('h-full', 'w-full', 'px-6', 'overflow-auto')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'p-b-3',
            'p-t-30px',
            'justify-between',
          )}
        >
          <h1
            className="ml-4 max-w-800px truncate color-neutral-800 typo-heading-3"
            title={groupNameDisplay}
          >
            {groupNameDisplay}
          </h1>
          <Back to={PATHS.MANAGE_GROUPS}>Back to all user groups</Back>
        </div>

        <div className="ml-4 mt-3 h-[64px] flex items-start justify-between">
          <p className="color-neutral-800 typo-text-s">Manage user group details.</p>
        </div>

        <div className="flex">
          <div className="ml-4 flex flex-col gap-6">
            <form className="w-360px flex flex-col gap-6" onSubmit={handleSubmitDetails}>
              <p className="color-neutral-800 typo-heading-4">Edit user group details</p>
              <div className="flex flex-col gap-3">
                <Input label="Group name" onChange={setName} value={name} />
                <Input label="Description" onChange={setDesc} value={desc} />
              </div>
              <Button primary size="s" type="submit">
                Update
              </Button>
            </form>

            <div className="w-360px flex flex-col gap-6">
              <p className="color-neutral-800 typo-heading-4">User group controls</p>
              <DeleteButton
                onDelete={() => deleteUserGroup.mutate()}
                subjectType={PolicySubjectTypeInput.Group}
              />
            </div>
          </div>

          <SideTabPanel links={getGroupsPagesSidePanelLinks(id)} />
        </div>
      </div>
    </div>
  );
};
