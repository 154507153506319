import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { useGroup } from '@/hooks/useGroup';
import { useGroupRoles } from '@/hooks/useGroupRoles';
import { SourceTargetPage } from '@/components/SourceTargetPage';
import { useRolesQuery } from '@/hooks/useRolesQuery';
import { useParams } from 'react-router-dom';
import { queryClient } from '@/graphql/client';
import { useCallback } from 'react';
import { useGroupingPolicies } from '@/hooks/useGroupingPolicies';

export const GroupsRolesPage = () => {
  const { id } = useParams<{ id: string }>();
  const { group } = useGroup(id);
  const { groupRoles, groupRoleIds } = useGroupRoles(id);
  const { roles } = useRolesQuery();

  const handleCreateOrDeleteSuccess = useCallback(() => {
    queryClient.invalidateQueries(
      useGroupingPolicies.getQueryKey({
        sourceId: id,
        sourceType: PolicySubjectTypeInput.Group,
        targetId: null,
        targetType: PolicySubjectTypeInput.Role,
      }),
    );
  }, [id]);

  return (
    <SourceTargetPage
      id={id}
      onCreateOrDeleteSuccess={handleCreateOrDeleteSuccess}
      source={group}
      sourceTargetIds={groupRoleIds}
      sourceTargetList={groupRoles}
      sourceType={PolicySubjectTypeInput.Group}
      targetList={roles}
      targetType={PolicySubjectTypeInput.Role}
    />
  );
};
