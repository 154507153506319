import brand from '@/assets/brand.svg';
import { LOG_IN } from '@/constants/paths';
import { ACCEPT_INVITATION } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { LogInCallback, useLogIn } from '@/hooks/useLogIn';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Spinner } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { z } from 'zod';

const schema = z.object({
  password: z.string().min(8),
});

export type SetPasswordInputs = z.infer<typeof schema>;

export interface ResetPasswordPageProps {
  onLogIn?: LogInCallback;
}

export const SetPasswordPage = ({ onLogIn }: ResetPasswordPageProps) => {
  const history = useHistory();
  const { key } = useParams<{ key: string }>();

  const { control, handleSubmit, formState, getValues } = useForm<SetPasswordInputs>({
    resolver: zodResolver(schema),
  });

  const [isKeyInvalid, setIsKeyInvalid] = useState(false);

  const logIn = useLogIn({ onLogIn });

  const acceptInvitation = useMutation({
    mutationFn: () =>
      request(ACCEPT_INVITATION, { password: getValues('password'), invitationToken: key }),
    onSuccess: data => {
      if (data.acceptInvitation?.email)
        logIn.mutate({ email: data.acceptInvitation.email, password: getValues('password') });
      else setIsKeyInvalid(true);
    },
    onError: () => setIsKeyInvalid(true),
  });

  const onSubmit: SubmitHandler<SetPasswordInputs> = (_, e) => {
    e?.preventDefault();
    acceptInvitation.mutate();
  };

  return (
    <div className="h-100dvh w-100dvw overflow-auto bg-neutral-100 py-10 lg:py-20">
      <div className="m-auto max-w-98 flex flex-col px-4">
        <div className="self-center">
          <img className="h-auto w-50 sm:w-auto" src={brand} />
        </div>

        <p className="mt-14 color-neutral-800 typo-heading-4 lg:mt-28">Set your password</p>

        {isKeyInvalid ? (
          <>
            <p className="mt-6 color-neutral-700 typo-text-m">
              Cannot set your password with this link. Please try again later or contact us at{' '}
              <a
                className="color-primary-400 no-underline typo-link-m"
                href="mailto:support@skand.io"
              >
                support@skand.io
              </a>
              .
            </p>
            <Button
              className="mt-3 cursor-pointer"
              onClick={() => history.push(LOG_IN)}
              primary
              size="s"
            >
              Back
            </Button>
          </>
        ) : (
          <form className="contents" onSubmit={handleSubmit(onSubmit)}>
            <p className="mt-6 color-neutral-700 typo-text-m">Please enter your password below.</p>
            <div className="mt-6">
              <Controller
                control={control}
                name="password"
                render={({ field }) => (
                  <Input
                    disabled={field.disabled}
                    hint={formState.errors.password?.message}
                    label="Password"
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    type="password"
                    value={field.value}
                    warn={!!formState.errors.password}
                  />
                )}
              />
            </div>

            <Button
              className="mt-6 flex cursor-pointer items-center justify-center gap-1"
              disabled={acceptInvitation.isLoading}
              filled
              primary
              size="s"
              type="submit"
            >
              {acceptInvitation.isLoading && <Spinner className="animate-spin" />}
              Set password
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};
