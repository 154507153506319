export const ROOT = '/';
export const LOG_IN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = `${FORGOT_PASSWORD}/reset/:key`;
export const SET_PASSWORD = `/invitation/set-password/:key`;
export const MANAGE_ACCOUNT = `/account/details`;

export const MANAGE_ROOT = '/manage';

export const MANAGE_UNAUTHORIZED = `${MANAGE_ROOT}/unauthorized`;

export const MANAGE_USERS = `${MANAGE_ROOT}/users`;
export const MANAGE_USERS_DETAILS = `${MANAGE_USERS}/:id/details`;
export const MANAGE_USERS_PERMISSIONS = `${MANAGE_USERS}/:id/permissions`;
export const MANAGE_USERS_GROUPS = `${MANAGE_USERS}/:id/groups`;
export const MANAGE_USERS_ROLES = `${MANAGE_USERS}/:id/roles`;

export const MANAGE_GROUPS = `${MANAGE_ROOT}/groups`;
export const MANAGE_GROUPS_CREATE_DETAILS = `${MANAGE_GROUPS}/details/create`;
export const MANAGE_GROUPS_CREATE_USERS = `${MANAGE_GROUPS}/users/create`;
export const MANAGE_GROUPS_CREATE_PERMISSIONS = `${MANAGE_GROUPS}/permissions/create`;
export const MANAGE_GROUPS_CREATE_ROLES = `${MANAGE_GROUPS}/roles/create`;
export const MANAGE_GROUPS_DETAILS = `${MANAGE_GROUPS}/:id/details`;
export const MANAGE_GROUPS_USERS = `${MANAGE_GROUPS}/:id/users`;
export const MANAGE_GROUPS_PERMISSIONS = `${MANAGE_GROUPS}/:id/permissions`;
export const MANAGE_GROUPS_ROLES = `${MANAGE_GROUPS}/:id/roles`;

export const MANAGE_ROLES = `${MANAGE_ROOT}/roles`;
export const MANAGE_ROLES_CREATE_DETAILS = `${MANAGE_ROLES}/details/create`;
export const MANAGE_ROLES_CREATE_PERMISSIONS = `${MANAGE_ROLES}/permissions/create`;
export const MANAGE_ROLES_DETAILS = `${MANAGE_ROLES}/:id/details`;
export const MANAGE_ROLES_USERS = `${MANAGE_ROLES}/:id/users`;
export const MANAGE_ROLES_GROUPS = `${MANAGE_ROLES}/:id/groups`;
export const MANAGE_ROLES_PERMISSIONS = `${MANAGE_ROLES}/:id/permissions`;

export const MANAGE_TEMPLATES = `${MANAGE_ROOT}/templates`;
export const MANAGE_TEMPLATES_CREATE = `${MANAGE_TEMPLATES}/create`;
export const MANAGE_TEMPLATES_DETAILS = `${MANAGE_TEMPLATES}/:id/details`;
export const MANAGE_TEMPLATES_EDIT = `${MANAGE_TEMPLATES}/:id/edit`;

export const MANAGE_BRANDING = `${MANAGE_ROOT}/branding`;

export const MANAGE_SUBSCRIPTION = `${MANAGE_ROOT}/subscription`;
