import * as PATHS from '@/constants/paths';
import { UserGroup } from '@/graphql/codegen/graphql';
import { createColumnHelper } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { ResourceCell } from './ResourceCell';
import { UserCell } from './UserCell';

const columnHelper = createColumnHelper<SolidId<UserGroup>>();

export const columns = [
  columnHelper.accessor('name', {
    header: 'user group name',
    cell: ({ getValue, row }) => (
      <div className="max-w-800px truncate" title={getValue() ?? ''}>
        <Link
          className="color-neutral-800 underline typo-link-s"
          to={`${PATHS.MANAGE_GROUPS}/${row.original.id}/details`}
        >
          {getValue()}
        </Link>
      </div>
    ),
  }),

  columnHelper.display({
    id: 'users',
    header: 'users',
    cell: ({ row }) => <UserCell row={row} />,
  }),

  columnHelper.display({
    id: 'resources',
    header: 'resources',
    cell: ({ row }) => <ResourceCell row={row} />,
  }),
];
