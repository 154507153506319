import { ROLE_CREATED_BY, ROLE_DETAILS_PAGE_MODE } from '@/constants/role';
import { useRoleQuery } from '@/hooks/useRoleQuery';
import { useRolesMutation } from '@/hooks/useRolesMutation';
import { FormEventHandler, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Content } from './Content';

export const EditMode = () => {
  const { id } = useParams<{ id: string }>();
  const { role } = useRoleQuery(id);

  const [roleName, setRoleName] = useState(role?.name ?? '');
  const [roleDescription, setRoleDescription] = useState(role?.description ?? '');

  useEffect(() => setRoleName(role?.name ?? ''), [role?.name]);
  useEffect(() => setRoleDescription(role?.description ?? ''), [role?.description]);

  const { updateRole } = useRolesMutation();

  const handleSubmitEditRoleDetails: FormEventHandler<HTMLFormElement> = useCallback(
    e => {
      e.preventDefault();

      updateRole.mutate({
        request: {
          roleId: id,
          name: roleName as string,
          description: roleDescription as string,
        },
      });
    },
    [id, roleDescription, roleName, updateRole],
  );

  return (
    <Content
      id={id}
      isSystemDefault={role?.createdBy === ROLE_CREATED_BY.SYSTEM}
      mode={ROLE_DETAILS_PAGE_MODE.EDIT}
      onSubmitEditRoleDetails={handleSubmitEditRoleDetails}
      roleDescription={roleDescription}
      roleName={roleName}
      setRoleDescription={setRoleDescription}
      setRoleName={setRoleName}
    />
  );
};
