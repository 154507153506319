import { MANAGE_SUBSCRIPTION } from '@/constants/paths';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { FeatureEntitlement, PolicyActionTypeInput, PolicyObjectTypeInput } from '@/graphql/codegen/graphql';
import { EntitlementName, useEntitlements } from '@/hooks/useEntitlements';
import { useIsSuperAdmin } from '@/hooks/useIsSuperAdmin';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Toast } from '@skand/ui';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export type AccessGateProps = {
  disabled: (entitlement: FeatureEntitlement) => ReactNode;
  enabled: (entitlement: FeatureEntitlement) => ReactNode;
  full?: (entitlement: FeatureEntitlement) => ReactNode;
  loading: () => ReactNode;
  entitlementCheck: {
    featureName: EntitlementName;
  }
  permissionCheck?: {
    objectId: string;
    objectType: PolicyObjectTypeInput;
    actionType: PolicyActionTypeInput;
  }
}

export const AccessGate = ({
  disabled,
  enabled,
  full,
  loading,
  entitlementCheck,
}: AccessGateProps) => {

  const isSuperAdmin = useIsSuperAdmin();
  const entitlement = useEntitlements().feature(entitlementCheck?.featureName);
  const { type, total, infinity, used } = entitlement ?? {};

  if (!entitlement) return loading();
  if (isSuperAdmin) return enabled(entitlement);

  if (type === 'quantity') {
    if (infinity) return enabled(entitlement);
    if (total !== undefined && total !== null && used !== undefined && used !== null) {
      if (total <= 0) return disabled(entitlement);
      if (used < total) return enabled(entitlement);
      if (used >= total) return full ? full(entitlement) : disabled(entitlement);
      if (total > 0) return enabled(entitlement);
    }
  }

  if (type === 'switch') {
    if (entitlement.enabled) return enabled(entitlement);
    return disabled(entitlement);
  }

  throw new Error('Invalid feature entitlement. Please contact support at support@skand.io'); // should not reach here
};

export const ToastButton = ({ button, toast }: { button: ReactNode; toast: ReactNode }) => {
  return (
    <Tooltip.Provider delayDuration={100}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{button}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side="bottom" sideOffset={8}>
            {toast}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

export const FeatureNotIncluded = ({
  button,
  readMoreUrl,
}: {
  button: ReactNode;
  readMoreUrl: string;
}) => {
  return (
    <ToastButton
      button={button}
      toast={
        <Toast
          message={
            <span>
              This feature is not included in your plan.&nbsp;
              <Link className="color-neutral-800 typo-link-s" to={MANAGE_SUBSCRIPTION}>
                Upgrade
              </Link>
              &nbsp;to unlock it or&nbsp;
              <a className="color-neutral-800 typo-link-s" href={readMoreUrl}>
                read more here
              </a>
              .
            </span>
          }
          size="l"
        />
      }
    />
  );
};

export const QuotaExceeded = ({ button }: { button: ReactNode }) => {
  return (
    <ToastButton
      button={button}
      toast={
        <Toast
          message={
            <span>
              All user seats have been allocated. Delete a user or&nbsp;
              <Link className="color-neutral-800 typo-link-xs" to={MANAGE_SUBSCRIPTION}>
                upgrade
              </Link>
              &nbsp; to invite more.&nbsp;
              <a className="color-neutral-800 typo-link-xs" href={READ_MORE_LINKS.PRICING}>
                Read what’s included in your plan.
              </a>
            </span>
          }
          size="l"
        />
      }
    />
  );
};