import { cn } from '@/utils/classname';
import { Skeleton } from '@skand/ui';
import { flexRender, useReactTable } from '@tanstack/react-table';
import { HTMLAttributes } from 'react';
import { Repeat } from './Repeat';

export interface TableProps<T> extends HTMLAttributes<HTMLTableElement> {
  table: ReturnType<typeof useReactTable<T>>;
  tdProps?: HTMLAttributes<HTMLTableCellElement>;
  isLoading?: boolean;
  empty?: React.ReactNode;
}

export const Table = <T,>({ table, className, tdProps, isLoading, empty }: TableProps<T>) => {
  const rows = table.getRowModel().rows;

  let lastWildcardIndex = -1;
  for (let i = 0; i < rows.length; i++) {
    if ((rows[i].original as { key?: string })?.key?.includes('-*')) {
      lastWildcardIndex = i;
    } else {
      break;
    }
  }

  if (isLoading) {
    const columnCount = table.getFlatHeaders().length;

    return (
      <div className={cn('w-full', className)}>
        <div className="b-y-1 b-y-neutral-500 b-y-solid py-3">
          <Skeleton className="h-14px" />
        </div>
        <Repeat n={24}>
          <div className="mt-12px flex gap-3">
            <Repeat n={columnCount}>
              <Skeleton className="h-28px flex-1" />
            </Repeat>
          </div>
        </Repeat>
      </div>
    );
  }

  if (empty && rows.length === 0) {
    return empty;
  }

  return (
    <table className={cn('w-full border-collapse table-auto', className)}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => {
          return (
            <tr className="b-y-1 b-y-neutral-500 b-y-solid" key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isSortable = header.column.getCanSort();
                const sort = header.column.getIsSorted();

                return (
                  <th
                    className="py-3 text-left uppercase color-neutral-800 typo-button-xs"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex items-center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}

                      {isSortable && (
                        <div
                          className={cn(
                            'i-skand-dropdown ml-2',
                            sort === false ? 'color-neutral-400' : 'color-neutral-800',
                            sort === 'asc' && 'rotate-180',
                          )}
                        />
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>

      <tbody>
        {rows.map((row, index) => {
          return (
            <tr
              className={cn(
                'b-y-1 b-y-neutral-300 b-y-solid',
                index === lastWildcardIndex && 'b-y-1 b-y-neutral-800 b-y-solid',
              )}
              key={row.id}
            >
              {row.getVisibleCells().map(cell => (
                <td
                  {...tdProps}
                  className={cn('py-4 color-neutral-800', tdProps?.className)}
                  key={cell.id}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
