import { EmptyListComponent, EmptyListSeachTopic } from '@/components/EmptyListComponent';
import { AccessGate, FeatureNotIncluded } from '@/components/AccessGate';
import { FindIcon } from '@/components/IconButton';
import { SearchResultsSummary } from '@/components/SearchResultsSummary';
import { SideBar } from '@/components/SideBar';
import { Table } from '@/components/Table';
import * as PATHS from '@/constants/paths';
import { READ_MORE_LINKS } from '@/constants/readMoreLinks';
import { ROLE_CREATED_BY } from '@/constants/role';
import { Role } from '@/graphql/codegen/graphql';
import { useRolesQuery } from '@/hooks/useRolesQuery';
import { clearRoleState } from '@/stores/role';
import { cn } from '@/utils/classname';
import { handleSearchInputField } from '@/utils/misc';
import { Button, Input } from '@skand/ui';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ENTITLEMENT_NAME } from '@/hooks/useEntitlements';

const columnHelper = createColumnHelper<SolidId<Role>>();

export const RolesPage = () => {
  const history = useHistory();
  const {
    roles,
    response: { isFetching },
  } = useRolesQuery();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        enableGlobalFilter: true,
        header: 'roles',
        cell: ({ getValue, row }) => (
          <div title={getValue() ?? ''}>
            <Link
              className="flex flex-row items-center justify-start gap-1 color-neutral-800 no-underline typo-link-s"
              to={`${PATHS.MANAGE_ROLES}/${row.original.id}/details`}
            >
              <div className="max-w-400px truncate underline">{getValue()}</div>
              <span className="color-neutral-400 typo-text-s">
                {row.original.createdBy === ROLE_CREATED_BY.SYSTEM && '— System default'}
                {row.original.createdBy === ROLE_CREATED_BY.USER && '— User created'}
              </span>
            </Link>
          </div>
        ),
      }),

      columnHelper.accessor('description', {
        enableGlobalFilter: true,
        header: 'description',
        cell: ({ getValue }) => (
          <div className="max-w-400px truncate color-neutral-800 typo-text-s">{getValue()}</div>
        ),
      }),
    ];
  }, []);

  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const table = useReactTable({
    columns,
    data: roles,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    state: { globalFilter, sorting },
    onSortingChange: setSorting,
  });

  const createRole = (
    <AccessGate
      disabled={() => (
        <FeatureNotIncluded
          button={
            <Button className="min-w-[134px]" disabled filled primary size="s">
              Create Role
            </Button>
          }
          readMoreUrl={READ_MORE_LINKS.ROLE}
        />
      )}
      enabled={() => (
        <Button
          className="min-w-[134px] hover:cursor-pointer"
          filled
          onClick={() => {
            clearRoleState();
            history.push(PATHS.MANAGE_ROLES_CREATE_DETAILS);
          }}
          primary
          size="s"
        >
          Create Role
        </Button>
      )}
      entitlementCheck={{ featureName: ENTITLEMENT_NAME.ROLE }}
      loading={() => (
        <Button className="min-w-[134px]" disabled filled primary size="s">
          Create Role
        </Button>
      )}
    />
  );

  const displayedRoles = table.getRowModel().rows.length;

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('max-h-fit', 'w-full', 'px-6', 'overflow-auto', 'flex flex-col')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'justify-between',
            'p-b-3',
            'p-t-30px',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800')}>Roles</h1>

          {createRole}
        </div>

        <div className="mt-3 flex items-start justify-between">
          <p className="truncate color-neutral-800 typo-text-s">
            Customise roles with a collection of permissions that can be assigned to users or user
            groups.
          </p>

          <div className="flex items-center justify-center gap-2">
            {globalFilter && (
              <div className="max-w-360px">
                <SearchResultsSummary dataLength={displayedRoles} searchKey={globalFilter} />
              </div>
            )}
            <div className={cn('w-360px', !roles.length && 'opacity-50')}>
              <Input
                disabled={!roles.length}
                onChange={e => handleSearchInputField(e, setGlobalFilter)}
                placeholder="Search for roles"
                tail={<FindIcon />}
                value={globalFilter}
              />
            </div>
          </div>
        </div>

        <Table
          className="mb-8 mt-6"
          empty={
            <EmptyListComponent searchTerm={globalFilter} searchTopic={EmptyListSeachTopic.ROLES}>
              {createRole}
            </EmptyListComponent>
          }
          isLoading={isFetching}
          table={table}
        />
      </div>
    </div>
  );
};
