import { GroupingPolicyInput, PolicySubjectTypeInput } from '@/graphql/codegen/graphql';

export const sourceIdAndTargetIdToGroupingPolicyInput = ({
  accountId,
  sourceId,
  sourceType,
  targetId,
  targetType,
}: {
  accountId: string;
  sourceId: string;
  sourceType: PolicySubjectTypeInput;
  targetId: string;
  targetType: PolicySubjectTypeInput;
}): GroupingPolicyInput => {
  if (
    (sourceType === PolicySubjectTypeInput.Role && targetType === PolicySubjectTypeInput.Group) ||
    (sourceType === PolicySubjectTypeInput.Role && targetType === PolicySubjectTypeInput.User) ||
    (sourceType === PolicySubjectTypeInput.Group && targetType === PolicySubjectTypeInput.User)
  ) {
    return {
      accountId,
      sourceId: targetId,
      sourceType: targetType,
      targetId: sourceId,
      targetType: sourceType,
    };
  }

  return {
    accountId,
    sourceId,
    sourceType,
    targetId,
    targetType,
  };
};
