import { cn } from '@skand/ui';
interface SearchResultsSummaryProps {
  dataLength: number;
  searchKey: string;
}

export const SearchResultsSummary = ({ dataLength, searchKey }: SearchResultsSummaryProps) => {
  return (
    <div
      className={cn(
        'typo-text-small color-neutral-600 whitespace-nowrap flex flex-row gap-1 items-center justify-end max-w-full',
      )}
    >
      Showing
      <span className="color-primary-400 typo-text-small-em">{dataLength} </span>
      {dataLength > 1 ? 'results' : 'result'} for
      <p className="inline-block truncate color-primary-400 typo-text-small-em" title={searchKey}>
        &#34;{searchKey}&#34;
      </p>
    </div>
  );
};
