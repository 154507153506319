import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { isEmpty } from '@/utils/misc';
import { useMemo } from 'react';
import { useGroupingPolicies } from './useGroupingPolicies';
import { useRolesQuery } from './useRolesQuery';

export const useGroupRoles = (groupId: null | string) => {
  const { roles } = useRolesQuery();

  const { policies } = useGroupingPolicies(
    {
      sourceId: groupId as string,
      sourceType: PolicySubjectTypeInput.Group,
      targetId: null,
      targetType: PolicySubjectTypeInput.Role,
    },
    { enabled: !!groupId },
  );

  const groupRoleIds = useMemo(() => {
    const ids = [];

    for (const policy of policies) {
      if (!isEmpty(policy.targetId)) ids.push(policy.targetId);
    }

    return ids;
  }, [policies]);

  const groupRoles = useMemo(
    () => roles.filter(role => groupRoleIds.includes(role.id)),
    [groupRoleIds, roles],
  );

  return { groupRoles, groupRoleIds };
};
