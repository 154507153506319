import { queryClient } from '@/graphql/client';
import {
  CreateRoleMutation,
  CreateRoleMutationVariables,
  DeleteRoleMutation,
  DeleteRoleMutationVariables,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
} from '@/graphql/codegen/graphql';
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { toast } from '@skand/ui';
import { UseMutationResult, useMutation } from '@tanstack/react-query';
import { useRoleQuery } from './useRoleQuery';
import { useRolesQuery } from './useRolesQuery';

export interface UseRoleMutation {
  createRole: UseMutationResult<CreateRoleMutation, unknown, CreateRoleMutationVariables, unknown>;
  updateRole: UseMutationResult<UpdateRoleMutation, unknown, UpdateRoleMutationVariables, unknown>;
  deleteRole: UseMutationResult<DeleteRoleMutation, unknown, DeleteRoleMutationVariables, unknown>;
}

export interface UseRoleMutationProps {
  onCreateRoleSuccess?: (createRoleId?: string) => void;
  onUpdateRoleSuccess?: (updateRoleId?: string) => void;
  onDeleteRoleSuccess?: () => void;
}

export const useRolesMutation = (props?: UseRoleMutationProps): UseRoleMutation => {
  const createRole = useMutation<CreateRoleMutation, unknown, CreateRoleMutationVariables, unknown>(
    {
      mutationFn: ({ name, description }) =>
        request(CREATE_ROLE, {
          name: name,
          description: description || '',
        }),
      onSuccess: ({ createRole }) => {
        props?.onCreateRoleSuccess?.(createRole as string);
      },
    },
  );

  const updateRole = useMutation<UpdateRoleMutation, unknown, UpdateRoleMutationVariables, unknown>(
    {
      mutationFn: updateRoleInput =>
        request(UPDATE_ROLE, {
          request: {
            roleId: updateRoleInput?.request?.roleId as string,
            name: updateRoleInput?.request?.name as string,
            description: updateRoleInput?.request?.description || '',
          },
        }),
      onSuccess: ({ updateRole }) => {
        queryClient.invalidateQueries(useRoleQuery.getQueryKey(updateRole as string));
        queryClient.invalidateQueries(useRolesQuery.queryKey);
        toast({
          type: 'success',
          message: 'Role has been updated successfully',
          lifespan: 10000,
        });
        props?.onUpdateRoleSuccess?.(updateRole as string);
      },
      onError: () => {
        toast({
          type: 'warn',
          message: 'Role update failed',
          lifespan: 10000,
        });
      },
    },
  );

  const deleteRole = useMutation<DeleteRoleMutation, unknown, DeleteRoleMutationVariables, unknown>(
    {
      mutationFn: ({ roleId }) =>
        request(DELETE_ROLE, {
          roleId,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(useRolesQuery.queryKey);

        toast({
          type: 'success',
          message: 'Role has been deleted successfully',
          lifespan: 10000,
        });

        props?.onDeleteRoleSuccess?.();
      },
      onError: () => {
        toast({
          type: 'warn',
          message: 'Role deletion failed',
          lifespan: 10000,
        });
      },
    },
  );

  return {
    createRole,
    updateRole,
    deleteRole,
  };
};
