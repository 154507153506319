import { FolderNode } from '@/graphql/codegen/graphql';
import { useParentNodesChainByParentNodeIdQuery } from '@/hooks/useParentNodesChainByParentNodeIdQuery';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';

export interface BreadcrumbProps {
  parentNodeId?: string;
  onNavigate: (folderNodeId?: string) => void;
}

export const Breadcrumbs = ({ parentNodeId, onNavigate }: BreadcrumbProps) => {
  const { parentNodesChain } = useParentNodesChainByParentNodeIdQuery(parentNodeId);

  const folderNodes = useMemo(() => {
    if (!parentNodesChain || !parentNodeId) return [];
    return structuredClone(parentNodesChain).reverse() as FolderNode[];
  }, [parentNodeId, parentNodesChain]);

  return (
    <div>
      <nav className="flex">
        <ol className="inline-flex list-none items-center space-x-1">
          <li className="inline-flex items-center">
            <div
              className={cn(
                'color-neutral-400',
                parentNodeId
                  ? 'typo-text-s-em hover:cursor-pointer'
                  : 'no-underline hover:cursor-default typo-text-s',
              )}
              onClick={() => onNavigate()}
            >
              All folders
            </div>
          </li>
          {(folderNodes || []).map(folderNode => (
            <li key={folderNode?.id}>
              <div className="flex items-center">
                <span className="color-neutral-400 typo-text-s">&#47;</span>
                <div
                  className={cn(
                    'ml-1 color-neutral-400',
                    parentNodeId !== folderNode?.id
                      ? 'typo-text-s-em hover:cursor-pointer'
                      : 'no-underline hover:cursor-default typo-text-s',
                  )}
                  onClick={() => onNavigate(folderNode?.id as string)}
                >
                  {folderNode?.name}
                </div>
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
