import { gql } from './codegen';

// Login
export const LOG_IN = gql(`
  mutation Login($email: String, $password: String) {
    login(email: $email, password: $password) {
      accountId
      bearerToken
      email
    }
  }
`);

export const LOG_IN_WITH_IDP_TOKEN = gql(`
  mutation LoginWithIdpToken {
    loginWithIdpToken {
      accountId
      bearerToken
      email
    }
  }
`);

export const RESET_PASSWORD_REQUEST = gql(`
  mutation ResetPasswordRequest($hostDomain: String!, $email: String!) {
    resetPasswordRequest(hostDomain: $hostDomain, email: $email)
  }
`);

export const RESET_PASSWORD = gql(`
  mutation ResetPassword($resetPasswordToken: String!, $password: String!) {
    resetPassword(resetPasswordToken: $resetPasswordToken, password: $password) {
      email
    }
  }
`);

export const CREATE_ACCOUNT_ANNOTATION_TEMPLATE = gql(`
  mutation CreateAccountAnnotationTemplate($request: CreateAccountAnnotationTemplateInput) {
    createAccountAnnotationTemplate(request: $request) {
      id
    }
  }
`);

export const CREATE_PROJECT_ANNOTATION_TEMPLATE = gql(`
  mutation CreateProjectAnnotationTemplate($request: CreateProjectAnnotationTemplateInput) {
    createProjectAnnotationTemplate(request: $request) {
      id
    }
  }
`);

export const UPDATE_ANNOTATION_TEMPLATE = gql(`
  mutation UpdateAnnotationTemplate($request: UpdateAnnotationTemplateInput) {
    updateAnnotationTemplate(request: $request) {
      id
    }
  }
`);

export const UPDATE_USER = gql(`
  mutation UpdateUser($user: UpdateUserInput) {
    updateUser(user: $user) {
      id
    }
  }
`);

export const UPDATE_USER_BY_ID = gql(`
  mutation UpdateUserById($userId: ID!, $user: UpdateUserInput!) {
    updateUserById(userId: $userId, user: $user) {
      id
    }
  }
`);

export const CREATE_ROLE = gql(`
  mutation CreateRole($name: String!, $description: String!) {
    createRole(name: $name, description: $description) 
  }
`);

export const UPDATE_ROLE = gql(`
  mutation UpdateRole($request: UpdateRoleInput) {
    updateRole(request: $request)
  }
`);

export const DELETE_ROLE = gql(`
  mutation DeleteRole($roleId: ID!) {
    deleteRole(roleId: $roleId)
  }
`);

export const CREATE_USER_GROUP = gql(`
  mutation CreateUserGroup($name: String!, $description: String!) {
    createUserGroup(name: $name, description: $description) 
  }
`);

export const UPDATE_USER_GROUP = gql(`
  mutation UpdateUserGroup($group: UpdateUserGroupInput) {
    updateUserGroup(request: $group)  
  }
`);

export const DELETE_USER_GROUP = gql(`
  mutation deleteUserGroup($userGroupId: ID!) {
    deleteUserGroup(userGroupId: $userGroupId)
  }
`);

export const CREATE_GROUPING_POLICY = gql(`
  mutation CreateGroupingPolicy($policies: [GroupingPolicyInput]) {
    createGroupingPolicies(groupingPolicies: $policies)
  }
`);

export const DELETE_GROUPING_POLICY = gql(`
  mutation DeleteGroupingPolicy($policies: [GroupingPolicyInput]) {
    deleteGroupingPolicies(groupingPolicies: $policies)
  }
`);

export const UPSERT_PERMISSION_POLICIES = gql(`
  mutation UpsertPermissionPolicies($policies: [PermissionPolicyInput]) {
    upsertPermissionPolicies(permissionPolicies: $policies)
  }
`);

export const DELETE_USER = gql(`
  mutation DeleteUser($userId: ID!) {
    deleteUser(userId: $userId) {
      id
    }
  }
`);

export const CREATE_PERMISSION_POLICY = gql(`
  mutation CreatePermissionPolicy($policies: [PermissionPolicyInput]) {
    upsertPermissionPolicies(permissionPolicies: $policies)
  }
`);

export const DELETE_PERMISSION_POLICY = gql(`
  mutation DeletePermissionPolicy($policies: [PermissionPolicyInput]) {
    deletePermissionPolicies(permissionPolicies: $policies)
  }
`);

export const DELETE_PERMISSION_POLICIES = gql(`
  mutation DeletePermissionPolicies($permissionPolicies: [PermissionPolicyInput]) {
    deletePermissionPolicies(permissionPolicies: $permissionPolicies)
  }
`);

export const CREATE_GROUPING_POLICIES = gql(`
  mutation CreateGroupingPolicies($groupingPolicies: [GroupingPolicyInput]) {
    createGroupingPolicies(groupingPolicies: $groupingPolicies)
  }
`);

export const DELETE_GROUPING_POLICIES = gql(`
  mutation DeleteGroupingPolicies($groupingPolicies: [GroupingPolicyInput]) {
    deleteGroupingPolicies(groupingPolicies: $groupingPolicies)
  }
`);

export const CREATE_PORTAL_SESSION = gql(`
  mutation createSubscriptionPortalSession {
    createSubscriptionPortalSession
  }
`);

export const INVITE_USER = gql(`
  mutation InviteUserV2($newUserEmail: String, $firstName: String, $lastName: String, $roleId: String, $userGroupId: String) {
    inviteUserV2(newUserEmail: $newUserEmail, firstName: $firstName, lastName: $lastName, roleId: $roleId, userGroupId: $userGroupId) {
      id
    }
  }
`);

export const DEACTIVATE_USER = gql(`
  mutation deactivateUser($userId: ID!) {
    deactivateUser(userId: $userId)
  }
`);

export const ACTIVATE_USER = gql(`
  mutation activateUser($userId: ID!) {
    activateUser(userId: $userId)
  }
`);

export const UPDATE_ACCOUNT_BRANDING = gql(`
  mutation UpdateAccountBranding(
    $accountId: ID!
    $color: String
    $hasSkandWatermark: Boolean
    $reportBannerFileId: ID
    $webappBannerFileId: ID
    $webappLogoFileId: ID
  ) {
    updateAccountBranding(
      accountId: $accountId
      color: $color
      hasSkandWatermark: $hasSkandWatermark
      reportBannerFileId: $reportBannerFileId
      webappBannerFileId: $webappBannerFileId
      webappLogoFileId: $webappLogoFileId
    ) {
      id
      branding {
        color
        hasSkandWatermark
        reportBannerFileId
        webappBannerFileId
        webappLogoFileId
      }
    }
  }
`);

export const ACCEPT_INVITATION = gql(`
  mutation AcceptInvitation($invitationToken: String, $password: String) {
    acceptInvitation(invitationToken: $invitationToken, password: $password) {
      id
      email
    }
  }
`);

export const LOGOUT_MUTATION = gql(`
  mutation LogoutMutation {
    logout
  }
`);

export const RESEND_INVITATION = gql(`
  mutation ResendInvitation($userId: ID!) {
    resendInvitationToUser(userId: $userId)
  }
`);
