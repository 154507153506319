import { SideBar } from '@/components/SideBar';
import { cn } from '@/utils/classname';
import { TemplatePanel } from './TemplatePanel';

export const TemplatesCreatePage = () => {
  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <TemplatePanel mode="create" />
    </div>
  );
};
