import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { cn } from '@/utils/classname';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { Button } from '@skand/ui';
import { useState } from 'react';

export interface DeleteButtonProps {
  subjectType: PolicySubjectTypeInput;
  onDelete: () => void;
  disabled?: boolean;
}

export const DeleteButton = ({ subjectType, onDelete, disabled }: DeleteButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const subject = subjectType.toString().toLowerCase();

  return (
    <div className="flex items-center">
      <AlertDialog.Root onOpenChange={setIsOpen} open={isOpen}>
        <AlertDialog.Trigger asChild>
          <Button
            className={cn('w-full', {
              'cursor-pointer hover:cursor-pointer': !disabled,
              'hover:bg-transparent active:bg-transparent': disabled,
            })}
            disabled={disabled}
            size="s"
          >{`Delete ${subject == 'group' ? 'user group' : subject}`}</Button>
        </AlertDialog.Trigger>

        <AlertDialog.Portal>
          <AlertDialog.Overlay />
          <AlertDialog.Content
            className={cn(
              'b-1 b-neutral-600 b-solid',
              'bg-neutral-100',
              'fixed',
              'flex-col',
              'flex',
              'overflow-auto',
              'p-6',
              'rounded-2',
              'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
              'top-50% left-50% transform-translate--50%',
              'w-640px',
              'z-1',
            )}
          >
            <AlertDialog.Title className="color-neutral-800 typo-text-m">
              {`Delete ${subject}`}
            </AlertDialog.Title>
            <AlertDialog.Description className="mt-3 color-neutral-800 typo-text-s">
              {`This action cannot be undone. Are you sure you want to delete this ${subject}?`}
            </AlertDialog.Description>

            <div className="mt-6 flex gap-3">
              <AlertDialog.Cancel asChild>
                <Button className="flex-1 hover:cursor-pointer" size="s">
                  Cancel
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button
                  className="flex-1 hover:cursor-pointer"
                  filled
                  onClick={onDelete}
                  primary
                  size="s"
                >
                  Delete
                </Button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    </div>
  );
};
