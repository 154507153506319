import { FindIcon } from '@/components/IconButton';
import { SceneEntityNode, useSceneEntityData } from '@/hooks/useSceneEntityTreeData';
import { cn } from '@/utils/classname';
import { isEmpty } from '@/utils/misc';
import { search } from '@/utils/search';
import { Input, Tree, TreeNodeProps } from '@skand/ui';
import { Dispatch, useCallback, useMemo, useState } from 'react';
import { Checkbox } from '../Checkbox';
import { AnnotationGroupNode } from './AnnotationGroupNode';
import { AnnotationNode } from './AnnotationNode';
import { LayerNode } from './LayerNode';
import { PhotoGroupNode } from './PhotoGroupNode';
import { PhotoNode } from './PhotoNode';
import { FilterOptions, TreeFilterDropdownMenu } from './TreeFilterDropdownMenu';
import { UNSELECTABLE_LAYER_TYPES } from './constants';
import { LayerGroupNode } from './LayerGroupNode';

export const SceneEntityTree = ({
  projectId,
  selectedSceneEntityIds,
  setSelectedSceneEntityIds,
  treeClassName,
}: {
  projectId?: string;
  selectedSceneEntityIds: string[];
  setSelectedSceneEntityIds: Dispatch<React.SetStateAction<string[]>>;
  treeClassName?: string;
}) => {
  const [searchKey, setSearchKey] = useState('');

  const [filterNodeTypes, setFilterNodeTypes] = useState<string[]>([
    ...FilterOptions.map(option => option.value),
  ]);

  const { treeData: sceneEntityTreeData, isLoadingTreeData } = useSceneEntityData({
    projectId,
    filterNodeTypes,
  });

  const handleToggleLayer = useCallback(
    (id: string) => {
      if (selectedSceneEntityIds.includes(id)) {
        setSelectedSceneEntityIds(prev => prev.filter(query => query !== id));
      } else {
        setSelectedSceneEntityIds(prev => [...prev, id]);
      }
    },
    [selectedSceneEntityIds, setSelectedSceneEntityIds],
  );

  const TreeNode = useCallback(
    ({ data, isLeaf, depth, setOpen, isOpen }: TreeNodeProps<SceneEntityNode>) => (
      <div className={cn('w-full h-full')}>
        <div
          className={cn('flex', 'flex-row', 'items-center', 'flex-1', 'mr-2', 'w-full')}
          style={{ marginLeft: `${depth * 0.5}rem` }}
        >
          {!UNSELECTABLE_LAYER_TYPES.includes(data.entity.type) && (
            <Checkbox
              checked={
                'sceneEntityId' in data.entity &&
                selectedSceneEntityIds.includes(data.entity.sceneEntityId)
              }
              setToggleCheckbox={() =>
                'sceneEntityId' in data.entity && handleToggleLayer(data.entity.sceneEntityId)
              }
            />
          )}

          <div
            className={cn(
              !isLeaf ? 'i-skand-dropdownarrow cursor-pointer' : 'w-1em h-1em',
              'text-neutral-400',
              'text-3',
              'mr-2',
              'ml-2',
              !isOpen && 'rotate-270',
            )}
            onClick={() => setOpen(!isOpen)}
          />

          {data.entity.type === 'layerGroup' && <LayerGroupNode group={data.entity} />}
          {data.entity.type === 'layer' && <LayerNode layer={data.entity} />}
          {data.entity.type === 'annotation' && <AnnotationNode annotation={data.entity} />}
          {data.entity.type === 'annotationGroup' && <AnnotationGroupNode group={data.entity} />}

          {data.entity.type === 'photoGroup' && <PhotoGroupNode group={data.entity} />}
          {(data.entity.type === 'panorama' || data.entity.type === 'photo2D') && (
            <PhotoNode photo={data.entity} />
          )}
        </div>
      </div>
    ),
    [handleToggleLayer, selectedSceneEntityIds],
  );

  const treeSortCmp = useCallback((a: SceneEntityNode, b: SceneEntityNode) => {
    const typeScore = (node: SceneEntityNode) => {
      switch (node.entity.type) {
        case 'panorama':
          return 0;
        case 'photo2D':
          return 1;
        case 'annotation':
          return 2;
        case 'annotationGroup':
          return 3;
        case 'photoGroup':
          return 4;
        case 'layer':
          return 5;
        case 'layerGroup':
          return 6;
      }
    };
    if (a.entity.type === 'layer' && b.entity.type === 'layer') {
      return a.entity.captureDate.getTime() - b.entity.captureDate.getTime();
    } else if (
      (a.entity.type === 'photo2D' && b.entity.type === 'photo2D') ||
      (a.entity.type === 'panorama' && b.entity.type === 'panorama')
    ) {
      return b.entity.name.localeCompare(a.entity.name, undefined, {
        numeric: true,
      });
    } else {
      return a.children.length - b.children.length || typeScore(a) - typeScore(b);
    }
  }, []);

  // Filter tree rows by search key
  const searchResult = useMemo(() => {
    const results = [];
    const queue = [...sceneEntityTreeData];

    while (queue.length && searchKey.length) {
      const node = queue.shift();
      if (node) {
        queue.push(...node.children);
        if (search(node.entity.name, searchKey)) {
          results.push(node);
        }
      }
    }
    return results;
  }, [searchKey, sceneEntityTreeData]);

  const roots = useMemo(() => {
    return searchResult.length ? searchResult : sceneEntityTreeData;
  }, [sceneEntityTreeData, searchResult]);

  return (
    <>
      <div className={cn('flex gap-2 relative mb-1')}>
        <div className={cn('w-full')}>
          <Input
            data-testid="input"
            onChange={e => setSearchKey(e)}
            placeholder="Search for layers"
            tail={<FindIcon />}
            value={searchKey}
          />
        </div>

        <TreeFilterDropdownMenu
          filterNodeTypes={filterNodeTypes}
          setFilterNodeTypes={setFilterNodeTypes}
        />
      </div>

      {!isEmpty(projectId) && !isLoadingTreeData && roots?.length > 0 && (
        <div className={cn('overflow-y-auto', 'scrollbar', 'scrollbar-rounded', treeClassName)}>
          <Tree
            getKey={(node: SceneEntityNode) => node.entity.id}
            quickListProps={{ scrollbarSpace: 20 }}
            roots={roots}
            sortCmp={treeSortCmp}
            walker={(node: SceneEntityNode) => node.children}
          >
            {TreeNode}
          </Tree>
        </div>
      )}
      {(isEmpty(projectId) || isLoadingTreeData || !(roots?.length > 0)) && (
        <div className="mt-1/2 flex justify-center">
          <p className="color-neutral-500 typo-text-s">
            {isEmpty(projectId) && 'Select project to show layers'}
            {isLoadingTreeData && 'Loading...'}
            {!isEmpty(projectId) &&
              !isLoadingTreeData &&
              !(roots?.length > 0) &&
              'No data available'}
          </p>
        </div>
      )}
    </>
  );
};
