import { queryClient } from '@/graphql/client';
import { ACTIVATE_USER } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useUser } from '@/hooks/useUser';
import { useUsers } from '@/hooks/useUsers';
import { toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import { useEntitlements } from './useEntitlements';
import { useListUsersByAccountId } from './useListUsersByAccountId';
import { useReadUserById } from './useReadUserById';

const queryKey = ['ACTIVATE_USER'];

export const useActivateUser = (userId: string) => {
  const response = useMutation({
    mutationFn: () => request(ACTIVATE_USER, { userId: userId }),
    onSuccess: () => {
      queryClient.invalidateQueries(useUser.getQueryKey(userId));
      queryClient.invalidateQueries(useUsers.queryKey);
      queryClient.invalidateQueries(useReadUserById.getQueryKey(userId));
      queryClient.invalidateQueries(useListUsersByAccountId.queryKey);
      queryClient.invalidateQueries(useEntitlements.queryKey);

      toast({
        type: 'success',
        message: 'User has been activated successfully',
        lifespan: 10000,
      });
    },
    onError: () => {
      toast({
        type: 'warn',
        message: "User couldn't be activated",
        lifespan: 10000,
      });
    },
  });

  return { response };
};

useActivateUser.queryKey = queryKey;
