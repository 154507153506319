import { cn } from '@/utils/classname';

export interface CheckboxProps {
  checked: boolean;
  setToggleCheckbox: (checked: boolean) => void;
  disabled?: boolean;
}

export const Checkbox = ({ checked, setToggleCheckbox, disabled }: CheckboxProps) => {
  return (
    <div
      className={cn('cursor-pointer')}
      onClick={() => {
        if (!disabled) {
          setToggleCheckbox(!checked);
        }
      }}
    >
      {checked ? <CheckedSvg /> : <UncheckedSvg />}
    </div>
  );
};

const CheckedSvg = () => <div className="i-skand-boxchecked h-12px w-12px color-neutral-600" />;

const UncheckedSvg = () => <div className="i-skand-boxunchecked h-12px w-12px color-neutral-600" />;
