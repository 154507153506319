import { LAYER_FORMAT_TYPE } from '@/constants/layer';
import { cn } from '@/utils/classname';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Button } from '@skand/ui';
import { useEffect, useState } from 'react';
import { Checkbox } from '../Checkbox';

export const FilterOptions = [
  {
    label: 'Layer group',
    value: 'layerGroup',
  },
  {
    label: 'Annotation group',
    value: 'annotationGroup',
  },
  {
    label: 'Photo group',
    value: 'photoGroup',
  },
  {
    label: '2D Annotation',
    value: 'sketch2D',
  },
  {
    label: '3D Annotation',
    value: 'sketch3D',
  },
  {
    label: 'Point cloud',
    value: LAYER_FORMAT_TYPE.POINT_CLOUD,
  },
  {
    label: '3D mesh',
    value: LAYER_FORMAT_TYPE.MESH_3D,
  },
  {
    label: 'OBJ',
    value: LAYER_FORMAT_TYPE.OBJ,
  },
  {
    label: 'BIM/CAD models',
    value: LAYER_FORMAT_TYPE.BIM_CAD_MODEL,
  },
  {
    label: 'Ortho',
    value: LAYER_FORMAT_TYPE.ORTHO_2D,
  },
  {
    label: 'Terrain',
    value: LAYER_FORMAT_TYPE.TERRAIN,
  },
  {
    label: 'IFC',
    value: LAYER_FORMAT_TYPE.IFC,
  },
  {
    label: 'DXF',
    value: LAYER_FORMAT_TYPE.DXF,
  },
  {
    label: 'Panoramas',
    value: 'panorama',
  },
  {
    label: '2D images',
    value: 'photo2D',
  },
];

interface TreeFilterDropdownMenuProps {
  filterNodeTypes: string[];
  setFilterNodeTypes: (values: string[]) => void;
}

export const TreeFilterDropdownMenu = ({
  filterNodeTypes,
  setFilterNodeTypes,
}: TreeFilterDropdownMenuProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<string[]>(filterNodeTypes);

  useEffect(() => {
    if (isOpen) {
      setFilters(filterNodeTypes);
    }
  }, [filterNodeTypes, isOpen]);

  const handleToggleAll = (on: boolean) => {
    if (on) {
      setFilters(FilterOptions.map(option => option.value));
    } else {
      setFilters([]);
    }
  };

  const handleToggleFilters = (id: string) => {
    if (filters.includes(id)) {
      setFilters(prev => prev.filter(query => query !== id));
    } else {
      setFilters(prev => [...prev, id]);
    }
  };

  const appyFilters = () => {
    setFilterNodeTypes(filters);
    setIsOpen(false);
  };

  return (
    <DropdownMenu.Root onOpenChange={setIsOpen} open={isOpen}>
      <DropdownMenu.Trigger asChild>
        <Button active={isOpen} className="w-20 hover:cursor-pointer" size="s">
          Filter
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          align="end"
          className={cn(
            'b-1 b-neutral-400 b-solid rounded',
            'w-360px p-3',
            'bg-neutral-100',
            'flex flex-col gap-2',
            'z-1',
          )}
          side="bottom"
          sideOffset={5}
        >
          <div className={cn('flex', 'flex-row', 'justify-between', 'items-center')}>
            <p className={cn('typo-text-medium', 'color-neutral-800')}>Filter options</p>
            <div
              className={cn('i-skand-close', 'text-3', 'text-neutral-400', 'p-1', 'cursor-pointer')}
              onClick={() => setIsOpen(false)}
            />
          </div>

          <p className={cn('typo-text-s-em', 'color-neutral-800')}>Layer types</p>

          <div className="flex flex-col gap-2">
            <div className={cn('flex', 'flex-row', 'items-center', 'gap-2')}>
              <Checkbox
                checked={FilterOptions.length === filters.length}
                setToggleCheckbox={handleToggleAll}
              />
              <span className={cn('color-neutral-800', 'typo-text-small-em')}>All</span>
            </div>

            {FilterOptions.map((option, index) => (
              <div className={cn('flex', 'flex-row', 'items-center', 'gap-2')} key={index}>
                <Checkbox
                  checked={filters.includes(option.value)}
                  setToggleCheckbox={() => handleToggleFilters(option.value)}
                />
                <span className={cn('color-neutral-800', 'typo-text-small')}>{option.label}</span>
              </div>
            ))}
          </div>
          <div className={cn('flex', 'items-center', '', 'gap-2')}>
            <Button
              className={cn('flex-1')}
              onClick={() => {
                setFilters([]);
              }}
              size="s"
            >
              Clear Filter
            </Button>
            <Button className={cn('flex-1')} filled onClick={appyFilters} primary size="s">
              Apply
            </Button>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
