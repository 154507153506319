import { LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

export type EntitlementType = 'quantity' | 'switch';
export type EntitlementSwitchName =
  | 'branding'
  | 'annotation-template'
  | 'role'
  | 'user-group'
  | 'permission';
export type EntitlementQuantityName = 'user' | 'storage';
export type EntitlementName = EntitlementSwitchName | EntitlementQuantityName;
export const ENTITLEMENT_NAME = {
  ANNOTATION_TEMPLATE: 'annotation-template',
  BRANDING: 'branding',
  PERMISSION: 'permission',
  ROLE: 'role',
  STORAGE: 'storage',
  USER_GROUP: 'user-group',
  USER: 'user',
} as const;

export const queryKey = ['LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT'];

export const useEntitlements = () => {
  const query = useQuery({
    queryFn: () => request(LIST_FEATURE_ENTITLEMENTS_BY_ACCOUNT_CONTEXT),
    queryKey,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const entitlements = useMemo(() => {
    return query.data?.listFeatureEntitlementsByAccountContext ?? [];
  }, [query.data?.listFeatureEntitlementsByAccountContext]);

  const feature = useCallback(
    (name: EntitlementName) => {
      return entitlements.find(item => item?.name === name) ?? undefined;
    },
    [entitlements],
  );

  return { query, entitlements, feature };
};

useEntitlements.queryKey = queryKey;
