import { READ_USER_BY_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { hasId } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const queryKey = ['READ_USER_BY_CONTEXT'];

export const useReadUserByContext = () => {
  const response = useQuery({
    queryFn: () => request(READ_USER_BY_CONTEXT),
    queryKey,
  });

  const user = useMemo(() => {
    const user = response.data?.readUserByContext;

    if (!hasId(user)) return null;
    return user;
  }, [response.data?.readUserByContext]);

  return { response, user };
};

useReadUserByContext.queryKey = queryKey;
