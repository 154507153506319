import { SKAND_SUPPORT_EMAIL } from '@/constants/env';
import { cn } from '@/utils/classname';

export const UnauthorizedPage = () => {
  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'items-center justify-center',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <div className="w-[500px] text-neutral-500">
        <h1 className="typo-heading-2">Oops!</h1>
        <p className="mt-5 typo-text-m">
          You do not have the permission to access this page. Please reach out to your
          administrators for assistance. If you believe this is in error, please contact our support
          team at{' '}
          {
            <a
              className="color-primary-400 no-underline typo-link-m"
              href={`mailto:${SKAND_SUPPORT_EMAIL}`}
            >
              {SKAND_SUPPORT_EMAIL}
            </a>
          }
        </p>
      </div>
    </div>
  );
};
