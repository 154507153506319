import { RESET_PASSWORD_REQUEST } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { toast } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';

const queryKey = ['RESET_PASSWORD'];

export const useResetPassword = () => {
  const response = useMutation({
    mutationFn: async ({ email, hostDomain }: { email: string; hostDomain: string }) => {
      return request(RESET_PASSWORD_REQUEST, { email, hostDomain });
    },
    onSuccess: () => {
      toast({
        type: 'success',
        message: 'We will send you an email with instructions on how to reset your password.',
        lifespan: 10000,
      });
    },
    onError: () => {
      toast({
        type: 'warn',
        message: 'Something wrong with resetting your password, Please contact support',
        lifespan: 10000,
      });
    },
  });

  return { response };
};

useResetPassword.queryKey = queryKey;
