import * as PATHS from '@/constants/paths';
import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';

export const getGroupsPagesSidePanelLinks = (id: string) => [
  { path: `${PATHS.MANAGE_GROUPS}/${id}/details`, title: 'User Group Details' },
  { path: `${PATHS.MANAGE_GROUPS}/${id}/permissions`, title: 'Permissions' },
  { path: `${PATHS.MANAGE_GROUPS}/${id}/users`, title: 'Users' },
  { path: `${PATHS.MANAGE_GROUPS}/${id}/roles`, title: 'Roles' },
];

export const getCreateGroupsPagesSidePanelLinks = () => [
  { path: `${PATHS.MANAGE_GROUPS_CREATE_DETAILS}`, title: 'User Group Details' },
  { path: `${PATHS.MANAGE_GROUPS_CREATE_PERMISSIONS}`, title: 'Permissions' },
  { path: `${PATHS.MANAGE_GROUPS_CREATE_USERS}`, title: 'Users' },
];

export const getRolesPagesSidePanelLinks = (id: string) => [
  { path: `${PATHS.MANAGE_ROLES}/${id}/details`, title: 'Role Details' },
  { path: `${PATHS.MANAGE_ROLES}/${id}/permissions`, title: 'Permissions' },
  { path: `${PATHS.MANAGE_ROLES}/${id}/users`, title: 'Users' },
  { path: `${PATHS.MANAGE_ROLES}/${id}/groups`, title: 'User Groups' },
];

export const getCreateRolesPagesSidePanelLinks = () => [
  { path: `${PATHS.MANAGE_ROLES_CREATE_DETAILS}`, title: 'Role Details' },
  { path: `${PATHS.MANAGE_ROLES_CREATE_PERMISSIONS}`, title: 'Permissions' },
];

export const getUsersPagesSidePanelLinks = (id: string) => [
  { path: `${PATHS.MANAGE_USERS}/${id}/details`, title: 'User Profile' },
  { path: `${PATHS.MANAGE_USERS}/${id}/permissions`, title: 'Permissions' },
  { path: `${PATHS.MANAGE_USERS}/${id}/groups`, title: 'User Groups' },
  { path: `${PATHS.MANAGE_USERS}/${id}/roles`, title: 'Roles' },
];

export const getSubjectsPagesSidePanelLinks = ({
  id,
  subjectType,
}: {
  id: string;
  subjectType: PolicySubjectTypeInput;
}) => {
  switch (subjectType) {
    case PolicySubjectTypeInput.User: {
      return getUsersPagesSidePanelLinks(id);
    }
    case PolicySubjectTypeInput.Group: {
      return getGroupsPagesSidePanelLinks(id);
    }
    case PolicySubjectTypeInput.Role: {
      return getRolesPagesSidePanelLinks(id);
    }
    default:
      return [];
  }
};

export const getCreateSubjectsPagesSidePanelLinks = ({
  subjectType,
}: {
  subjectType: PolicySubjectTypeInput;
}) => {
  switch (subjectType) {
    case PolicySubjectTypeInput.Group: {
      return getCreateGroupsPagesSidePanelLinks();
    }
    case PolicySubjectTypeInput.Role: {
      return getCreateRolesPagesSidePanelLinks();
    }
    default:
      return [];
  }
};
