import { LIST_ANNOTATIONS_BY_PROJECT_ID } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const getQueryKey = (projectId: string) => ['LIST_ANNOTATIONS_BY_PROJECT_ID', projectId];

export const useListAnnotationsByProjectId = ({ projectId }: { projectId?: string }) => {
  const response = useQuery({
    enabled: !!projectId,
    queryFn: () =>
      request(LIST_ANNOTATIONS_BY_PROJECT_ID, {
        projectId: projectId as string,
      }),
    queryKey: getQueryKey(projectId as string),
    keepPreviousData: true,
  });

  const annotations = useMemo(() => {
    return response.data?.listAnnotationsByProjectId ?? [];
  }, [response.data?.listAnnotationsByProjectId]);

  return {
    response,
    annotations,
  };
};

useListAnnotationsByProjectId.getQueryKey = getQueryKey;
