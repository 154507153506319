import { useReadUserByContext } from './useReadUserByContext';

export const useIsUserAdmin = () => {
  const { user, response } = useReadUserByContext();

  if (response.isFetching) {
    return true;
  }

  return user?.isAdmin ?? false;
};
