import { Project } from '@/graphql/codegen/graphql';

import { Input, Select } from '@skand/ui';
import { useMemo, useState } from 'react';
import { FindIcon } from '../IconButton';

interface ProjectsSelectProps {
  options: SolidId<Project>[];
  onValueChange: (value: string) => void;
}

export const ProjectsSelect = ({ options, onValueChange }: ProjectsSelectProps) => {
  const [searchQuery, setSearchQuery] = useState('');

  const transformedOptions = options.map(project => ({
    key: project.id,
    name: project.name || 'Unnamed Project',
  }));

  const filteredOptions = useMemo(() => {
    return transformedOptions.filter(project =>
      project?.name?.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [transformedOptions, searchQuery]);

  const handleInputChange = (event: string) => {
    setSearchQuery(event);
  };

  const handleSelectChange = (selectedKey: string) => {
    onValueChange(selectedKey);
  };

  return (
    <Select
      className="min-w-194px"
      filterSlot={
        <div className="m-1">
          <Input
            onChange={handleInputChange}
            placeholder="Search"
            tail={<FindIcon />}
            value={searchQuery}
          />
        </div>
      }
      label="Search projects"
      onChange={handleSelectChange}
      options={filteredOptions}
    />
  );
};
