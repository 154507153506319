import { PolicySubjectTypeInput } from '@/graphql/codegen/graphql';
import { LIST_GROUPING_POLICIES_WITH_ACCOUNT_CONTEXT } from '@/graphql/queries';
import { request } from '@/graphql/request';
import { isDefiend } from '@/utils/misc';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

export interface GroupingPolicyQueryTuple {
  sourceId: null | string;
  sourceType: null | PolicySubjectTypeInput;
  targetId: null | string;
  targetType: null | PolicySubjectTypeInput;
}

export interface UseGroupingPoliciesOptions {
  enabled?: boolean;
}

const getQueryKey = ({ sourceId, sourceType, targetId, targetType }: GroupingPolicyQueryTuple) => [
  'LIST_GROUPING_POLICIES_WITH_ACCOUNT_CONTEXT',
  sourceId,
  sourceType,
  targetId,
  targetType,
];

export const useGroupingPolicies = (
  query: GroupingPolicyQueryTuple,
  options: UseGroupingPoliciesOptions = {},
) => {
  const { enabled = true } = options;

  const response = useQuery({
    enabled,
    queryFn: () => request(LIST_GROUPING_POLICIES_WITH_ACCOUNT_CONTEXT, query),
    queryKey: getQueryKey(query),
    refetchOnMount: true,
  });

  const policies = useMemo(() => {
    return response.data?.listGroupingPoliciesWithAccountContext?.filter(isDefiend) ?? [];
  }, [response.data?.listGroupingPoliciesWithAccountContext]);

  return { response, policies };
};

useGroupingPolicies.getQueryKey = getQueryKey;
