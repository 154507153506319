import { GRAPHQL_URL } from '@/constants/env';
import { ROOT } from '@/constants/paths';
import { clearAuth, getAuth } from '@/utils/auth';
import * as logger from '@/utils/logger';
import { GraphQLClient, ResponseMiddleware } from 'graphql-request';

const INVALID_TOKEN_ENUM = 'INVALID_TOKEN';

const responseMiddleware: ResponseMiddleware = response => {
  if (response instanceof Error) {
    logger.error('network error', response);
    return;
  }

  if (response.errors) {
    logger.error('backend error', response.status, response.errors);

    if (response.errors[0].extensions?.code === INVALID_TOKEN_ENUM) {
      clearAuth();
      location.replace(ROOT);
      return;
    }

    throw new Error(response.errors[0].message);
  }
};

const client = new GraphQLClient(GRAPHQL_URL, {
  errorPolicy: 'all',
  headers: () => {
    return {
      Authorization: getAuth() ?? '',
    };
  },
  method: 'POST',
  responseMiddleware,
});

export const request = client.request.bind(client);
