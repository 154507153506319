import { create } from 'zustand';

interface SubjectPermissionsPageState {
  disablePermissionActions: boolean;
}

export const useSubjectPermissionsPageStore = create<SubjectPermissionsPageState>(() => ({
  disablePermissionActions: false,
}));

export const setDisablePermissionActions = (disablePermissionActions: boolean) => {
  useSubjectPermissionsPageStore.setState({ disablePermissionActions });
};
