import { UserV2 } from '@/graphql/codegen/graphql';
import { User } from '@/utils/interfaces';
import { isEmpty } from './empty';

export const createUser = (data: UserV2): User | undefined => {
  if (
    !isEmpty(data?.id) &&
    !isEmpty(data?.email) &&
    !isEmpty(data?.name) &&
    !isEmpty(data?.name?.first) &&
    !isEmpty(data?.name?.last) &&
    !isEmpty(data?.isActive) &&
    !isEmpty(data?.isAdmin) &&
    !isEmpty(data?.status) 
  ) {
    return {
      id: data.id,
      email: data.email,
      firstName: data.name.first,
      lastName: data.name.last,
      isActive: data.isActive,
      isAdmin: data.isAdmin,
      status: data.status,
      invitationCreatedAt: data.invitationCreatedAt as string ?? null,
    };
  }
};
