import brand from '@/assets/brand.svg';
import { ACCESS_TOKEN_NAME, USER_EMAIL_NAME } from '@/constants/env';
import * as PATHS from '@/constants/paths';
import { LOG_IN_WITH_IDP_TOKEN } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useLogIn } from '@/hooks/useLogIn';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, Spinner } from '@skand/ui';
import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { z } from 'zod';
import { useGainIdpToken } from './useGainIdpToken';

const schema = z.object({
  email: z.string().email(),
  password: z.string(),
});

export type LogInInputs = z.infer<typeof schema>;

export interface LogInPageProps {
  onLogIn?: (args: {
    accountId?: null | string;
    bearerToken?: null | string;
    userEmail?: null | string;
  }) => void;
}

export const LogInPage = ({ onLogIn }: LogInPageProps) => {
  const history = useHistory();

  const { control, handleSubmit, formState, getValues } = useForm<LogInInputs>({
    resolver: zodResolver(schema),
  });

  const logIn = useLogIn({ onLogIn });

  const loginWithIdpToken = useMutation({
    mutationFn: ({ idpToken }: { idpToken: string }) =>
      request(LOG_IN_WITH_IDP_TOKEN, undefined, { Authorization: `Bearer ${idpToken}` }),
    onSuccess: data => {
      const bearerToken = data?.loginWithIdpToken?.bearerToken;
      const userEmail = data?.loginWithIdpToken?.email;
      const accountId = data?.loginWithIdpToken?.accountId;

      if (bearerToken) {
        Cookies.set(ACCESS_TOKEN_NAME, bearerToken);
        if (userEmail) Cookies.set(USER_EMAIL_NAME, userEmail);

        if (onLogIn) onLogIn({ accountId, bearerToken, userEmail });
        else history.push(PATHS.MANAGE_ROOT);
      }
    },
  });

  const gainIdpToken = useGainIdpToken();
  const handleLoginWithIdp = async () => {
    const idpToken = await gainIdpToken(getValues('email') ?? '');
    if (idpToken) loginWithIdpToken.mutate({ idpToken });
  };

  const onSubmit: SubmitHandler<LogInInputs> = (data, e) => {
    e?.preventDefault();
    logIn.mutate(data);
  };

  const isLoading = logIn.isLoading || loginWithIdpToken.isLoading;

  return (
    <div className="h-100dvh w-100dvw overflow-auto bg-neutral-100 py-10 lg:py-20">
      <div className="m-auto max-w-98 flex flex-col px-4">
        <div className="self-center">
          <img className="h-auto w-50 sm:w-auto" src={brand} />
        </div>

        <form className="contents" onSubmit={handleSubmit(onSubmit)}>
          <p className="mt-14 color-neutral-800 typo-heading-4 lg:mt-28">Log in</p>

          <div className="mt-6">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input
                  disabled={field.disabled}
                  hint={formState.errors.email?.message}
                  label="Email address"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  value={field.value}
                  warn={!!formState.errors.email}
                />
              )}
            />
          </div>
          <div className="mt-3">
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <Input
                  disabled={field.disabled}
                  hint={formState.errors.password?.message}
                  label="Password"
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  type="password"
                  value={field.value}
                  warn={!!formState.errors.password}
                />
              )}
            />
          </div>
          <Link
            className="mt-1 cursor-pointer color-primary-400 underline typo-link-s"
            to={PATHS.FORGOT_PASSWORD}
          >
            Forgot password?
          </Link>

          <Button
            className="mt-3 flex cursor-pointer items-center justify-center gap-1"
            disabled={isLoading}
            filled
            primary
            size="s"
            type="submit"
          >
            {isLoading && <Spinner className="animate-spin" />}
            Log in
          </Button>
        </form>

        <div className="mt-7 flex items-center gap-3">
          <div className="flex-1 b-t-1 b-t-neutral-500 b-t-solid" />
          <span className="flex-none color-neutral-500 typo-text-m">or</span>
          <div className="flex-1 b-t-1 b-t-neutral-500 b-t-solid" />
        </div>

        <Button
          className="mt-7 flex cursor-pointer items-center justify-center gap-1"
          disabled={isLoading}
          onClick={handleLoginWithIdp}
          primary
          size="s"
        >
          {isLoading && <Spinner className="animate-spin" />}
          Enterprise SSO
        </Button>

        <p className="mt-7">
          <span className="color-neutral-500 typo-text-m">Don&apos;t have an account? </span>
          <a
            className="cursor-pointer color-primary-400 underline typo-link-m"
            href="https://skand.io/pricing"
          >
            Try a free demo
          </a>
        </p>
      </div>
    </div>
  );
};
