import { FindIcon } from '@/components/IconButton';
import { SideBar } from '@/components/SideBar';
import { Table } from '@/components/Table';
import { UsageMeter } from '@/components/UsageMeter';
import * as PATHS from '@/constants/paths';
import { UserV2 } from '@/graphql/codegen/graphql';
import { useListUsersByAccountId } from '@/hooks/useListUsersByAccountId';
import { cn } from '@/utils/classname';
import { Input } from '@skand/ui';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { GroupCell } from './GroupCell';
import { InviteUser } from './InviteUser';
import { RoleCell } from './RoleCell';
import { UserStatus } from './UserStatus';
import { SearchResultsSummary } from '@/components/SearchResultsSummary';
import { EmptyListComponent, EmptyListSeachTopic } from '@/components/EmptyListComponent';
import { handleSearchInputField } from '@/utils/misc';

const columnHelper = createColumnHelper<SolidId<UserV2>>();

const columns = [
  columnHelper.accessor(row => `${row?.name?.first} ${row?.name?.last}`, {
    enableGlobalFilter: true,
    id: 'name',
    header: 'name',
    cell: ({ getValue, row }) => (
      <div className="w-200px truncate" title={getValue()}>
        <Link
          className="color-neutral-800 underline typo-link-s"
          to={`${PATHS.MANAGE_USERS}/${row.original.id}/details`}
        >
          {getValue()}
        </Link>
      </div>
    ),
  }),

  columnHelper.accessor('email', {
    enableGlobalFilter: true,
    header: 'email',
    cell: ({ getValue }) => (
      <div className="w-200px truncate">
        <span className="typo-text-s">{getValue()}</span>
      </div>
    ),
  }),

  columnHelper.display({
    enableGlobalFilter: false,
    id: 'roles',
    header: 'roles',
    cell: ({ row }) => <RoleCell row={row} />,
  }),

  columnHelper.display({
    enableGlobalFilter: false,
    id: 'groups',
    header: 'groups',
    cell: ({ row }) => <GroupCell row={row} />,
  }),

  columnHelper.accessor('status', {
    enableGlobalFilter: true,
    header: 'status',
    cell: ({ row }) => <UserStatus status={row?.original?.status} />,
  }),
  columnHelper.accessor('lastLoginAt', {
    enableGlobalFilter: true,
    header: 'Last Login',
    cell: ({ getValue }) => {
      if (!getValue()) {
        return (
          <div>
            <p className="typo-text-s">N/A</p>
          </div>
        );
      }

      const date = new Date(getValue() as string);

      return (
        <div className="w-120px flex flex-row gap-1 typo-text-s">
          <span className="lg:block sm:hidden">
            {date.toLocaleDateString(undefined, { weekday: 'long' })}
          </span>
          <span className="lg:block">
            {date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
          </span>
        </div>
      );
    },
  }),
];

export const UsersPage = () => {
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');

  const {
    response: { isFetching },
    users,
  } = useListUsersByAccountId();

  const table = useReactTable({
    columns,
    data: users,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: 'includesString',
    onSortingChange: setSorting,
    state: { globalFilter, sorting },
  });

  const displayedUsers = table.getRowModel().rows.length;

  return (
    <div
      className={cn(
        'h-full',
        'w-full',
        'flex',
        'flex-row',
        'flex-nowrap',
        'bg-neutral-100',
        'overflow-hidden',
      )}
    >
      <SideBar />
      <div className={cn('max-h-fit', 'w-full', 'px-6', 'overflow-auto', 'flex flex-col')}>
        <div
          className={cn(
            'b-b-1',
            'b-b-neutral-500',
            'b-b-solid',
            'flex',
            'items-center',
            'justify-between',
            'p-b-3',
            'p-t-30px',
            'relative',
          )}
        >
          <h1 className={cn('typo-heading-3', 'color-neutral-800')}>Users</h1>
          <InviteUser
            isInviteUserModalOpen={isInviteUserModalOpen}
            setIsInviteUserModalOpen={setIsInviteUserModalOpen}
          />
        </div>

        <div className="mt-3 flex items-start justify-between">
          <p className="truncate color-neutral-800 typo-text-s">
            Assign roles to users, add and remove users from user groups and fine-tune permissions
            for individual users.
          </p>

          <div className="flex items-center justify-center gap-2">
            {globalFilter && (
              <div className="ml-4 max-w-360px">
                <SearchResultsSummary dataLength={displayedUsers} searchKey={globalFilter} />
              </div>
            )}
            <div className={cn('w-360px', !users.length && 'opacity-50')}>
              <Input
                disabled={!users.length}
                onChange={e => handleSearchInputField(e, setGlobalFilter)}
                placeholder="Search for users"
                tail={<FindIcon />}
                value={globalFilter}
              />
            </div>
          </div>
        </div>

        <Table
          className="mb-8 mt-6"
          empty={
            <EmptyListComponent searchTerm={globalFilter} searchTopic={EmptyListSeachTopic.USERS}>
              <InviteUser
                isInviteUserModalOpen={isInviteUserModalOpen}
                setIsInviteUserModalOpen={setIsInviteUserModalOpen}
              />
            </EmptyListComponent>
          }
          isLoading={isFetching}
          table={table}
          tdProps={{ className: 'w-fit truncate' }}
        />

        <div className="sticky bottom-0 z-1 bg-neutral-100 py-3">
          <UsageMeter />
        </div>
      </div>
    </div>
  );
};
